import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, AbstractControl, FormControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContratoService } from '../shared/contrato.service';
import { HtmlPrinterService } from '../../core/html-printer/html-printer.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { STEPPER_GLOBAL_OPTIONS, StepperSelectionEvent } from '@angular/cdk/stepper';
import { MatTableDataSource } from '@angular/material/table';
import { FaturaService } from '../../fatura/shared/fatura.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TableComponent } from 'src/app/Components/table/table/table.component';
import * as moment from 'moment';
import { Fatura } from 'src/app/fatura/shared/fatura.model';
import { DocumentoRelacionado, TipoDocumento } from 'src/app/Components/documentos-relacionados/shared/documentos-relacionados.model';
import { Contrato, TipoFaturamento, MetodoPagamento } from '../shared/contrato.model';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { CalculaParcelasComponent } from 'src/app/Components/parcelas/calcula-parcelas/calcula-parcelas.component';
import { ItemParcela } from 'src/app/Components/parcelas/shared/item-parcela.model';



export interface DialogData {
  contrato: Contrato;
}

@Component({
  selector: 'contrato-fatura',
  templateUrl: 'contrato-fatura.component.html',
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },

    },
    [DatePipe]
  ],
  styleUrls: ['contrato-fatura.component.css']

})

export class ContratoFaturaComponent {
  contrato: any = {};
  empresa: any = {};
  empresas: any = [];
  configuracoesFatura: FormGroup;
  maximizado: boolean;
  lastSelection: Range | null = null;
  dataSource = new MatTableDataSource<any>([]);
  itensSelecionados: any = [];
  integracaoSOC: boolean = false;
  parcelasFiltradas: any[] = [];
  totalParcelas: any;
  faturaParcelas: any;
  contratoParcelasItensSelecionados: any[] = [];
  parcelasContrato: any;
  valorTotalLiquidoqueSeraFaturadoPorCiclo: any; 
  quantidadeParcelasContrato: any;
  somaTodasParcelasLiquidasDoContrato: any;
  intervaloEmDiasEntreAsParcelas: any;
  parcelasValidas: boolean = false;

  @ViewChild('stepper') stepper: MatStepper;

  columns = [
    { def: 'descricao', header: 'Item' },
    { def: 'quantidade', header: 'Quantidade.' },
    { def: 'valorTotalLiquido', header: 'Valor Total' }
  ];

  @ViewChild(TableComponent) tableComponent: TableComponent;
  grupoContas: any;
  centroCustos: any;
  caixas: any;
  faturas = [];
  fatura: Fatura = new Fatura();

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ContratoFaturaComponent>,
    private _formBuilder: FormBuilder,
    private htmlPrinterService: HtmlPrinterService,
    @Inject(MAT_DIALOG_DATA) public IContrato: DialogData,
    private utilsService: UtilsService,
    private datePipe: DatePipe,
    private contratoService: ContratoService, private faturaService: FaturaService, private router: Router) {

    this.contrato = this.IContrato.contrato;


  }

  ngOnInit() {




    this.dataSource.data = this.contrato.contratoItens.map(item => {
      return {
        ...item,
        valorTotalLiquido: item.valorTotalLiquido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      };
    })



    this.buscarIntegracaoSOC();
    this.maximizado = false;
    const dataInicio = new Date(this.contrato.dataInicio);
    const dataFim = new Date(this.contrato.dataFim);

    this.configuracoesFatura = this._formBuilder.group({
      formArray: this._formBuilder.array([
        this._formBuilder.group({
          empresaId: ['', Validators.required]
        }),
        this._formBuilder.group({
          itens:  this._formBuilder.array([],this.utilsService.minArrayLengthValidator(1)),
        }),
        this._formBuilder.group({
          quaisParcelasFaturar: ['', Validators.required],
          dataInicio: [new Date(dataInicio.getFullYear(), dataInicio.getMonth(), 1)],
          dataFim: [new Date(dataFim.getFullYear(), dataFim.getMonth() + 1, 0)],
        }),
        this._formBuilder.group({
          parcelas:  this._formBuilder.array([],[this.utilsService.minArrayLengthValidator(1)]),
           }),
        this._formBuilder.group({ comoFaturar: ['', Validators.required] }),
        this._formBuilder.group({
          grupoContaId: [0],
          centroCustoId: [0],
          caixaId: [0]}),
        this._formBuilder.group({ substituirSoc: [true, Validators.required] })
      ])
    });

    this.buscarEmpresas();



    this.configuracoesFatura.get('formArray').get([2]).get('quaisParcelasFaturar').valueChanges.subscribe(value => {
      const dtInicio = this.configuracoesFatura.get('formArray').get([2]).get('dataInicio');
      const dtFim = this.configuracoesFatura.get('formArray').get([2]).get('dataFim');
      if (value === 'algumas') {
        dtInicio.setValidators([Validators.required]);
        dtFim.setValidators([Validators.required]);
      } else {
        dtInicio.clearValidators();
        dtFim.clearValidators();
      }
      dtInicio.updateValueAndValidity();
      dtFim.updateValueAndValidity();
    });

    this.configuracoesFatura.get('formArray').get([0]).get('empresaId').valueChanges.subscribe(value => {

      this.buscarCentroCustos(value);

      this.buscarGrupoContas(value);

      this.buscarCaixas(value);

    })

    this.configuracoesFatura.get('formArray').get([2]).get('quaisParcelasFaturar').valueChanges.subscribe(value => {
      this.definirParcelas();
    });
    this.configuracoesFatura.get('formArray').get([2]).get('dataInicio').valueChanges.subscribe(value => {
      this.definirParcelas();
    });
    this.configuracoesFatura.get('formArray').get([2]).get('dataFim').valueChanges.subscribe(value => {
      this.definirParcelas();
    });

    

  }


  

  getTipoFaturamentoTexto(): string {
    const tipoFaturamento = this.contrato.tipoFaturamento;
    return tipoFaturamento !== null ? TipoFaturamento[tipoFaturamento] : '';
  }


   // Método que será usado pelo validador customizado
   verificaValorParcelas(): boolean {
    
    //somar o valor de todas as parcelas no formulario
    const formArray = this.configuracoesFatura.get('formArray') as FormArray;
    const formGroup = formArray.at(3) as FormGroup;
    const parcelasArray = formGroup.get('parcelas') as FormArray;
    const parcelas = parcelasArray.value;
    let soma = 0;
    parcelas.forEach(parcela => {
      soma += Number(parcela.valorLiquido.toFixed(2));
    });

    soma = Number(soma.toFixed(2));
    
    if(soma === this.somaTodasParcelasLiquidasDoContrato){
      this.parcelasValidas = true;
      return true;
    }

    this.parcelasValidas = false;
    //se o step atual for o 3, exibir um snackbar de erro
    if(this.stepper.selectedIndex === 3){

    this._snackBar.open('⚠️ A soma total do Contrato no período é de R$ '+this.somaTodasParcelasLiquidasDoContrato.toFixed(2)+'. Entretanto, a soma das parcelas está totalizando R$ '+soma.toFixed(2)+'\n\n Sugerimos que corrija o valor das parcelas antes de continuar.', '✖', {
      duration: 7000, panelClass: ['mat-toolbar', 'warn']

    });

  }

    return false; 
  }



  onSelectionChange(event: any) {

    this.itensSelecionados = event;


   // Acessa o formArray do contratoForm
   const formArray =  this.configuracoesFatura.get('formArray') as FormArray;

   // Acessa o FormGroup na posição 1 do formArray
   const formGroup = formArray.at(1) as FormGroup;

   // Acessa o contratoItens FormArray dentro do FormGroup
   const itensArray = formGroup.get('itens') as FormArray;

   // Limpa o FormArray existente
   itensArray.clear();

   //para cada item selecionado de this.itensSelecionados, buscar o item pelo contratoItemId em this.contrato.contratoItens e salvar no array itensArray
    this.itensSelecionados.forEach(item => {
      const itemEncontrado = this.contrato.contratoItens.find(i => i.contratoItemId === item.contratoItemId);
      itensArray.push(new FormControl(itemEncontrado));
    });



  }

  salvarFaturas(){
    this.faturaService.postFaturas(this.faturas).subscribe(data => {
      this._snackBar.open('📑 Fatura(s) criadas com Sucesso! ', '✖', {
        duration: 5000, panelClass: ['mat-toolbar', 'succes']
      });

      //redirecionar para a tela de faturas
      this.router.navigate(['/faturavenda/visualizatodas']);


      this.dialogRef.close(true);


    });

  }

 IniciarProcesso(){

  this.fatura.faturaTipo = 0;
  this.fatura.faturaTipoDocumento = this.contrato.contratoItens[0].produto.produtoTipo == 0 ? 0 : 1;
  this.fatura.empresaId = this.configuracoesFatura.get('formArray').get([0]).get('empresaId').value;
  this.fatura.parceiroId = this.contrato.parceiroId;
  this.fatura.faturaStatus = 0;
  this.fatura.dataCriacao = new Date();
  this.fatura.caixaId = this.contrato.caixaId;
  this.fatura.contratoId = this.contrato.contratoId
  this.fatura.diaVencimento = this.contrato.diaVencimento;



}

AdicionarItens(){
  //somar a quantidade de todos os itens
  const quantidadeTotal = this.contrato.contratoItens.reduce((sum, item) => sum + item.quantidade, 0);
  const descontoGlobalPorUnidadeDeItem = Number((this.contrato.descontosAplicadosGlobalmente/quantidadeTotal).toFixed(2))

  //soma o valorTotalLiquido de todos os itens do contrato
  this.valorTotalLiquidoqueSeraFaturadoPorCiclo = Number((this.configuracoesFatura.get('formArray').get([1]).get('itens').value.reduce((sum, item) => Number(sum.toFixed(2)) + Number(item.valorTotalLiquido.toFixed(2)), 0)).toFixed(2));


  this.fatura.faturaItens = this.configuracoesFatura.get('formArray').get([1]).get('itens').value.map(item => {

    let novoValorTotal = item.valorTotalLiquido - (descontoGlobalPorUnidadeDeItem*item.quantidade);
    let novoDescontoUnitario = item.descontoUnitario + descontoGlobalPorUnidadeDeItem;

    //subtrair novoValorTotal de item.valorBruto e calcular a porcentagem de desconto
    let porcentagemDesconto = Number(((item.valorTotalBruto-novoValorTotal) / item.valorTotalBruto).toFixed(2));

    return {

      produtoId: item.produtoId,
      codProd: item.codProd,
      qtdTributaria: item.quantidade,
      qtdComercial: item.quantidade,
      vlrTributario: item.valorUnitario,
      vlrComercial: item.valorUnitario,
      valorUnitario: item.valorUnitario,
      valorTotal: novoValorTotal,
      valorBruto: item.valorTotalBruto,
      valorDesconto: novoDescontoUnitario,
      produto: item.produto,
      descricao: item.descricao,
      extipi: item.produto.extipi,
      InfAdProd: item.produto.InfAdProd,
      porcentagemQueRepresentaDoTotal: item.valorTotalLiquido/this.valorTotalLiquidoqueSeraFaturadoPorCiclo,
      porcentagemDescontoTotal: porcentagemDesconto


    };
  })

  //atualiza o valor do itens com o desconto global
  this.valorTotalLiquidoqueSeraFaturadoPorCiclo = this.fatura.faturaItens.reduce((sum, item) => sum + item.valorTotal, 0);

  //formatar para duas casas decimais
  this.valorTotalLiquidoqueSeraFaturadoPorCiclo = Number(this.valorTotalLiquidoqueSeraFaturadoPorCiclo.toFixed(2)); 

}


// Exemplo de método para ir para um step específico
goToStep(stepIndex: number): void {
  this.stepper.selectedIndex = stepIndex;
}

definirParcelas(){

  let dataInicio = this.contrato.dataInicio;
  let dataFim = this.contrato.dataFim;

  if(this.configuracoesFatura.get('formArray').get([2]).get('quaisParcelasFaturar').value === 'algumas'){

    dataInicio = this.configuracoesFatura.get('formArray').get([2]).get('dataInicio').value;
    dataFim = this.configuracoesFatura.get('formArray').get([2]).get('dataFim').value;
 
  }

    //definir quantidade de parcelas ou faturas que serão criadas
    this.parcelasContrato = this.definirTotalParcelas(dataInicio, dataFim);

    
    if(!(this.quantidadeParcelasContrato > 0)){
      this._snackBar.open('😖 Ops, não existem parcelas possíveis no período selecionado para esse contrato! Insira um período de data válido para continuar, ajuste a data de início e término do contrato ou o ciclo de faturamento', '✖', {
        duration: 5000, panelClass: ['mat-toolbar', 'warn']

      });
    }

}

definirFinanceiro(){
  this.fatura.caixaId = this.configuracoesFatura.get('formArray').get([5]).get('caixaId').value;
  this.fatura.grupoContaId = this.configuracoesFatura.get('formArray').get([5]).get('grupoContaId').value;
  this.fatura.centroCustoId = this.configuracoesFatura.get('formArray').get([5]).get('centroCustoId').value;
}


definirTotalParcelas(dataInicio, dataFim):any{

  let parcelas =[];
  let intervalo = 1;
  let unidade:moment.unitOfTime.DurationConstructor = 'months';
  
  //se this.contrato.tipoFaturamento === TipoFaturamento.Mensal, obter o resultado em Meses da diferença entre a this.contrato.dataFim e this.contrato.dataInicio
  if(this.contrato.tipoFaturamento === TipoFaturamento.Mensal){
    
    intervalo = 1;
    unidade = 'months';
    this.intervaloEmDiasEntreAsParcelas = 30;
    this.quantidadeParcelasContrato = (moment(dataFim).diff(moment(dataInicio), 'months') / intervalo)+1;
 
  }
  
  //se this.contrato.tipoFaturamento === TipoFaturamento.Anual, obter o resultado em Anos da diferença entre o ano de this.contrato.dataFim e o ano de this.contrato.dataInicio
  if (this.contrato.tipoFaturamento === TipoFaturamento.Anual) {
    
    const inicio = new Date(dataInicio);
    const fim = new Date(dataFim);

    intervalo = 1;
    unidade = 'years';
    this.intervaloEmDiasEntreAsParcelas = 364;
    this.quantidadeParcelasContrato = fim.getFullYear() - inicio.getFullYear() >0 ? fim.getFullYear() - inicio.getFullYear() : 1;
    
  }

  //se this.contrato.tipoFaturamento === TipoFaturamento.Semanal, obter o resultado em Semanas da diferença entre a this.contrato.dataFim e this.contrato.dataInicio
  if(this.contrato.tipoFaturamento === TipoFaturamento.Semanal){

    intervalo = 1;
    unidade = 'weeks';
    this.intervaloEmDiasEntreAsParcelas = 6;
    this.quantidadeParcelasContrato = moment(dataFim).diff(moment(dataInicio), 'weeks') / intervalo;

  }
  
  //se this.contrato.tipoFaturamento === TipoFaturamento.Diario, obter o resultado em Dias da diferença entre a this.contrato.dataFim e this.contrato.dataInicio
  if(this.contrato.tipoFaturamento === TipoFaturamento.Diario){

    intervalo = 1;
    unidade = 'days';
    this.intervaloEmDiasEntreAsParcelas = 1;
    this.quantidadeParcelasContrato = (moment(dataFim).diff(moment(dataInicio), 'days') / intervalo)+1;

  }
  
  //se this.contrato.tipoFaturamento === TipoFaturamento.Trimestral, obter o resultado em Bimestres da diferença entre a this.contrato.dataFim e this.contrato.dataInicio
  if(this.contrato.tipoFaturamento === TipoFaturamento.Trimestral){

    intervalo = 3;
    unidade = 'months';
    this.intervaloEmDiasEntreAsParcelas = 90;
    this.quantidadeParcelasContrato = moment(dataFim).diff(moment(dataInicio), 'months') / intervalo;

  }
  
  //se this.contrato.tipoFaturamento === TipoFaturamento.Quadrimestral, obter o resultado em Quadrimestres da diferença entre a this.contrato.dataFim e this.contrato.dataInicio
  if(this.contrato.tipoFaturamento === TipoFaturamento.Quadrimestral){

    intervalo = 4;
    unidade = 'months';
    this.intervaloEmDiasEntreAsParcelas = 122;
    this.quantidadeParcelasContrato = moment(dataFim).diff(moment(dataInicio), 'months') / intervalo;

  }

  //se this.contrato.tipoFaturamento === TipoFaturamento.Quinzenal, obter o resultado em Quinzenas da diferença entre a this.contrato.dataFim e this.contrato.dataInicio
  if(this.contrato.tipoFaturamento === TipoFaturamento.Quinzenal){

    intervalo = 14;
    unidade = 'days';
    this.intervaloEmDiasEntreAsParcelas = 14;
    this.quantidadeParcelasContrato = moment(dataFim).diff(moment(dataInicio), 'days') / intervalo;

  }
  
  //se this.contrato.tipoFaturamento === TipoFaturamento.Semestral, obter o resultado em Semestres da diferença entre a this.contrato.dataFim e this.contrato.dataInicio
  if(this.contrato.tipoFaturamento === TipoFaturamento.Semestral){

  intervalo = 6;
  unidade = 'months';
  this.intervaloEmDiasEntreAsParcelas = 183;
  this.quantidadeParcelasContrato = moment(dataFim).diff(moment(dataInicio), 'months') / intervalo;

  };

  //Arredondr o valor, pois não existe meia parcela
  this.quantidadeParcelasContrato = Math.round(this.quantidadeParcelasContrato);

  parcelas = this.gerarParcelas(intervalo, unidade, dataFim, dataInicio, this.quantidadeParcelasContrato);

  this.somaTodasParcelasLiquidasDoContrato =  Number((Math.round(this.quantidadeParcelasContrato)*this.valorTotalLiquidoqueSeraFaturadoPorCiclo).toFixed(2));
  return parcelas;
}

gerarParcelas(intervalo: number, unidade: moment.unitOfTime.DurationConstructor, dataFim, dataInicio, totalParcelas): any {

  let itens = [];

  for (let i = 0; i < totalParcelas; i++) {
    
    let parcela = new ItemParcela();

    let dataAtual = moment(dataInicio).add(i * intervalo, unidade).toDate();
    let dataVencimento = moment(dataAtual).set('date', this.contrato.diaVencimento).toDate();

    //subtração entre dataAtual e DataVencimento
    let diferenca = Math.abs(moment(dataVencimento).diff(moment(dataAtual), 'days'));

    //caso seja uma quinzena ou diario
    if(intervalo<30 && (unidade === 'days') && diferenca>intervalo-1 ){

      dataVencimento = dataAtual;

    }
   
    parcela = {
      dataVencimento: dataVencimento,
      valorLiquido: this.valorTotalLiquidoqueSeraFaturadoPorCiclo,
      tipoPagamento: this.contrato.metodoPagamento,
      saguiClienteId: this.contrato.saguiClienteId,
      parcelaId: i
    };

    itens.push(parcela);

  }

  return itens;
}



definirListaFaturas(){

  this.faturas = [];
  this.fatura.faturaParcelas = [];

  const parcelas = this.configuracoesFatura.get('formArray').get([3]).get('parcelas').value;
  const quantidadeParcelas = parcelas.length;


  this.fatura.isSocCancela = this.configuracoesFatura.get('formArray').get([6]).get('substituirSoc').value;
   
  //somar todos os valores de vlrComercial, vlrTributario, valorUnitario, valorTotal, valorBruto e valorDesconto de todos os itens em this.fatura.faturaItens e atualizar os valores de this.fatura.valorTotal, this.fatura.valorBruto, this.fatura.valorLiquido, this.fatura.valorDesconto
  //  let itensSomaValorTotal = this.fatura.faturaItens.reduce((sum, item) => sum + item.valorTotal, 0);
    let itensSomaValorBruto = this.fatura.faturaItens.reduce((sum, item) => sum + item.valorBruto, 0);
   // let itensSomaValorDesconto = this.fatura.faturaItens.reduce((sum, item) => sum + item.valorTotal, 0);
    
  //recalcula descontosGlobais
  // let descontosGlobais = this.recalcularDescontosGlobais();

   if(this.configuracoesFatura.get('formArray').get([4]).get('comoFaturar').value == 'separado'){

   //this.parcelas.totalParcelas é a quantidade de faturas que serão criadas com 1 parcela cada
   for (let i = 0; i < quantidadeParcelas; i++) {

    let novaFatura = { ...this.fatura };

     novaFatura.faturaParcelas = [{
     dataVencimento: parcelas[i].dataVencimentoParcela,
     valorParcela: parcelas[i].valorLiquido,
     valorBruto: parcelas[i].valorLiquido,
     saguiClienteId: this.contrato.saguiClienteId,
     faturaParcelaId: 0,
     valorDesconto: 0, 
     valorLiquido: parcelas[i].valorLiquido, 
     textoAdicional: '',
     faturaId: 0 
   }];

   novaFatura.numeroParcelas = 1;
   novaFatura.valorBruto = parcelas[i].valorLiquido;
   novaFatura.valorTotal = parcelas[i].valorLiquido,
   novaFatura.valorLiquido = parcelas[i].valorLiquido,
   novaFatura.valorDesconto = 0;
   novaFatura.descricao = 'ContratoId: ' + this.contrato.contratoId + ' - Fatura referente a parcela de venc. em: ' + this.datePipe.transform(parcelas[i].dataVencimentoParcela, 'dd/MM/yyyy');

   novaFatura = this.ajustarValorItensFatura(novaFatura);

   this.faturas.push(novaFatura);


   }
  }else{

    let novaFatura = { ...this.fatura };

    novaFatura.numeroParcelas = 1;
    novaFatura.valorBruto = itensSomaValorBruto*this.quantidadeParcelasContrato;
    novaFatura.valorTotal = this.somaTodasParcelasLiquidasDoContrato;
    novaFatura.valorLiquido = this.somaTodasParcelasLiquidasDoContrato;
    novaFatura.valorDesconto =  novaFatura.valorBruto -  this.somaTodasParcelasLiquidasDoContrato;
    novaFatura.descricao = 'Fatura do ContratoId: ' + this.contrato.contratoId;
    
    for (let i = 0; i < quantidadeParcelas; i++) {

       novaFatura.faturaParcelas.push({
        dataVencimento: parcelas[i].dataVencimentoParcela,
        valorParcela: parcelas[i].valorLiquido,
        valorBruto: parcelas[i].valorLiquido,
        saguiClienteId: this.contrato.saguiClienteId,
        faturaParcelaId: 0,
        valorDesconto: 0, 
        valorLiquido: parcelas[i].valorLiquido, 
        textoAdicional: '',
        faturaId: 0 
      });
   

    }

    this.ajustarValorItensFatura(novaFatura);

    this.faturas.push(novaFatura);
  }

 }



 ajustarValorItensFatura(fatura: any): any {
  // Somar o valor de todas as fatura.faturaParcelas
  const valorTotalLiquidoParcelas = fatura.faturaParcelas.reduce((sum, parcela) => sum + parcela.valorLiquido, 0);

  fatura.faturaItens = fatura.faturaItens.map(item => {
    let novoValorTotal = valorTotalLiquidoParcelas * item.porcentagemQueRepresentaDoTotal;
    // Calcular o valor bruto através de item.porcentagemDescontoTotal aplicado ao novo valor total
    let novoValorBruto = novoValorTotal / (1 - item.porcentagemDescontoTotal);

    // Arredondar os valores para evitar dízimas
    let novoValorUnitario = Number((novoValorBruto / item.qtdComercial).toFixed(2));
    let novoDescontoUnitario = Number(((novoValorBruto - novoValorTotal) / item.qtdComercial).toFixed(2));

    // Ajustar os valores para garantir que a soma seja igual ao novoValorTotal
    let valorTotalCalculado = (novoValorUnitario - novoDescontoUnitario) * item.qtdComercial;
    let diferenca = novoValorTotal - valorTotalCalculado;

    // Ajustar o valor unitário para compensar a diferença
    novoValorUnitario += diferenca / item.qtdComercial;

    return {
      ...item,
      valorTotal: novoValorTotal,
      valorBruto: novoValorBruto,
      valorDesconto: novoDescontoUnitario,
      valorUnitario: Number(novoValorUnitario.toFixed(2)),
      vlrTributario: Number(novoValorUnitario.toFixed(2)),
      vlrComercial: Number(novoValorUnitario.toFixed(2)),
    };

 
  });

  // Calcular a soma de todos os itens de faturaItens.valorTotal
  const somaValorTotalItens = fatura.faturaItens.reduce((sum, item) => sum + item.valorTotal, 0);

  // Ajustar o valor do último item para compensar a diferença
  const diferencaFinal = valorTotalLiquidoParcelas - somaValorTotalItens;
  if (diferencaFinal !== 0) {
    const ultimoItem = fatura.faturaItens[fatura.faturaItens.length - 1];
    ultimoItem.valorTotal += diferencaFinal;
    ultimoItem.valorBruto = ultimoItem.valorTotal / (1 - ultimoItem.porcentagemDescontoTotal);
    ultimoItem.valorUnitario = Number((ultimoItem.valorBruto / ultimoItem.qtdComercial).toFixed(2));
    ultimoItem.valorDesconto = Number(((ultimoItem.valorBruto - ultimoItem.valorTotal) / ultimoItem.qtdComercial).toFixed(2));
    ultimoItem.vlrTributario = ultimoItem.valorUnitario;
    ultimoItem.vlrComercial = ultimoItem.valorUnitario;
  }

  return fatura;
}

 onParcelasFinaisChange(parcelas: any[]) {


  //salvar as parcelas no formulario this.configuracoesFatura.get('contratoParcelas').get('formArray').get([3]).get('parcelas')
  const formArray = this.configuracoesFatura.get('formArray') as FormArray;
  const formGroup = formArray.at(3) as FormGroup;
  const parcelasArray = formGroup.get('parcelas') as FormArray;
  parcelasArray.clear();
  parcelas.forEach(parcela => {
    parcelasArray.push(new FormControl(parcela));
  });

  this.verificaValorParcelas();

  
}

  buscarIntegracaoSOC() {
    this.faturaService.getIntegracoesSOC().subscribe(data => {
      if (data.body) {
        this.integracaoSOC = true;
      }
    });
  }

  buscarGrupoContas(empresaId:any) {

    this.faturaService.getgrupoContas(0, empresaId).subscribe(data => {

      this.grupoContas = data.body;

    });
  }

  buscarCaixas(empresaId:any) {

    this.faturaService.getCaixas(0, empresaId).subscribe(data => {

      this.caixas = data.body;

    });
  }

  buscarCentroCustos(empresaId:any) {

    this.faturaService.getCentroCustos(0, empresaId).subscribe(data => {

      this.centroCustos = data.body;

    });
  }



  buscarEmpresas() {
    this.contratoService.getEmpresa().subscribe(data => {
      this.empresas = data.body;
      if (this.contrato.Empresas > 0) {
        this.configuracoesFatura.get('formArray').get([0]).get('empresaId').setValue(this.contrato.ContratoEmpresa[0].empresaId);
      } else {
        this.configuracoesFatura.get('formArray').get([0]).get('empresaId').setValue(this.contrato.empresaId);
      }
    });
  }

  verificaErro(){
    if(!(this.faturaParcelas?.length > 0)){
      return true;
    }

    return false;

  }

  fecharDialog() {
    this.dialogRef.close();
  }

  maximizarDialog() {
    this.dialogRef.updateSize('100%', '100%');
    this.maximizado = true;
  }

  restaurarDialog() {
    let innerWidth = window.innerWidth;
    if (innerWidth < 1024) {
      this.dialogRef.updateSize('90%', '100vh');
    } else {
      this.dialogRef.updateSize('70%', '100vh');
    }
    this.maximizado = false;
  }

  getSelectedRows() {
    this.itensSelecionados = this.tableComponent.selection.selected;
    return this.itensSelecionados;
  }

  isItemSelected(): boolean {
    if (this.tableComponent && this.tableComponent.selection) {
      return this.getSelectedRows().length > 0;
    }
    return false;
  }


  /** Returns a FormArray with the name 'formArray'. */
  get formArray(): AbstractControl | null { return this.configuracoesFatura.get('formArray'); }

  onStepChange(event: StepperSelectionEvent): void {
    const currentIndex = event.previouslySelectedIndex;
    const nextIndex = event.selectedIndex;

    // Função para rodar quando sair de um step
    this.onStepExit(currentIndex, nextIndex);
  }


  centroCusto() {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/financeiro/centrocusto']));
    window.open(url, '_blank');
  }

  categoriasFinanceiras() {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/financeiro/categoriacontareceber']));
    window.open(url, '_blank');
  }

  caixaFinanceiro() {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/financeiro/fluxocaixa']));
    window.open(url, '_blank');
  }

  onStepExit(currentIndex: number, nextIndex: number): void {

    if(currentIndex === 0){
      this.IniciarProcesso();
    }

    if(currentIndex === 1){
      this.AdicionarItens();
    }

    if(currentIndex === 2){
     //esta no valueChanges do formulario do step 1
    }

    if(currentIndex === 4){
      this.definirFinanceiro();
    }


    if (nextIndex === 6 && this.integracaoSOC) {
      this.definirListaFaturas();
    }else{
      if (nextIndex === 5){
        this.definirListaFaturas();
      }

    }

  }

}
