import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CadastroEtapaDialogComponent } from 'src/app/ordem-servico/etapa-dialog/cadastro-etapa-dialog/cadastro-etapa-dialog.component';
import { OrcamentoService } from '../shared/orcamento.service';
import { VendaService } from '../shared/venda.service';


export interface DialogData {
  orcamentoId?: number;
  vendaId?: number;
  orcamento?: any;
  venda?: any;
}

@Component({
  selector: 'app-desconto-adicional',
  templateUrl: './desconto-adicional.component.html',
  styleUrls: ['./desconto-adicional.component.css']
})
export class DescontoAdicionalComponent implements OnInit {
  maximizado: boolean = false;
  orcamentoOrVenda: any = this.IData.orcamento == null || this.IData.orcamento == undefined ? this.IData.venda : this.IData.orcamento;
  valorBruto: number = this.orcamentoOrVenda.valorTotal - this.orcamentoOrVenda.descontosItensEmReais;


  DescontoOrcamentoDto: FormGroup;



  constructor(public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DescontoAdicionalComponent>,
    @Inject(MAT_DIALOG_DATA) public IData: DialogData,
    private orcamentoService: OrcamentoService,
    private vendaService: VendaService) { }

  ngOnInit(): void {
    this.DescontoOrcamentoDto = this._formBuilder.group({
      OrcamentoId: [this.IData.orcamentoId],
      VendaId: [this.IData.vendaId],
      DescontoEmPorcentagem: 0,
      DescontoValorEmReal: 0
    });

    if (this.IData.orcamentoId > 0) {
      this.orcamentoService.getOrcamento(this.IData.orcamentoId).subscribe(
        (response) => {
          this.orcamentoOrVenda = response.body;
          this.DescontoOrcamentoDto.get('OrcamentoId').setValue(this.IData.orcamentoId);
          this.DescontoOrcamentoDto.get('DescontoEmPorcentagem').setValue(this.orcamentoOrVenda.descontosAdicionaisEmPorcentagem);
          this.DescontoOrcamentoDto.get('DescontoValorEmReal').setValue(this.orcamentoOrVenda.descontosAdicionaisEmReais);
        },
        (error) => {
          console.log(error);
        }
      );
    }
    if (this.IData.vendaId > 0) {
      this.vendaService.getVenda(this.IData.vendaId).subscribe(
        (response) => {
          this.orcamentoOrVenda = response.body;
          this.DescontoOrcamentoDto.get('VendaId').setValue(this.IData.vendaId);
          this.DescontoOrcamentoDto.get('DescontoEmPorcentagem').setValue(this.orcamentoOrVenda.descontosAdicionaisEmPorcentagem);
          this.DescontoOrcamentoDto.get('DescontoValorEmReal').setValue(this.orcamentoOrVenda.descontosAdicionaisEmReais);
        },
        (error) => {
          console.log(error);
        }
      );
    }

  }

  fecharDialog() {
    this.dialogRef.close();
  }

  maximizarDialog() {
    this.dialogRef.updateSize('100%', '100%');
    this.maximizado = true;
  }

  restaurarDialog() {
    let innerWidth = window.innerWidth;

    if (innerWidth < 1024) {
      this.dialogRef.updateSize('90%', '100vh');
    }
    else {
      this.dialogRef.updateSize('60%', '100vh');
    }
    this.maximizado = false;
  }

  salvarDescontosAdicionais() {
    if(this.DescontoOrcamentoDto.value.OrcamentoId > 0){
      this.orcamentoService.putDescontosAdicionais(this.DescontoOrcamentoDto.value).subscribe(
        (response) => {
          this._snackBar.open('Descontos e Adicionais atualizados com sucesso!', 'Fechar', {
            duration: 5000,
          });
          this.dialogRef.close();
        },
        (error) => {
          console.log(error);
        }
      );
    }else{
      this.vendaService.putDescontosAdicionais(this.DescontoOrcamentoDto.value).subscribe(
        (response) => {
          this._snackBar.open('Descontos e Adicionais atualizados com sucesso!', 'Fechar', {
            duration: 5000,
          });
          this.dialogRef.close();
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  changePorcentagem() {
    this.onEmptied();

    if (this.DescontoOrcamentoDto.value.DescontoEmPorcentagem <= 100 && this.DescontoOrcamentoDto.value.DescontoEmPorcentagem >= 0) {

      this.DescontoOrcamentoDto.value.DescontoValorEmReal = this.valorBruto * (this.DescontoOrcamentoDto.value.DescontoEmPorcentagem / 100);

    }

  }

  changeEmReal() {
    this.onEmptied();
    if (this.DescontoOrcamentoDto.value.DescontoValorEmReal <= this.orcamentoOrVenda.valorBruto
      && this.DescontoOrcamentoDto.value.DescontoValorEmReal >= 0) {
      this.DescontoOrcamentoDto.value.DescontoEmPorcentagem
        = (this.DescontoOrcamentoDto.value.DescontoValorEmReal / this.valorBruto) * 100;

    }

  }

  onEmptied() {
    if ((this.DescontoOrcamentoDto.value.DescontoValorEmReal == 0 || this.DescontoOrcamentoDto.value.DescontoValorEmReal == null)
      && (this.DescontoOrcamentoDto.value.DescontoEmPorcentagem == 0 || this.DescontoOrcamentoDto.value.DescontoEmPorcentagem == null)) {
      this.DescontoOrcamentoDto.value.DescontoEmPorcentagem = 0;
      this.DescontoOrcamentoDto.value.DescontoValorEmReal = 0;
      this.valorBruto = this.orcamentoOrVenda.valorTotal;
    }

  }

  limitarValor() {
    let descontoEmPorcentagem = this.DescontoOrcamentoDto.get('DescontoEmPorcentagem');
    let descontoEmReais = this.DescontoOrcamentoDto.get('DescontoValorEmReal');
    let valorBruto = this.valorBruto;
    this.limitarTresCasasDecimais();
    if (descontoEmPorcentagem.value < 0) {
      this.DescontoOrcamentoDto.value.DescontoEmPorcentagem = 0;
      this.DescontoOrcamentoDto.value.DescontoValorEmReal = 0;
      this.changePorcentagem();
    } else if (descontoEmPorcentagem.value > 100) {
      this.DescontoOrcamentoDto.get('DescontoEmPorcentagem').setValue(100);
      this.DescontoOrcamentoDto.get('DescontoValorEmReal').setValue(valorBruto);
      this.changePorcentagem();
    }
    else if (descontoEmReais.value < 0) {
      this.DescontoOrcamentoDto.value.DescontoEmPorcentagem = 0;
      this.DescontoOrcamentoDto.value.DescontoValorEmReal = 0;
      this.changeEmReal();
    } else if (descontoEmReais.value > valorBruto) {

      this.DescontoOrcamentoDto.value.DescontoEmPorcentagem = 100;
      this.DescontoOrcamentoDto.get('DescontoValorEmReal').setValue(valorBruto);
      this.changeEmReal();
    }

  }

  limitarTresCasasDecimais(){
    let descontoEmPorcentagem = this.DescontoOrcamentoDto.get('DescontoEmPorcentagem');
    let descontoEmReais = this.DescontoOrcamentoDto.get('DescontoValorEmReal');
    descontoEmPorcentagem.setValue(parseFloat(descontoEmPorcentagem.value.toFixed(3)));
    descontoEmReais.setValue(parseFloat(descontoEmReais.value.toFixed(3)));
  }

}
