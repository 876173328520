import { Cliente } from "src/app/cliente/shared/cliente.model";
import { ContratoParcela } from "./contrato-parcela.model";
import { ContratoItem } from "./contrato-item.model";
import { ContratoSignatario } from "./contrato-signatario.model";

export class Contrato {

    contratoId: number = 0;

    data: Date = new Date();

    contratoHtmlTexto: string = '';

    contratoHtmlByte: string = '';

    tipoContrato: number = 0;

    dataCriacao: Date = new Date();

    d4SignId: string = '';

    status: number = 0;

    contratoMetodoAssinatura: number = 2;

    dataInicio: Date = new Date();

    dataFim: Date = new Date(new Date().getFullYear()+1, new Date().getMonth(), new Date().getDay());

    dadosAdicionais: string = '';

    empresaId: number = 0;

    parceiroId: number = 0;

    nomeParceiro: string = '';

    valorContrato: string = '';

    saguiClienteId: number = 0;

    templateId: number = 0;

    vendaId: number = 0;

    valorTotalBruto: number=0;

    descontosAplicadosAosItens: number=0;
   
    descontosAplicadosGlobalmente: number=0;
   
    valorTotalLiquido: number=0;

    somaDeTodosOsDescontos: number=0;

    clienteId: number = 0;

    contratoEmpresas: any[] = []; 

    contratoParcelas: ContratoParcela[]=[];

    contratoItens: ContratoItem[] = [];

    contratoSignatarios: ContratoSignatario[] = [];

    documentos: any[] = [];

    tipoFaturamento: TipoFaturamento = TipoFaturamento.Mensal;

    metodoPagamento: MetodoPagamento = MetodoPagamento.Boleto;

    diaVencimento: number = 1;
    
}


export enum ContratoStatus
{
    EmCriacao = 0,
    PendenteAssinatura = 1,
    Assinado = 2,
    Concluido = 3,
    Cancelado = 99
}


export enum TipoFaturamento
{
    Mensal,
    Anual,
    Semestral,
    Trimestral,
    Quadrimestral,
    Quinzenal,
    Semanal,
    Diario
}

export enum MetodoPagamento
{
    Dinheiro = 0,
    Debito = 1,
    Credito = 2,
    Boleto = 3,
    Transferencia = 4,
    Cheque = 5,
    ValeRefeicao = 6,
    Banco = 7,
    PrestacaoConta = 8,
    Devolucao = 9,
    TransferenciaEntreCaixas = 10,
    Pix = 11
}
