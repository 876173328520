import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { Nfse } from './nfse.model';

@Injectable()
export class NfseService {

  postVisualizaNfsesPorFiltro(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/nfse/visualizatodas/', item, { observe: 'response' })
  }

  getEmpresa(): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/nfse/visualizatodasempresas', { observe: 'response' })
  }

  getListaServico(): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/servicolista/visualizatodas', { observe: 'response' })
  }

  getListaServicoCadastrados(empresaId): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/servicolista/visualizatodascadastradas/' + empresaId, { observe: 'response' })
  }

  getRetornoTardio(nfseId): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/nfse/processa-retorno-tardio/'+ nfseId, null, { observe: 'response' })
  }

  getClientePorNome(nome: any): Observable<HttpResponse<any>> {
    const params = new HttpParams().set('id', nome);
    return this.http.get(
       environment.webApiSaguiControlUrl +'/api/nfse/visualizaclientepornome',
      { observe: 'response', params }
    );
  }

  getNfse(itemId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/nfse/visualiza/' + itemId, { observe: 'response' })
  }

  postNfse(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/nfse/inclui/', item, { observe: 'response' })
  };

  postGerarBoletoByNfseId(nfseId: number): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/nfse/gerarboleto-by-nfseid/' + nfseId, {}, { observe: 'response' })
  }

  putNfse(item: any, itemId: number): Observable<HttpResponse<any>> {
    return this.http.put(
      environment.webApiSaguiControlUrl + '/api/nfse/atualiza/' + itemId, item, { observe: 'response' })
  };

  postProcessaNfse(item: any, itemId: number): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/nfse/processa/', item, { observe: 'response' })
  };

  postCalculaImposto(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/nfse/calculaimposto/', item, { observe: 'response' })
  };

  postGeraLinkImpressao(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/nfse/imprime/',
      item,
      { responseType: 'arraybuffer', observe: 'response' } // Recebe arraybuffer, que pode ser tratado como texto ou Blob
    );
  }




  postCancelar(itemId: number): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/nfse/cancela/' + itemId, {}, { observe: 'response' })
  };

  postGerarNfseLoteRps(nfses: Nfse[]): Observable<HttpResponse<any>> {
    console.log(nfses, "teste");
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/nfse/processa-lote/', nfses, { observe: 'response' })
  }

  getContaParcelFaturaByNfseId(nfseId: number): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/nfse/contaparcelas-fatura-by-nfseid/' + nfseId, { observe: 'response' })
  }

  salvaContaParcelaEFatura(item: any): Observable<HttpResponse<any>> {
    return this.http.put(
      environment.webApiSaguiControlUrl + '/api/nfse/salva-contaparcela-fatura/', item, { observe: 'response' })
  }

  atualizaContaParcelaToValoresNfse(nfseId: number): Observable<HttpResponse<any>> {
    return this.http.put(
      environment.webApiSaguiControlUrl + '/api/nfse/atualiza-contaparcela-to-valoresnfse/' + nfseId, {}, { observe: 'response' })
  }

  getImpostosRetencaoNfse(nfseId: number) {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/nfse/get-info-nfse-retencao/' + nfseId, { observe: 'response' })
  }

  getCnaeByListaServico(listaServico: string): Observable<HttpResponse<string>> {
    return this.http.get<string>(
      `${environment.webApiSaguiControlUrl}/api/nfse/cnae/${listaServico}`,
      { observe: 'response' }
    );
  }


  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

  }
}
