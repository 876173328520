<mat-toolbar>
  <div class="md-toolbar-tools">
    <h1 mat-dialog-title>{{tipoOperacao}} {{textoTpContaView}} {{textoAdiantamento}} {{contaCadastroForm.value.contaId}}</h1>
    <span flex></span>
    <div class="posicionar-direita">
      <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
        <mat-icon>fullscreen_exit</mat-icon>
      </button>
      <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
        <mat-icon>fullscreen</mat-icon>
      </button>
      <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" matTooltip="Salvar" (click)="salvar()" *ngIf="permiteEdicao" [disabled]="contaCadastroForm.invalid || contaCadastroForm.get('parceiroAutoComplete').invalid || !isParceiroValido">
    <mat-icon>save</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <div class="md-dialog-content">
    <br />
    <form [formGroup]="contaCadastroForm">
      <mat-tab-group>
        <mat-tab label="Geral">
          <br />
          <div class="row">
            <mat-form-field class="col-md-6 col-xs-12 form-group">
              <mat-label>Emitente</mat-label>
              <mat-select formControlName="empresaId">
                <mat-option *ngFor="let empresa of empresas" [value]="empresa.empresaId">
                  {{empresa.nomeFantasia}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="col-md-6 col-xs-12 form-group">
              <mat-label>Descrição da Conta</mat-label>
              <input type="text" matInput formControlName="descricaoConta">
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="col-md-4 col-xs-12 form-group">
              <mat-label>Categoria</mat-label>
              <mat-select formControlName="grupoContaId">
                <mat-option *ngFor="let grupoConta of grupoContas" [value]="grupoConta.grupoContaId"
                  (click)="determinagrupoConta(grupoConta)">
                  {{grupoConta.descricao}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="col-md-4 col-xs-12 form-group">
              <mat-label>Caixa (Fluxo de Caixa)</mat-label>
              <mat-select formControlName="caixaId">
                <mat-option *ngFor="let caixa of caixas" [value]="caixa.caixaId" (click)="determinaCaixa(caixa)">
                  {{caixa.nome}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="col-md-4 col-xs-12 form-group">
              <mat-label>Centro de Custo</mat-label>
              <mat-select formControlName="centroCustoId">
                <mat-option *ngFor="let centroCusto of centroCustos" [value]="centroCusto.centroCustoId">
                  {{centroCusto.descricao}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="col-md-6 col-xs-12 form-group">
              <mat-label>Parceiro</mat-label>
              <input appRequiredField #inputField type="text" matInput [matAutocomplete]="auto"
                formControlName="parceiroAutoComplete" (input)="onInputChange(inputField.value)">
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="determinaParceiro($event.option.value)">
                <mat-option *ngFor="let parceiro of parceiros" [value]="parceiro.nomeFantasia"
                  (click)="determinaParceiro(parceiro)">
                  CNPJ/CPF: {{parceiro.cpf != null ? parceiro.cpf : '' }}{{parceiro.cnpj != null ? parceiro.cnpj : ''}} - {{parceiro.razaoSocial}} {{parceiro.nomeFantasia}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <div class="col-md-6 col-xs-12 form-group">
              <mat-checkbox formControlName="isSocCancela">Sempre Substituir com SOC</mat-checkbox>
            </div>
          </div>
          <br />
        </mat-tab>
        <mat-tab label="Itens">
          <br />
          <mat-card>
            <mat-card-header>
                <mat-card-title>Itens</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div id="itens">
                    <div id="list" class="row">
                        <div class="table-responsive col-md-12">
                            <table class="table table-striped" cellspacing="0" cellpadding="0">
                                <thead>
                                    <tr>
                                        <th>Descrição</th>
                                        <th>Unid.</th>
                                        <th>Qtde.</th>
                                        <th>Valor Un.</th>
                                        <th>Valor Bruto</th>
                                        <th>Desconto</th>
                                        <th>Valor Após desconto</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let contaItem of contaItens">
                                      <td>{{contaItem.descricao}}</td>
                                      <td>{{contaItem.unidade}}</td>
                                      <td>{{contaItem.quantidade}}</td>
                                      <td>{{contaItem.valorUnitario | currency}}</td>
                                      <td>{{contaItem.valorTotal | currency}}</td>
                                      <td>{{contaItem.valorDesconto| currency}}</td>
                                      <td>{{contaItem.valorBruto | currency}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
        </mat-tab>
        <mat-tab label="Contábil">
          <br />
          <div class="row">
            <mat-form-field class="col-md-6 col-xs-12 form-group">
              <mat-label>Conta de Débito</mat-label>
              <input type="text" matInput
                [matAutocomplete]="contaContabilDebitoAutoComplete" formControlName="contaContabilDebitoAutoComplete">
              <mat-autocomplete #contaContabilDebitoAutoComplete="matAutocomplete">
                <mat-option *ngFor="let contaContabil of contasContabeis" [value]="contaContabil.descricao"
                  (click)="determinaContaContabilDebito(contaContabil)">
                  {{contaContabil.numero}} - {{contaContabil.descricao}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field class="col-md-6 col-xs-12 form-group">
              <mat-label>Conta de Crédito</mat-label>
              <input type="text" matInput
                [matAutocomplete]="contaContabilCreditoAutoComplete" formControlName="contaContabilCreditoAutoComplete">
              <mat-autocomplete #contaContabilCreditoAutoComplete="matAutocomplete">
                <mat-option *ngFor="let contaContabil of contasContabeis" [value]="contaContabil.descricao"
                  (click)="determinaContaContabilCredito(contaContabil)">
                  {{contaContabil.numero}} - {{contaContabil.descricao}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </mat-tab>
      </mat-tab-group>
    </form>
  </div>
  <div class="row rowmateria">
    <div class="col-md-12 col-xs-12 col-sm-12">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Parcelas</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row">
            <div class="col-md-6">
              <button mat-raised-button color="accent" (click)="abreModalContaParcela(0, contaCadastroForm.value.contaId)"
                *ngIf="contaCadastroForm.value.contaId > 0" matTooltip="Adicionar">
                <mat-icon>add</mat-icon>
              </button>
              <button mat-raised-button color="accent" class="sagui-button" (click)="incluirParcelaRecorrente()"
                *ngIf="contaCadastroForm.value.contaId > 0 && contaCadastroForm.value.tpConta != 2" matTooltip="Adicionar Recorrência">
                <mat-icon>add</mat-icon> Recorrência
              </button>
            </div>
            <div class="col-md-6" *ngIf="contaCadastroForm.value.tpConta ==  2 ">
              <h2>Saldo Atual: {{contaCadastroForm.value.saldo | currency}}</h2>
            </div>
          </div>
          <hr>
          <div id="list" class="row">
            <div class="table-responsive col-md-12">
              <table class="table table-striped" cellspacing="0" cellpadding="0">
                <thead>
                  <tr>
                    <th>Ações</th>
                    <th>Id</th>
                    <th>Descrição</th>
                    <th>Status</th>
                    <th>Tipo</th>
                    <th>Data de Vencimento</th>
                    <th>Previsto</th>
                    <th>Pago</th>
                    <th *ngIf="contaCadastroForm.value.tpConta == 2">Saldo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let contaParcela of contaParcelas">
                    <td class="actions">
                      <a mat-mini-fab
                        (click)="abreModalContaParcela(contaParcela.contaParcelaId, contaParcela.contaId)" matTooltip="Editar">
                        <mat-icon>edit</mat-icon>
                      </a>
                      <a mat-mini-fab color="warn" (click)="deletarContaParcela(contaParcela)"
                        *ngIf="contaParcela.statusPagamento < 2" matTooltip="Deletar">
                        <mat-icon>delete</mat-icon>
                      </a>
                    </td>
                    <td>{{contaParcela.contaParcelaId}}</td>
                    <td>{{contaParcela.descricao}}</td>
                    <td>{{contaParcela.statusPagamento == 0 ? "Ativo" : contaParcela.statusPagamento == 1 ? "Quitado" :
                      "Cancelada" }}</td>
                    <td>{{contaParcela.statusPagamento == 0 ? "" : getTextoTipoPagametno(contaParcela.tipoPagamento) }}
                    </td>
                    <td>{{contaParcela.dataVencimento | date:'dd/MM/yyyy'}}</td>
                    <td>{{contaParcela.valorParcela | currency}}</td>
                    <td>{{contaParcela.valorPagamento | currency}}</td>
                    <td *ngIf="contaCadastroForm.value.tpConta == 2">{{contaParcela.saldo | currency}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</mat-dialog-content>
