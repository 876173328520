'use strict'
import { Component, EventEmitter, Inject, Input, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InfoDialogComponent } from '../../../core/dialogs/info-dialog/info-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, of } from 'rxjs';
import { TipoDocumento } from '../shared/documentos-relacionados.model';
import { DocumentoService } from '../shared/documento.service';


@Component({   
    selector: 'app-documentos-relacionados-component',
    templateUrl: 'documentos-relacionados.component.html',
})

export class DocumentosRelacionadosComponent {
    
    @Input() empresas: any[] = [];
    @Input() postDocumentoByIdEEmpresas: (params: any) => Observable<any>;
    @Input() clienteId: any;
    @Input() panelOpenState: boolean = true;
    @Input() id: any;


    @Output() documentosRelacionadosNovos = new EventEmitter<any[]>();

    documentoRelacionadoForm: FormGroup;
    tipoDocumentoRelacionadoTexto: any = '';
    documentoFormControl = new FormControl();
    filteredObjetos: any[];
    documentosRelacionados: any[] = [];
    documentosRelacionadosFormatados: any[] = [];
    documentosRelacionadosDataTable = new MatTableDataSource<any>([]);
    documentosRelacionadosAnteriores: any[] = [];
    optionsDocumentosRelacionados: any[]; 
    

      configInicialOptions = [
        { value: 1, viewValue: 'Pedido de Venda' },
        { value: 4, viewValue: 'Orçamento' },
        { value: 6, viewValue: 'Contrato Anterior' },
    ];

    

      columnsDocumentosRelacionados = [
        { def: 'idDocumentoDestino', header: 'ID do Documento' },
        { def: 'tipoDocumentoDestino', header: 'Tipo do Documento' },
        { def: 'acaoTable', header: 'Ações' },
    
      ];

    constructor(
         private _snackBar: MatSnackBar,
        private _formBuilder: FormBuilder,
        private utilsService: UtilsService,
        private documentoService: DocumentoService,
    ) {
    }

    ngAfterViewInit() {

       
    
    }

    ngOnInit() {

      this.optionsDocumentosRelacionados = this.configInicialOptions;

        // criar campos do formulario documentoRelacionadoForm
        this.documentoRelacionadoForm = this._formBuilder.group({
        idDocumentoDestino: ['', Validators.required],
        tipoDocumentoDestino: ['', Validators.required],
     
      });

      this.documentoRelacionadoForm.get('tipoDocumentoDestino').valueChanges.pipe(
        startWith(this.documentoRelacionadoForm.get('tipoDocumentoDestino')?.value)
        ).subscribe(value => {
          const selectedOption = this.optionsDocumentosRelacionados.find(option => option.value === value);
          this.tipoDocumentoRelacionadoTexto = selectedOption ? selectedOption.viewValue : null;
        });

        this.documentoFormControl.valueChanges.pipe(
            startWith(''),
            debounceTime(300),
            distinctUntilChanged(),
            switchMap(value => {
              const id = parseInt(value);
              if (value?.length >= 1 && !isNaN(id)) {
                // Acessa o formArray na posição [0] e o FormArray empresas dentro dele
                const contratoEmpresasArray = this.empresas;
          
              // Mapeia os valores de EmpresaId
                const empresaIds = contratoEmpresasArray.map(item => item.empresaId);
          
                // Retorna o resultado da API se o comprimento for 3 ou mais caracteres
                return this.postDocumentoByIdEEmpresas(
                  {
                  idBuscado: id, 
                  tipoDocumentoDestino: parseInt(this.documentoRelacionadoForm.get('tipoDocumentoDestino').value),
                  empresasIds: empresaIds,
                  clienteId: this.clienteId
                }
                ).pipe(
                  map(response => response.body || []),
                );
              } else {
                // Retorna um array vazio se o comprimento for menor que 3 caracteres
                return of([]);
              }
            })
          ).subscribe(data => {
            // Atualiza a lista de clientes filtrados
            this.filteredObjetos = data;
          });
        
         this.visualizaDocumento();

    }

    visualizaDocumento() {

      if(this.id){
        

        var parametros: any = {
          tipoOrigem: TipoDocumento.Contrato,
          idOrigem: this.id
        };
    
        this.documentoService.getVisualizaDocumento(parametros).subscribe(data => {
    
          this.documentosRelacionadosAnteriores = data.body;

          this.documentosRelacionados = this.documentosRelacionadosAnteriores;
            if(this.documentosRelacionadosAnteriores?.length>0){
                this.documentosRelacionadosAnteriores.forEach(element => {
                    this.documentosRelacionadosFormatados.push({
                        idDocumentoDestino: element.idDocumentoDestino,
                        tipoDocumentoDestino: TipoDocumento[element.tipoDocumentoDestino],
                    });
                }); 
            }

            this.documentosRelacionadosDataTable.data = this.documentosRelacionadosFormatados;
    
        });
      }
    }

    ngOnChanges(changes: SimpleChanges) {
        
      //verifica se tem mudanças em id
        if (changes.id && changes.id.currentValue !== changes.id.previousValue) {
          this.visualizaDocumento();
        }

   
    }

    addDocumento(){
        this.documentosRelacionados.push(this.documentoRelacionadoForm.value);
    
        this.documentosRelacionadosFormatados.push({
          idDocumentoDestino: this.documentoRelacionadoForm.get('idDocumentoDestino').value,
          tipoDocumentoDestino: this.tipoDocumentoRelacionadoTexto,
          
          });
      
        // Atribuição dos valores ao MatTableDataSource
        this.documentosRelacionadosDataTable.data = this.documentosRelacionadosFormatados;
    
        // Emitir o evento para o componente pai
        this.documentosRelacionadosNovos.emit(this.documentosRelacionados);

        //limpar todos os campos do formulario documentoRelacionadoForm após a inserção sem remover as funções onChanges
        this.documentoRelacionadoForm.reset();
        this.documentoFormControl.setValue(undefined);
        this.verificaContratoAnteriorExiste();
    
      }
    

    

    onDocumentoSelected(documento: any) {
        if(documento){
          this.documentoRelacionadoForm.patchValue({ idDocumentoDestino: documento.id });

          this.addDocumento();
        
         }
      }

      displayDocumentoFn(documento): string {
        return documento ? documento.descricao : '';
      }

        //cria uma função chamada contratoAnteriorExiste para verificar se em this.contratoForm.get('formArray').get([5]).get('documentos').value possui algum registro com tipo de registro = 6
    verificaContratoAnteriorExiste(){
        if (this.documentosRelacionados.some((documento: any) => documento.tipoDocumentoDestino === 6)){

        //remove option 6 de optionsDocumentosRelacionados
        this.optionsDocumentosRelacionados = this.optionsDocumentosRelacionados?.filter(option => option.value !== 6);
        }else{
        //adiciona option 6 de optionsDocumentosRelacionados
        this.optionsDocumentosRelacionados = this.configInicialOptions;
        }
  }


    onExcluirDocumento(idToRemove: any): void {
      
        // Encontrar o índice do item a ser removido
        const index = this.documentosRelacionados.findIndex(item => item.idDocumentoDestino === idToRemove);
    
        // Remover o item se encontrado
        if (index !== -1) {
            this.documentosRelacionados.splice(index,1);
            this.documentosRelacionadosFormatados.splice(index,1);

        }
    
        // Atualizar os dados da tabela
        this.documentosRelacionadosDataTable.data = this.documentosRelacionadosFormatados;

        // Emitir o evento para o componente pai
        this.documentosRelacionadosNovos.emit(this.documentosRelacionados);

        this.verificaContratoAnteriorExiste();
    
    }

     
}
