import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable()
export class ContaService {

  postEnviaBoletoPorEmail(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/conta/enviaboletoporemail', item, { observe: 'response' })
  };

  postIncluiBoletoPelaContaParcela(item: any): Observable<HttpResponse<any>> {
    console.log(item);
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/conta/incluiboletopelacontaparcela', item, { observe: 'response' })
  };

  postVisualizaBoletoPorContaParcela(item: any): Observable<HttpResponse<any>> {
    console.log(item);
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/conta/visualizaboletoporcontaparcela', item, { observe: 'response' })
  };

  getEmpresas(itemId: any): Observable<HttpResponse<any>> {

    const params = {
      tpConta: itemId,
    };

    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/contaparcela/visualizatodasempresas/', { observe: 'response', params })
  }

  getCaixas(itemId: any): Observable<HttpResponse<any>> {

    const params = {
      tpConta: itemId,
    };

    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/conta/visualizatodascaixas/', { observe: 'response', params })
  }

  getCentroCustos(itemId: any): Observable<HttpResponse<any>> {

    const params = {
      tpConta: itemId,
    };
    
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/conta/centrocusto/', { observe: 'response', params })
  }

  getConta(itemId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/conta/visualiza/' + itemId, { observe: 'response' })
  }

  postConta(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/conta/inclui', item, { observe: 'response' })
  };

  putConta(itemId: any, item: any): Observable<HttpResponse<any>> {
    return this.http.put(
      environment.webApiSaguiControlUrl + '/api/conta/atualiza/' + itemId, item, { observe: 'response' })
  };

  getIntegracoesSOC(): Observable<HttpResponse<any>>{
    return this.http.get(
        environment.webApiSaguiControlUrl + '/api/conta/verifica-integracoes-soc/', {observe: 'response'})
}

  postVisualizaCliente(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/conta/visualizacliente', item, { observe: 'response' })
  };

  postVisualizaFornecedor(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/conta/visualizafornecedor', item, { observe: 'response' })
  };

  postVisualizaFuncionario(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/conta/visualizafuncionario', item, { observe: 'response' })
  };

  getParceiroPorNome(nome: any, tpConta: number): Observable<HttpResponse<any>> {
    let url = '';
    const params = new HttpParams().set('id', nome);

    switch (tpConta) {
      case 0:
        url = 'visualizaclientepornome';
        break;
    case 1:
        url = 'visualizafornecedorpornome';
        break;
     case 2:
        url = 'visualizafuncionariopornome';
        break;
  }

    return this.http.get(
       environment.webApiSaguiControlUrl + '/api/conta/' + url, { observe: 'response', params }
    );

  }

  getAdiantamentoPorContaId(itemId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/conta/visualizaadiantamentoporcontaid/' + itemId, { observe: 'response' })
  }

  postFiltraPosicaoConta(item: any): Observable<HttpResponse<any>> {
    console.log(item);
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/conta/contasporcliente', item, { observe: 'response' })
  };

  postContaPorPeriodo(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/conta/contasporperiodo/', item, { observe: 'response' })
  }

  postCaixaMovimentoPorFiltro(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/caixamovimento/visualizasaldoporfiltro/', item, { observe: 'response' })
  }

  getTextoTipoPagametno(tipoPagamento: number) {

    let textotipoPagamento = "";

    switch (tipoPagamento) {
      case 0:
        textotipoPagamento = "Dinheiro";
        break;
      case 1:
        textotipoPagamento = "Débito";
        break;
      case 2:
        textotipoPagamento = "Crédito";
        break;
      case 3:
        textotipoPagamento = "Boleto";
        break;
      case 4:
        textotipoPagamento = "Transferência";
        break;
      case 5:
        textotipoPagamento = "Cheque";
        break;
      case 6:
        textotipoPagamento = "Vale Refeição";
        break;
      case 7:
        textotipoPagamento = "Banco";
        break;
      case 8:
        textotipoPagamento = "Prestação de contas";
        break;
    }

    return textotipoPagamento;

  }

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

  }
}
