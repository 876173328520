
<mat-toolbar>
  <div class="md-toolbar-tools">
    <h1 mat-dialog-title>⚠️ Alteração no Faturamento Detectada!</h1>
    <span flex></span>
    <div class="posicionar-direita">
      <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
        <mat-icon>fullscreen_exit</mat-icon>
      </button>
      <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
        <mat-icon>fullscreen</mat-icon>
      </button>
      <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <br>
</mat-toolbar>

<mat-dialog-content>
 
  <br>

  <form [formGroup]="contratoTipoFaturamento">
    <div class="row">
      <div class="form-group col-md-12">     
        <p>Foram detectadas alterações no tipo de faturamento do contrato. Entretanto, os itens desse contrato já foram cadastrados. Como você gostaria de prosseguir?</p>
      </div>

    <div class="form-group col-md-12">
 
      <mat-radio-group formControlName="proximoPasso">
            <mat-radio-button value="naoAlterar">Não fazer nada</mat-radio-button>
            <mat-radio-button value="atualizar">Atualizar os valores dos itens do contrato automaticamente (sugerimos conferência após atualização)</mat-radio-button>
            <mat-radio-button value="apagar">Apagar todos os itens do contrato</mat-radio-button>
        </mat-radio-group>
    </div>
    </div>
  </form>
  <div class="row">
    <div class="col-md-12">

    </div>
  </div>
</mat-dialog-content>
<div mat-dialog-actions class="md-toolbar-tools"> 

  <div class="posicionar-direita">
   
    <button mat-raised-button color="warn" (click)="fecharDialog()">Cancelar Alteração</button>
    <button mat-raised-button color="primary" (click)="salvar()" [disabled]="contratoTipoFaturamento.invalid"><mat-icon>save</mat-icon> Salvar</button>
  </div>
</div>
