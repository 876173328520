import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable()
export class CompraService {

    postVisualizaComprasPorFiltro(item: any): Observable<HttpResponse<any>> {
        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/compra/visualizatodas/', item, { observe: 'response' })
    }

    getEmpresa(): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/compra/visualizatodasempresas', { observe: 'response' })
    }

    getTransportadoras(): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/compra/transportadoras', { observe: 'response' })
    }

    getCompra(itemId: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/compra/visualiza/' + itemId, { observe: 'response' })
    }

    getClientePorId(itemId: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/compra/visualizacliente/' + itemId, { observe: 'response' })
    }


    getClientePorNome(nome: any): Observable<HttpResponse<any>> {
        const params = new HttpParams().set('id', nome);
        return this.http.get(
          `${environment.webApiSaguiControlUrl}/api/compra/visualizaclientepornome`,
          { observe: 'response', params }
        );
      }

    getFornecedorPorId(itemId: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/compra/visualizafornecedor/' + itemId, { observe: 'response' })
    }

    getFornecedorPorNome(nome: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/compra/visualizafornecedorpornome/' + nome, { observe: 'response' })
    }

    postCompra(item: any): Observable<HttpResponse<any>> {
        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/compra/inclui/', item, { observe: 'response' })
    };

    putCompra(item: any, itemId: any): Observable<HttpResponse<any>> {
        return this.http.put(
            environment.webApiSaguiControlUrl + '/api/compra/atualiza/' + itemId, item, { observe: 'response' })
    };

    deleteCompra(itemId: any): Observable<HttpResponse<any>> {
        return this.http.delete(
            environment.webApiSaguiControlUrl + '/api/compra/exclui/' + itemId, { observe: 'response' })
    }

    getCompraItem(itemId: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/compraitem/visualiza/' + itemId, { observe: 'response' })
    }

    postIncluiCompraItem(item: any): Observable<HttpResponse<any>> {
        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/compraitem/inclui/', item, { observe: 'response' })
    };

    putAtualizaCompraItem(item: any, itemId: any): Observable<HttpResponse<any>> {
        return this.http.put(
            environment.webApiSaguiControlUrl + '/api/compraitem/atualiza/' + itemId, item, { observe: 'response' })
    };

    deleteCompraItem(itemId: any): Observable<HttpResponse<any>> {
        return this.http.delete(
            environment.webApiSaguiControlUrl + '/api/compraitem/exclui/' + itemId, { observe: 'response' })
    }

    getVisualizaDocumento(parametros: any): Observable<HttpResponse<any>> {

        const params = {
            idOrigem: parametros.idOrigem,
            tipoOrigem: parametros.tipoOrigem,
            tipoDestino: parametros.tipoDestino
        }

        return this.http.get(environment.webApiSaguiControlUrl + '/api/compra/visualizadocumentopororigemdestino', { observe: 'response', params })
    }

    postMovimentarEstoque(item: any): Observable<HttpResponse<any>> {
        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/compra/movimentarestoques', item, { observe: 'response' })
    };

    postGeraFatura(item: any): Observable<HttpResponse<any>> {
        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/compra/gerafatura', item, { observe: 'response' })
    };

    getProdutoPorNome(parametros: any): Observable<HttpResponse<any>> {

        const params = {
            id: parametros.id,
            tipoProduto: parametros.tipoProduto,
        }

        return this.http.get(environment.webApiSaguiControlUrl + '/api/compra/autocompleteproduto', { observe: 'response', params })
    }

    getEstoqueByProduto(itemId: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/compraitem/visualizaestoqueporproduto/' + itemId, { observe: 'response' })
    }

    getDesvincularNFe(itemId: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/compra/desvincularnfe/' + itemId, { observe: 'response' })
    }

    getAprovadores(): Observable<HttpResponse<any>> {
        return this.http.get(
          environment.webApiSaguiControlUrl + '/api/compra/visualizatodosaprovadores', { observe: 'response' })
      }

    postSolicitarAprovacao(item: any): Observable<HttpResponse<any>> {
        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/compra/solicitaraprovacao', item, { observe: 'response' })
    };

    postAprovar(item: any): Observable<HttpResponse<any>> {
        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/compra/aprovar', item, { observe: 'response' })
    };

    postDefinirComoRecebido(item: any): Observable<HttpResponse<any>> {
        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/compra/definircomorecebido', item, { observe: 'response' })
    };

    postDefinirComoemCriacao(item: any): Observable<HttpResponse<any>> {
        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/compra/definircomoemcriacao', item, { observe: 'response' })
    };

    postConfirmarCompra(item: any): Observable<HttpResponse<any>> {
        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/compra/confirmar', item, { observe: 'response' })
    };

    constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

    }
}
