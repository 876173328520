import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


export interface DialogData {
  faturaItensCodigoServicoDto: any
}

@Component({
  selector: 'app-confirmacao-divergencia-cservico-dialog',
  templateUrl: './confirmacao-divergencia-cservico-dialog.component.html',
  styleUrls: ['./confirmacao-divergencia-cservico-dialog.component.css']
})
export class ConfirmacaoDivergenciaCservicoDialogComponent implements OnInit {
  maximizado: boolean;
  faturaItensCodigoServicoDto: any;
  displayedColumns: string[] = ['itens', 'codigoServico'];

  constructor(
    public dialogRef: MatDialogRef<ConfirmacaoDivergenciaCservicoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  ngOnInit() {

    let innerWidth = window.innerWidth;

    this.faturaItensCodigoServicoDto = this.data.faturaItensCodigoServicoDto;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }


  fecharDialog() {
    this.dialogRef.close();
  }

  maximizarDialog() {
    this.dialogRef.updateSize('100%', '100%');
    this.maximizado = true;
  }

  restaurarDialog() {
    let innerWidth = window.innerWidth;

    if (innerWidth < 1024) {
      this.dialogRef.updateSize('100%', '100vh');
    } else {
      this.dialogRef.updateSize('80%', '100vh');
    }

    this.maximizado = false;
  }

}
