<mat-toolbar>
  <div class="md-toolbar-tools">
    <h1 mat-dialog-title>{{VerificaVendaTipo(venda.vendaTipo)}} - {{venda.vendaId}}</h1>
    <span flex></span>
    <div class="posicionar-direita">
      <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
        <mat-icon>fullscreen_exit</mat-icon>
      </button>
      <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
        <mat-icon>fullscreen</mat-icon>
      </button>
      <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
        <mat-icon>close</mat-icon>
      </button>

    </div>
  </div>
</mat-toolbar>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" matTooltip="Salvar" (click)="salvar()" [disabled]="venda.vendaStatus == 99">
    <mat-icon>save</mat-icon>
  </button>
  <button mat-raised-button (click)="imprimir()" *ngIf="venda.vendaId > 0" matTooltip="Imprimir">
    <mat-icon>print</mat-icon>
  </button>
  <button mat-raised-button [matMenuTriggerFor]="opcoes" matTooltip="Opções">
    <mat-icon>more_horiz</mat-icon>
  </button>
  <mat-menu #opcoes>
    <button *ngIf="venda.vendaId > 0 && venda.vendaTipo != 1" (click)="movimentaEstoque()" mat-menu-item>
      Movimentar Estoque
    </button>
    <button *ngIf="venda.vendaId > 0 && venda.remessaId == 0  && venda.vendaStatus != 99 && venda.vendaTipo == 0"
      (click)="geraRemessa(vendaCadastroForm)" mat-menu-item>Gerar Remessa
    </button>
    <button *ngIf="venda.remessaId > 0 " (click)="exibeRemessa(venda.remessaId)" mat-menu-item>
      <mat-icon>edit</mat-icon> Remessa</button>
    <button *ngIf="venda.vendaId > 0 && venda.faturaId == 0  && venda.vendaStatus < 3 " (click)="faturar()"
      mat-menu-item>Faturar</button>
    <button *ngIf="venda.faturaId > 0 " (click)="VisualizaFatura(venda.faturaId)"
      mat-menu-item><mat-icon>edit</mat-icon> Fatura</button>
    <button *ngIf="venda.vendaId > 0 && venda.vendaTipo == 0 && venda.nfeId == 0 && venda.vendaStatus != 99  "
      (click)="geraNFe(vendaCadastroForm)" mat-menu-item>Gerar NF-e</button>
    <button *ngIf="venda.nfeId > 0 " (click)="VisualizaNFe(venda.nfeId)" mat-menu-item><mat-icon>edit</mat-icon>
      NF-e</button>
    <button *ngIf="venda.vendaId > 0 && venda.vendaStatus < 4 && venda.vendaTipo == 0 "
      (click)="geraDevolucao(vendaCadastroForm)" mat-menu-item>Gerar Devolução</button>
    <button *ngIf="venda.vendaId > 0 && venda.vendaTipo == 2 " (click)="GeraDevolucaoEstoque(vendaCadastroForm)"
      mat-menu-item>Devolução de Estoque</button>
    <!--<button *ngIf="venda.vendaId > 0 && venda.vendaTipo < 1 " (click)="GeraEstornoFinanceiro(vendaCadastroForm)" mat-menu-item>Estornar Financeiro</button>-->
    <button *ngIf="venda.vendaId > 0 && venda.vendaTipo == 2 && venda.nfeId == 0 "
      (click)="GeraNFeDevolucao(vendaCadastroForm)" mat-menu-item>Gerar NF-e de Devolução</button>
    <button *ngIf="venda.vendaId > 0 && venda.vendaTipo == 2 && venda.nfeId == 0 "
      (click)="ReceberNFeDevolucaoCliente(vendaCadastroForm)" mat-menu-item>Receber NF-e de Devolução do
      Cliente</button>
    <button *ngIf="venda.vendaId > 0" (click)="gerarContrato()" mat-menu-item>
      Gerar Contrato
    </button>
    <button *ngIf="venda.vendaId > 0" (click)="visualizaFluxoDocumentos()" mat-menu-item>
      Documentos Relacionados</button>
    <button
      [disabled]="venda.vendaId > 0 && venda.faturaId == 0 && venda.remessaId == 0 && venda.nfeId == 0 && venda.vendaStatus < 4 ? false : true"
      (click)="cancelarVenda(venda.vendaId)" mat-menu-item>Cancelar
    </button>
    <button *ngIf="!IsExistente" (click)="criarOrdemServico(venda.vendaId)" mat-menu-item>Criar Ordem de Serviço
    </button>
  </mat-menu>
</div>
<br>
<mat-dialog-content>
  <form [formGroup]="vendaCadastroForm">
    <mat-tab-group>
      <mat-tab label="Geral">
        <br />
        <div class="row">
          <mat-form-field class="col-md-6 col-xs-12 form-group">
            <mat-label>Emitente</mat-label>
            <mat-select formControlName="empresaId" (selectionChange)="buscaListaServico($event.value)">
              <mat-option *ngFor="let empresa of empresas" [value]="empresa.empresaId">
                {{ empresa.nomeFantasia }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="col-md-6 col-xs-12 form-group">
            <mat-label>Vendedor</mat-label>
            <mat-select formControlName="vendedorId">
              <mat-option *ngFor="let vendedor of vendedores" [value]="vendedor.funcionarioId">
                {{vendedor.nomeFantasia}}
              </mat-option>
            </mat-select>
            <mat-hint>Para cadastrar vendedores acesse RH > Funcionários > E
              selecione o Tipo de Função 'Vendedor'</mat-hint>
          </mat-form-field>

        </div>
        <div class="row">
          <mat-form-field class="col-md-6 col-xs-12 form-group">
            <mat-label>Cliente</mat-label>
            <input type="text" matInput [matAutocomplete]="auto" formControlName="clienteAutoComplete">
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let parceiro of clientes" [value]="parceiro.nomeFantasia"
                (click)="determinaCliente(parceiro)">
                CNPJ: {{parceiro.cnpj}} - {{parceiro.nomeFantasia}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div class="col-md-3">
            <mat-form-field>
              <mat-label>Data da Venda</mat-label>
              <input matInput [matDatepicker]="picker1" formControlName="dataVenda">
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field>
              <mat-label>Data Entrega</mat-label>
              <input matInput [matDatepicker]="picker2" formControlName="dataPrevista">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
              <mat-hint>Data prevista para entrega</mat-hint>
            </mat-form-field>
          </div>
        </div>
        <div class="row" *ngIf="parceirosEncontrados == false">
          <div class="col-md-6 col-xs-12 form-group">
            <p>Nenhum cliente com o nome "{{vendaCadastroForm.value.clienteAutoComplete}}" foi encontrado.</p>
            <a (click)="clienteModal(0, vendaCadastroForm.value.clienteAutoComplete)" style="color: #F37431; text-decoration: underline; cursor: pointer;">
              Criar um novo cliente com esse nome
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-xs-12 form-group">
            <label id="example-radio-group-label">Selecione o tipo de Venda:</label>
            <mat-radio-group aria-label="Selecione uma opção" class="col-md-12 col-xs-12 form-group"
              formControlName="naturezaVenda">
              <mat-radio-button [value]="0">Nova Venda</mat-radio-button>
              <mat-radio-button [value]="1">Renovação</mat-radio-button>
            </mat-radio-group>
        </div>
        </div>
      </mat-tab>
      <mat-tab label="Entrega e Observações">
        <br />
        <div class="row">
          <mat-form-field class="col-md-6 col-xs-12 form-group">
            <mat-label>Local de Entrega</mat-label>
            <input type="text" matInput [matAutocomplete]="localEntregaMatAutocomplete"
              formControlName="localEntregaAutoComplete">
            <mat-autocomplete #localEntregaMatAutocomplete="matAutocomplete">
              <mat-option *ngFor="let localEntrega of locaisEntrega" [value]="localEntrega.nomeFantasia"
                (click)="DeterminalocalEntregaId(localEntrega.clienteId)">
                CNPJ: {{localEntrega.cnpj}} - {{localEntrega.nomeFantasia}}
              </mat-option>
            </mat-autocomplete>
            <mat-hint>Local de Entrega (se diferente do destinatário)</mat-hint>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field class="form-group col-md-6">
            <mat-label>Comunicação Interna</mat-label>
            <textarea matInput rows="5" formControlName="comunicacaoInterna"></textarea>
          </mat-form-field>
        </div>
      </mat-tab>
      <mat-tab label="Financeiro">
        <br />
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="row">
              <mat-form-field class="col-md-6 col-xs-12 form-group">
                <mat-label>Indicador de Pagamento</mat-label>
                <mat-select formControlName="indPag" name="indPag">
                  <mat-option [value]="0">Pagamento a vista</mat-option>
                  <mat-option [value]="1">Pagamento a prazo</mat-option>
                  <mat-option [value]="2">Outros</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="col-md-6 col-xs-12 form-group">
                <mat-label>Tipo de Pagamento</mat-label>
                <mat-select formControlName="tipoPagamento" name="tipoPagamento">
                  <mat-option [value]="0">Dinheiro</mat-option>
                  <mat-option [value]="1">Débito</mat-option>
                  <mat-option [value]="2">Crédito</mat-option>
                  <mat-option [value]="3">Boleto</mat-option>
                  <mat-option [value]="11">PIX</mat-option>
                  <mat-option [value]="4">Transferência</mat-option>
                  <mat-option [value]="5">Cheque</mat-option>
                  <mat-option [value]="6">Vale Refeição</mat-option>
                  <mat-option [value]="7">Banco</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="col-md-3 col-xs-12 form-group" *ngIf="venda.vendaTipo != 1">
                <mat-label>Consumidor Final</mat-label>
                <mat-select formControlName="indFinal" name="indFinal">
                  <mat-option [value]="0">Normal</mat-option>
                  <mat-option [value]="1">Consumidor final</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="col-md-3 col-xs-12 form-group" *ngIf="venda.vendaTipo != 1">
                <mat-label>Forma de Emissão</mat-label>
                <mat-select formControlName="indPres" name="indPres">
                  <mat-option [value]="0">Não se aplica</mat-option>
                  <mat-option [value]="1">Operação presencial</mat-option>
                  <mat-option [value]="2">Operação não presencial, pela Internet
                  </mat-option>
                  <mat-option [value]="3">Operação não presencial, Teleatendimento
                  </mat-option>
                  <mat-option [value]="4">NFC-e em operação com entrega a domicílio
                  </mat-option>
                  <mat-option [value]="9">Operação não presencial, outros</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12">
            <mat-card>
              <mat-card-header>
                <mat-card-title>Parcelas</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div class="row">
                  <mat-form-field class="col-md-4 col-xs-12 form-group">
                    <mat-label>Número de Parcelas</mat-label>
                    <input type="number" matInput formControlName="numeroParcelas">
                  </mat-form-field>
                  <form [formGroup]="dataVencimentoParcela" >
                    <mat-form-field class="col-md-4 col-xs-12 form-group">
                      <mat-label>Vencimento 1ª Parcela</mat-label>
                      <input matInput formControlName="dataVencimentoParcela" [matDatepicker]="picker6" [min]="currentDate">
                      <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
                      <mat-datepicker #picker6></mat-datepicker>
                    </mat-form-field>
                  </form>
                </div>
                <div id="itens">
                  <div class="row">
                    <div class="col-md-12">
                      <button mat-mini-fab color="accent" (click)="gerarParcelas(vendaCadastroForm.value)"
                        *ngIf="venda.vendaId > 0 && venda.faturaId == 0">
                        <mat-icon>cached</mat-icon>
                      </button>
                      <button mat-mini-fab color="accent" (click)="adicionarNovaParcelaVazia()"
                        matTooltip="Adicionar Venda" *ngIf="venda.vendaId > 0 && venda.faturaId == 0"
                        class="sagui-button">
                        <mat-icon>add</mat-icon>
                      </button>
                    </div>
                  </div>
                  <div id="list" class="row">
                    <div class="table-responsive col-md-12">
                      <table class="table table-striped" cellspacing="0" cellpadding="0">
                        <thead>
                          <tr>
                            <th>Data Vencimento</th>
                            <th>Valor Parcela</th>
                            <th>Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let vendaParcela of vendaParcelas;trackBy:trackByIndex;">
                            <td>
                              <mat-form-field appearance="legacy">
                                <input matInput [matDatepicker]="picker3" [(ngModel)]="vendaParcela.dataVencimento"
                                  (click)="picker3.open()" (keydown)="false" [ngModelOptions]="{standalone: true}">
                                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                <mat-datepicker #picker3></mat-datepicker>
                              </mat-form-field>
                            </td>
                            <td>
                              <mat-form-field appearance="legacy">
                                <input currencyMask matInput [(ngModel)]="vendaParcela.valorParcela"
                                  [ngModelOptions]="{standalone: true}">
                              </mat-form-field>
                            <td class="actions">
                              <a mat-mini-fab (click)="DeleteVendaParcela(vendaParcela.vendaParcelaId)"
                                *ngIf="venda.faturaId > 0 ? false : true" color="warn" matTooltip="Deletar">
                                <mat-icon>delete</mat-icon>
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Tranportes" *ngIf="venda.vendaTipo != 1">
        <br />
        <div class="row">
          <mat-form-field class="col-md-6 col-xs-12 form-group">
            <mat-label>Transportadora</mat-label>
            <mat-select formControlName="transportadoraId">
              <mat-option *ngFor="let transportadora of transportadoras" [value]="transportadora.transportadoraId">
                {{transportadora.nomeFantasia}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field class="col-md-6 col-xs-12 form-group">
            <mat-label>Tipo de Entrega</mat-label>
            <mat-select formControlName="tipoEntrega" name="tipoEntrega">
              <mat-option [value]="1">Transportadora</mat-option>
              <mat-option [value]="2">Veículo Próprio</mat-option>
              <mat-option [value]="3">Cliente Retira</mat-option>
              <mat-option [value]="4">Correios</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field class="col-md-6 col-xs-12 form-group">
            <mat-label>Tipo de Frete</mat-label>
            <mat-select formControlName="modFrete" name="modFrete">
              <mat-option [value]="0">Contratação do frete por conta do remetente (CIF)
              </mat-option>
              <mat-option [value]="1">Contratação do frete por conta do destinatário (FOB)
              </mat-option>
              <mat-option [value]="2">Transporte próprio por conta do remetente</mat-option>
              <mat-option [value]="3">Transporte próprio por conta do destinatário
              </mat-option>
              <mat-option [value]="9">Sem ocorrência de transporte</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field class="col-md-6 col-xs-12 form-group">
            <mat-label>Quantidade de Volumes Transportados</mat-label>
            <input type="number" matInput formControlName="qVol">
          </mat-form-field>
          <mat-form-field class="col-md-6 col-xs-12 form-group">
            <mat-label>Espécie dos Volumes Transportados</mat-label>
            <input type="text" matInput formControlName="especie">
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field class="form-group col-md-6 col-xs-12">
            <mat-label>Peso Líquido (KG)</mat-label>
            <input type="number" matInput formControlName="pesoBruto">
          </mat-form-field>
          <mat-form-field class="form-group col-md-6 col-xs-12">
            <mat-label>Peso Bruto (KG)</mat-label>
            <input type="number" matInput formControlName="pesoLiquido">
          </mat-form-field>
        </div>
      </mat-tab>
      <mat-tab label="Fiscal" *ngIf="vendaCadastroForm.get('vendaId').value > 0">
        <br />
        <div class="row">
          <mat-form-field class="col-md-3 col-xs-12 form-group">
            <mat-label>Modalidade Nota Fiscal</mat-label>
            <mat-select formControlName="modNFe" name="modNFe">
              <mat-option [value]="0">0 - Não se Aplica</mat-option>
              <mat-option [value]="55">55 - NF-e</mat-option>
              <mat-option [value]="65">65 - NFC-e</mat-option>
              <mat-option [value]="99">99 - NFS-e</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <br/>
        <div class="row">
          <div class="col-md-2 col-xs-12 form-group">
            <label>Total <br> {{vendaCadastroForm.get('valorTotal').value | currency}}</label>
          </div>
          <div class="col-md-2 col-xs-12 form-group">
            <label>Desconto <br> {{vendaCadastroForm.get('valorDesconto').value |
              currency}}</label>
          </div>
          <div class="col-md-2 col-xs-12 form-group">
            <label>Base de Cálculo <br> {{vendaCadastroForm.get('valorBruto').value |
              currency}}</label>
          </div>
          <div class="col-md-2 col-xs-12 form-group">
            <label>Após Retenções<br>
              {{vendaCadastroForm.get('valorAposRetencoes').value | currency}}</label>
          </div>
          <div class="col-md-3 col-xs-12 form-group">
            <label>Valor Líquido (Bruto - Impostos) <br>
              {{vendaCadastroForm.get('valorLiquido').value | currency}}</label>
          </div>
        </div>
        <div id="list" class="row">
          <div class="table-responsive col-md-12">
            <table class="table table-striped" cellspacing="0" cellpadding="0">
              <thead>
                <tr>
                  <th>Imposto</th>
                  <th>Valor</th>
                  <th>%</th>
                  <th>Retido na Fonte</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="venda.vendaTipo == 1">
                  <td>ISS</td>
                  <td>{{venda.valorIss | currency}}</td>
                  <td>{{(venda.valorIss * 100 / venda.valorBruto).toFixed(2)}} %</td>
                  <td>
                    <mat-checkbox formControlName="issRetido"></mat-checkbox>
                  </td>
                </tr>
                <tr *ngIf="venda.vendaTipo != 1">
                  <td>ICMS</td>
                  <td>{{venda.valorIcms | currency}}</td>
                  <td>{{(venda.valorIcms * 100 / venda.valorBruto).toFixed(2)}} %</td>
                  <td></td>
                </tr>
                <tr *ngIf="venda.vendaTipo != 1">
                  <td>ICMS ST</td>
                  <td>{{venda.valorIcmsSt | currency}}</td>
                  <td>{{(venda.valorIcmsSt * 100 / venda.valorBruto).toFixed(2)}} %</td>
                  <td></td>
                </tr>
                <tr *ngIf="venda.vendaTipo != 1">
                  <td>IPI</td>
                  <td>{{venda.valorIpi | currency}}</td>
                  <td>{{(venda.valorIpi * 100 / venda.valorBruto).toFixed(2)}} %</td>
                  <td></td>
                </tr>
                <tr>
                  <td>PIS</td>
                  <td>{{venda.valorPis | currency}}</td>
                  <td>{{(venda.valorPis * 100 / venda.valorBruto).toFixed(2)}} %</td>
                  <td>
                    <mat-checkbox formControlName="pisRetido"></mat-checkbox>
                  </td>
                </tr>
                <tr *ngIf="venda.vendaTipo != 1">
                  <td>PIS ST</td>
                  <td>{{venda.valorPisSt | currency}}</td>
                  <td>{{(venda.valorPisSt * 100 / venda.valorBruto).toFixed(2)}} %</td>
                  <td></td>
                </tr>
                <tr>
                  <td>cofins</td>
                  <td>{{venda.valorCofins | currency}}</td>
                  <td>{{(venda.valorCofins * 100 / venda.valorBruto).toFixed(2)}} %</td>
                  <td>
                    <mat-checkbox formControlName="cofinsRetido"></mat-checkbox>
                  </td>
                </tr>
                <tr *ngIf="venda.vendaTipo != 1">
                  <td>cofins ST</td>
                  <td>{{venda.valorCofinsSt | currency}}</td>
                  <td>{{(venda.valorCofinsSt * 100 / venda.valorBruto).toFixed(2)}} %</td>
                  <td></td>
                </tr>
                <tr *ngIf="venda.vendaTipo == 1">
                  <td>CSLL</td>
                  <td>{{venda.valorCsll | currency}}</td>
                  <td>{{(venda.valorCsll * 100 / venda.valorBruto).toFixed(2)}} %</td>
                  <td>
                    <mat-checkbox formControlName="csllRetido"></mat-checkbox>
                  </td>
                </tr>
                <tr *ngIf="venda.vendaTipo == 1">
                  <td>IRPJ</td>
                  <td>{{venda.valorIr | currency}}</td>
                  <td>{{(venda.valorIr * 100 / venda.valorBruto).toFixed(2)}} %</td>
                  <td>
                    <mat-checkbox formControlName="irRetido"></mat-checkbox>
                  </td>
                </tr>
                <tr *ngIf="venda.vendaTipo == 1">
                  <td>INSS</td>
                  <td>{{venda.valorInss | currency}}</td>
                  <td>{{(venda.valorInss * 100 / venda.valorBruto).toFixed(2)}} %</td>
                  <td>
                    <mat-checkbox formControlName="inssRetido"></mat-checkbox>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </form>
  <div class="row sagui-form-card" *ngIf="vendaCadastroForm?.value?.vendaId > 0">
    <div class="col-md-6 col-sm-6 col-xs-6">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Descontos em Reais: {{vendaCadastroForm.value.somaTotalDescontosEmReais | currency}}
            <button
            mat-mini-fab  color="accent" class="sagui-button sagui-mini-button report-card-icon"
            (click)="openDialogDescontoAdicional()">
            <mat-icon>add</mat-icon>
          </button></mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <span class="sagui-card-content">
          Desconto itens R${{vendaCadastroForm.value.descontosItensEmReais}} +
          Desconto Adicionais R${{vendaCadastroForm.value.descontosAdicionaisEmReais}}
          </span>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-header>
          <mat-card-title>Total s/ descontos: {{vendaCadastroForm.value.valorTotal |
            currency}}</mat-card-title>
        </mat-card-header>
      </mat-card>


    </div>
    <div class="col-md-6 col-sm-6 col-xs-6">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Desconto em Porcentagem: {{vendaCadastroForm.value.somaTotalDescontosEmPorcentagem}}%
            <button mat-mini-fab  color="accent" class="sagui-button sagui-mini-button report-card-icon"
            (click)="openDialogDescontoAdicional()">
            <mat-icon>add</mat-icon>
          </button></mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <span class="sagui-card-content">
          Desconto itens R${{vendaCadastroForm.value.descontosItensEmReais}} +
          Desconto Adicionais R${{vendaCadastroForm.value.descontosAdicionaisEmReais}}
          </span>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-header>
          <mat-card-title>Total com Descontos: {{vendaCadastroForm.value.valorBruto |
            currency}}</mat-card-title>
        </mat-card-header>
      </mat-card>
    </div>
  </div>
  <hr>
  <div *ngIf="vendaCadastroForm.get('vendaStatus').value < 4">
    <h2>Itens</h2>
    <form [formGroup]="vendaItemForm">
      <div class="row">
        <mat-form-field class="col-md-2 col-xs-12 form-group" *ngIf="IVenda.vendaTipo != 1">
          <mat-label>Tipo de Item</mat-label>
          <mat-select formControlName="produtoTipo">
            <mat-option [value]="0">Produto Acabado</mat-option>
            <mat-option [value]="2">Uso e Consumo</mat-option>
            <mat-option [value]="3">Ativo Imobilizado</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="col-md-3 col-xs-12 form-group">
          <mat-label>Digite o Nome do Produto ou Serviço</mat-label>
          <input type="text" matInput [matAutocomplete]="auto" formControlName="produtoAutoComplete">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let produto of produtos" [value]="produto.descricao"
              (click)="determinaProdutoId(produto)">
              {{produto.descricao}} - {{produto.vlrComercial | currency}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field class="col-md-2 col-xs-12 form-group" *ngIf="IVenda.vendaTipo != 1">
          <mat-label>CFOP</mat-label>
          <mat-select formControlName="cfop">
            <mat-option *ngFor="let cfop of cfops" [value]="cfop.codCfop">
              {{cfop.codCfop + ' - ' + cfop.descricao}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="col-md-1 col-xs-12 form-group">
          <mat-label>Quantidade"</mat-label>
          <input type="number" matInput formControlName="qtdComercial" (blur)="onChangeValorComercial()">
        </mat-form-field>
        <mat-form-field class="col-md-1 col-xs-12 form-group">
          <mat-label>V. Unit.</mat-label>
          <input currencyMask matInput formControlName="vlrComercial" (blur)="onChangeValorComercial()"
            matTooltip="Valor Unitário">
        </mat-form-field>
        <mat-form-field class="col-md-2 col-xs-12 form-group">
          <mat-label>Total</mat-label>
          <input currencyMask matInput formControlName="valorTotal" [disabled]="true">
        </mat-form-field>
        <div class="col-md-1">
          <button mat-mini-fab (click)="adicionarItem()" matTooltip="Adicionar Item">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4" *ngIf="vendaConfig?.permiteLancamentoManual == true">
          <button mat-stroked-button (click)="modalItem(0)"
            matTooltip="Para criar o item manualmente digitando todos os dados">
            Lançamento Manual
          </button>
        </div>
      </div>
    </form>
  </div>
  <hr>
  <div id="list" class="row">
    <div class="table-responsive col-md-12">
      <table class="table table-striped" cellspacing="0" cellpadding="0">
        <thead>
          <tr>
            <th class="actions">Ações</th>
            <th>Código</th>
            <th>Descrição</th>
            <th>Lote</th>
            <th>NCM</th>
            <th>CFOP</th>
            <th>Unid.</th>
            <th>Valor Un.</th>
            <th>Qtde.</th>
            <th>Total</th>
            <th>Desconto</th>
            <th>Com Desconto <small>+ Adicionais</small></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let vendaitem of venda.vendaItens">
            <td class="actions">
              <a mat-mini-fab (click)="modalItem(vendaitem.vendaItemId)" matTooltip="Editar Venda Item"
                *ngIf="venda.vendaId > 0 && venda.vendaStatus < 4">
                <mat-icon>edit</mat-icon></a>
              <a mat-mini-fab color="warn" (click)="deleteVendaItem(vendaitem.vendaItemId)"
                matTooltip="Deletar Venda Item" *ngIf="venda.vendaId > 0 && venda.vendaStatus < 4">
                <mat-icon>delete</mat-icon></a>
            </td>
            <td>{{vendaitem.codProd}}</td>
            <td>{{vendaitem.descricao}}</td>
            <td>{{vendaitem.numLote}}</td>
            <td>{{vendaitem.ncm}}</td>
            <td>{{vendaitem.cfop}}</td>
            <td>{{vendaitem.unComercial}}</td>
            <td>{{vendaitem.vlrComercial | currency}}</td>
            <td>{{vendaitem.qtdComercial}}</td>
            <td>{{vendaitem.valorTotal | currency}}</td>
            <td>{{vendaitem.valorDesconto | currency}}</td>
            <td>{{vendaitem.valorBruto | currency}}</td>
          </tr>

        </tbody>
      </table>
    </div>
  </div>
  <div style="display:flex; justify-content: center; background-color: #c3c3c3; display: none">
    <div class="danfe" id="printVenda">
      <div class="page" *ngFor="let vendatemp of vendas">
        <div class="bloco">
          <table id="dados-nfe" class="tabelaDanfe">
            <thead>
              <tr>
                <td class="campo">
                  <img [src]="'data:image/JPEG;base64,'+ empresa.logoByte" id="logoCliente">
                </td>
                <td rowspan="4" class="campo quadro-identificacao-emitente">
                  <div class="conteudoPedido negrito">
                    <h3>{{'Pedido de Venda'}}
                    </h3>
                    <h5>{{empresa.nomeFantasia}}</h5>
                  </div>
                  <div class="conteudoPedido">
                    {{empresa.logradouro}}, {{empresa.numero}} , {{empresa.bairro}} <br />
                    {{empresa.municipio?.descricao}} - {{empresa.municipio?.uf.siglaUF}}, CEP:
                    {{empresa.cep}}
                    <br />
                    Tel: {{empresa.telefone}} <br />
                    e-mail: {{empresa.emailVenda}} <br />
                    IE: {{empresa.inscEstadual}} - CNPJ: {{empresa.cnpj}}
                  </div>
                </td>
                <td class="campo data-emissao">
                  <br>
                  <div class="conteudoPedido negrito">EMISSÃO</div>
                  <div class="conteudoPedido">
                    <h5>{{vendatemp.dataVenda | date:'dd/MM/yyyy'}}</h5>
                  </div>
                  <div class="conteudoPedido negrito">PEDIDO</div>
                  <div class="conteudoPedido negrito">
                    <h5>{{vendatemp.vendaId}}</h5>
                  </div>
                  <div class="conteudoPedido negrito">FATURA</div>
                  <div class="conteudoPedido negrito">
                    <h5>{{vendatemp.faturaId}}</h5>
                  </div>
                </td>
              </tr>
            </thead>
          </table>
        </div>
        <div class="bloco">
          <div class="conteudoPedido negrito fonte-7-pontos">DESTINATÁRIO/REMETENTE</div>
          <table>
            <tr>
              <td colspan="3" class="campo razao-social">
                <div class="conteudoPedido fonte-5-pontos">NOME / RAZÃO SOCIAL</div>
                <div class="conteudoPedido">
                  {{cliente.razaoSocial}}
                </div>
              </td>
              <td class="campo cnpj-destinatario">
                <div class="conteudoPedido fonte-5-pontos">CNPJ / CPF</div>
                <div class="conteudoPedido">
                  {{cliente.cnpj != undefined ? cliente.cnpj : cliente.cpf}}
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" class="campo endereco-destinatario">
                <div class="conteudoPedido fonte-5-pontos">ENDEREÇO</div>
                <div class="conteudoPedido">
                  {{cliente.logradouro}} &nbsp;
                  {{cliente.numero}}
                </div>
              </td>
              <td class="campo bairro-destinatario">
                <div class="conteudoPedido fonte-5-pontos">BAIRRO / DISTRITO</div>
                <div class="conteudoPedido">
                  {{cliente.bairro}}
                </div>
              </td>
              <td class="campo cep-destinatario">
                <div class="conteudoPedido fonte-5-pontos">CEP</div>
                <div class="conteudoPedido">
                  {{cliente.cep}}
                </div>
              </td>
            </tr>
            <tr>
              <td class="campo municipio-destinatario">
                <div class="conteudoPedido fonte-5-pontos">MUNICÍPIO</div>
                <div class="conteudoPedido">
                  {{cliente.municipio?.descricao}}
                </div>
              </td>
              <td class="campo fone-fax-destinatario">
                <div class="conteudoPedido fonte-5-pontos">TELEFONE</div>
                <div class="conteudoPedido">
                  {{cliente.telefone}}
                </div>
              </td>
              <td class="campo uf-destinatario">
                <div class="conteudoPedido fonte-5-pontos">UF</div>
                <div class="conteudoPedido">
                  {{cliente.municipio?.uf.siglaUF}}
                </div>
              </td>
              <td class="campo inscricao-destinatario">
                <div class="conteudoPedido fonte-5-pontos">INSCRIÇÃO ESTADUAL</div>
                <div class="conteudoPedido">
                  {{cliente.inscEstadual}}
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="bloco">
          <div class="conteudoPedido negrito fonte-7-pontos">TRANSPORTE</div>
          <table>
            <tr>
              <td colspan="3" class="campo razao-social">
                <div class="conteudoPedido fonte-5-pontos">NOME / RAZÃO SOCIAL</div>
                <div class="conteudoPedido" *ngIf="transportadora != undefined">
                  {{transportadora.razaoSocial}}
                </div>
              </td>
              <td class="campo endereco-destinatario">
                <div class="conteudoPedido fonte-5-pontos">CNPJ / CPF</div>
                <div class="conteudoPedido" *ngIf="transportadora != undefined">
                  {{transportadora.cnpj}}
                </div>
              </td>
            </tr>
          </table>
          <table class="sem-borda-superior">
            <tr>
              <td class="campo municipio-destinatario">
                <div class="conteudo fonte-5-pontos">MODALIDADE DO FRETE</div>
                <div class="conteudo">
                  {{vendatemp.modalidadeFrete}}
                </div>
              </td>
              <td class="campo quantidade-transportador">
                <div class="conteudo fonte-5-pontos">QUANTIDADE</div>
                <div class="conteudo">
                  {{vendatemp.volumesTransportados}}
                </div>
              </td>
              <td colspan="2" class="campo especie-transportador">
                <div class="conteudo fonte-5-pontos">ESPÉCIE</div>
                <div class="conteudo">
                  {{vendatemp.especieVolumesTransportados}}
                </div>
              </td>
              <td class="campo peso-bruto-transportador">
                <div class="conteudo fonte-5-pontos">PESO BRUTO</div>
                <div class="conteudo">
                  {{vendatemp.pesoBrutoTransportado}}
                </div>
              </td>
              <td class="campo peso-liquido-transportador">
                <div class="conteudo fonte-5-pontos">PESO LÍQUIDO</div>
                <div class="conteudo">
                  {{vendatemp.pesoLiquidoTransportado}}
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="bloco">
          <div class="conteudoPedido negrito fonte-7-pontos">VALORES TOTAIS</div>
          <table>
            <tr>
              <td class="campo base-calculo-icms">
                <div class="conteudoPedido fonte-5-pontos">TOTAL DA VENDA</div>
                <div class="conteudoPedido">
                  R$ {{vendatemp.totalVenda | number:'1.2-2'}}
                </div>
              </td>
              <td class="campo valor-do-icms">
                <div class="conteudoPedido fonte-5-pontos">DESCONTOS</div>
                <div class="conteudoPedido">
                  R$ {{vendatemp.totalDescontos | number:'1.2-2'}}
                </div>
              </td>
              <td class="campo base-de-cálculo-do-icms-st">
                <div class="conteudoPedido fonte-5-pontos">TOTAL GERAL</div>
                <div class="conteudoPedido">
                  R$ {{vendatemp.totalGeral| number:'1.2-2'}}
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="bloco">
          <div class="conteudoPedido negrito fonte-7-pontos">DADOS DOS PRODUTOS / SERVIÇOS</div>
          <table id="dados-produtos">
            <thead>
              <tr>
                <th class="campo">
                  <div class="conteudoPedido fonte-5-pontos">CÓDIGO</div>
                </th>
                <th class="campo">
                  <div class="conteudoPedido fonte-5-pontos">DESCRIÇÃO DOS PRODUTOS / SERVIÇOS
                  </div>
                </th>
                <th class="campo">
                  <div class="conteudoPedido fonte-5-pontos">NCM</div>
                </th>
                <th class="campo">
                  <div class="conteudoPedido fonte-5-pontos">LOTE</div>
                </th>
                <th class="campo">
                  <div class="conteudoPedido fonte-5-pontos">UNID</div>
                </th>
                <th class="campo">
                  <div class="conteudoPedido fonte-5-pontos">QUANT</div>
                </th>
                <th class="campo">
                  <div class="conteudoPedido fonte-5-pontos">VLR.UNIT.</div>
                </th>
                <th class="campo">
                  <div class="conteudoPedido fonte-5-pontos">VLR.TOTAL</div>
                </th>
                <th class="campo">
                  <div class="conteudoPedido fonte-5-pontos">DESC.</div>
                </th>
                <th class="campo">
                  <div class="conteudoPedido fonte-5-pontos">COM DESC.</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of vendatemp.vendaItens">
                <!--<td class="campo sem-borda-superior-inferior">-->
                <td class="campo ">
                  <div class="conteudoPedido fonte-5-pontos">
                    {{item.codProd}}
                  </div>
                </td>
                <!--<td class="campo sem-borda-superior-inferior">-->
                <td class="campo ">
                  <div class="conteudoPedido fonte-5-pontos">
                    {{item.descricao}}
                  </div>
                </td>
                <!--<td class="campo sem-borda-superior-inferior">-->
                <td class="campo ">
                  <div class="conteudoPedido fonte-5-pontos">
                    {{item.ncm}}
                  </div>
                </td>
                <td class="campo ">
                  <div class="conteudoPedido fonte-5-pontos">
                    {{item.numLote}}
                  </div>
                </td>
                <!--<td class="campo sem-borda-superior-inferior">-->
                <td class="campo ">
                  <div class="conteudoPedido fonte-5-pontos">
                    {{item.unComercial}}
                  </div>
                </td>
                <!--<td class="campo sem-borda-superior-inferior">-->
                <td class="campo ">
                  <div class="conteudoPedido fonte-5-pontos">
                    {{item.qtdComercial}}
                  </div>
                </td>
                <!--<td class="campo sem-borda-superior-inferior">-->
                <td class="campo ">
                  <div class="conteudoPedido fonte-5-pontos">
                    R$ {{item.vlrComercial | number:'1.2-2'}}
                  </div>
                </td>
                <!--<td class="campo sem-borda-superior-inferior">-->
                <td class="campo ">
                  <div class="conteudoPedido fonte-5-pontos">
                    R$ {{item.valorTotal | number:'1.2-2'}}
                  </div>
                </td>
                <td class="campo ">
                  <div class="conteudoPedido fonte-5-pontos">
                    R$ {{item.valorDesconto | number:'1.2-2'}}
                  </div>
                </td>
                <td class="campo ">
                  <div class="conteudoPedido fonte-5-pontos">
                    R$ {{item.valorBruto | number:'1.2-2'}}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="bloco rodape">
          <!--ng-if="page.isLastPage"-->
          <table id="dados_adicionais">
            <tr>
              <td class="campo">
                <div class="conteudoPedido negrito">DADOS ADICIONAIS</div>
              </td>
              <td class="campo">
                <div class="conteudoPedido negrito">END. ENTREGA</div>
              </td>
            </tr>
            <tr>
              <td class="campo dados-adicionais">
                <div class="conteudoPedido">
                  {{vendatemp.dadosAdicionais}}
                </div>
              </td>
              <td class="campo reservado-fisco">
                <div class="conteudoPedido" *ngIf="localEntrega != null">
                  {{localEntrega.logradouro}} {{localEntrega.numero}} <br />
                  {{localEntrega.bairro}}<br />
                  {{localEntrega?.municipio?.descricao}},
                  {{localEntrega?.municipio?.uf.siglaUF}}<br />
                  {{localEntrega.cep}} <br />
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
