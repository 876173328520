import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable()
export class NfeService {

  

  postVisualizaNfesPorFiltro(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/nfe/visualizatodas/', item, { observe: 'response' })
  }

  getEmpresa(): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/nfe/visualizatodasempresas', { observe: 'response' })
  }

  getListaServico(): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/servicolista/visualizatodas', { observe: 'response' })
  }


  getClientePorNome(nome: any): Observable<HttpResponse<any>> {
    const params = new HttpParams().set('id', nome);
    return this.http.get(
       environment.webApiSaguiControlUrl +'/api/nfe/visualizaclientepornome',
      { observe: 'response', params }
    );
  }

  getNfe(itemId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/nfe/visualiza/' + itemId, { observe: 'response' })
  }

  getNfeConfig(itemId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/nfeconfig/visualiza/' + itemId, { observe: 'response' })
  }

  getNfeConfigPorEmpresa(itemId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/nfeconfig/visualizaporempresa/' + itemId, { observe: 'response' })
  }

  postNfe(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/nfe/inclui/', item, { observe: 'response' })
  };

  putNfe(item: any, itemId: number): Observable<HttpResponse<any>> {
    return this.http.put(
      environment.webApiSaguiControlUrl + '/api/nfe/atualiza/' + itemId, item, { observe: 'response' })
  };

  postProcessaNfe(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/nfe/processa/', item, { observe: 'response' })
  };

  postExportarXml(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/nfe/exporta/', item, { observe: 'response' })
  };

  postCalculaImposto(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/nfeitem/calculaimposto/', item, { observe: 'response' })
  };

  postCancelar(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/nfe/cancela/', item, { observe: 'response' })
  };

  getNfeItem(itemId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/nfeitem/visualiza/' + itemId, { observe: 'response' })
  }

  postIncluiNfeItem(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/nfeitem/inclui', item, { observe: 'response' })
  };

  putAtualizaNfeItem(item: any, itemId: any): Observable<HttpResponse<any>> {
    return this.http.put(
      environment.webApiSaguiControlUrl + '/api/nfeitem/atualiza/' + itemId, item, { observe: 'response' })
  };

  deleteNfeItem(itemId: any): Observable<HttpResponse<any>> {
    return this.http.delete(
      environment.webApiSaguiControlUrl + '/api/nfeitem/exclui/' + itemId, { observe: 'response' })
  }

  getEstoqueByProduto(itemId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/nfeitem/visualizaestoqueporproduto/' + itemId, { observe: 'response' })
  }

  // getProdutoPorNome(parametros: any): Observable<HttpResponse<any>> {

  //   const params = {
  //     id: parametros.id,
  //   }

  //   return this.http.get(environment.webApiSaguiControlUrl + '/api/nfe/produtovisualizapornome', { observe: 'response', params })
  // }

  getProdutoPorNome(parametros: any): Observable<HttpResponse<any>> {

    const params = {
      id: parametros.id,
      tipoProduto: parametros.tipoProduto,
    }

    return this.http.get(environment.webApiSaguiControlUrl + '/api/nfe/autocompleteproduto', { observe: 'response', params })
  }

  getTransportadoras(): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/nfe/transportadoras', { observe: 'response' })
  }

  postInboundXml(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/nfeinbound/processaxml/', item, { observe: 'response' })
  }

  postVisualizaNfeInboundsPorFiltro(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/nfeinbound/visualizatodas/', item, { observe: 'response' })
  }

  postVisualizaComprasPorFiltro(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/compra/visualizatodas/', item, { observe: 'response' })
  }

  postVisualizaVendasPorFiltro(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/venda/visualizatodas/', item, { observe: 'response' })
  }

  postNfeVincularPedidoCompra(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/nfeinbound/vinculapedidocomrpa/', item, { observe: 'response' })
  }

  postNfeVincularPedidoVenda(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/nfeinbound/vinculapedidovenda/', item, { observe: 'response' })
  }

  postEnviarPorEmail(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/nfe/enviarporemail', item, { observe: 'response' })
  };

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

  }
}
