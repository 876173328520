<mat-accordion>
  <mat-expansion-panel  [expanded]="panelOpenState" (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title *ngIf="!panelOpenState">
        ➕ Clique para adicionar novas parcelas
      </mat-panel-title>
      <mat-panel-title *ngIf="panelOpenState">
        Adicionar novas parcelas 👇
       </mat-panel-title>
      <mat-panel-description>
       
      </mat-panel-description>
    </mat-expansion-panel-header>
    <form [formGroup]="formParcelas">
      <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="row">
              
              <mat-form-field class="col-md-6 col-xs-12 form-group">
                <mat-label>Tipo de Pagamento</mat-label>
                <mat-select appRequiredField formControlName="tipoPagamento" name="tipoPagamento" (selectionChange)="onSelectChange($event)">
                  <mat-option [value]="0">Dinheiro</mat-option>
                  <mat-option [value]="1">Débito</mat-option>
                  <mat-option [value]="2">Crédito</mat-option>
                  <mat-option [value]="3">Boleto</mat-option>
                  <mat-option [value]="11">PIX</mat-option>
                  <mat-option [value]="4">Transferência</mat-option>
                  <mat-option [value]="5">Cheque</mat-option>
                  <mat-option [value]="6">Vale Refeição</mat-option>
                  <mat-option [value]="7">Banco</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="col-md-3 col-sm-12 col-xs-12 form-group">
                <mat-label>Número de Parcelas</mat-label>
                <input appRequiredField type="number" matInput formControlName="numeroParcelas">
              </mat-form-field>
              <mat-form-field class="col-md-3 col-xs-12 form-group">
                <mat-label>Valor da Parcela</mat-label>
                <span matPrefix>R$ &nbsp;</span>
                <input appRequiredField type="number" matInput formControlName="valorLiquido" step="0.01">
              </mat-form-field>
            
            </div>
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12">
      
      <div class="row">
          
          
            <mat-form-field class="col-md-4 col-xs-12 form-group">
              <mat-label>Vencimento 1ª Parcela</mat-label>
              <input appRequiredField matInput formControlName="dataVencimentoParcela" [matDatepicker]="picker6" [min]="currentDate">
              <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
              <mat-datepicker #picker6></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="col-md-4 col-sm-12 col-xs-12 form-group">
              <mat-label>Intervalo entre Parcelas (em dias)</mat-label>
              <input appRequiredField type="number" matInput formControlName="intervaloParcelas">
            </mat-form-field>
         
   
            <div class="col-md-4">
      
              <button  mat-raised-button color="accent" (click)="verificaParcelas()" [disabled]="formParcelas.invalid" matTooltip="Adicionar Parcela">Criar Parcelas</button>
         
        </div>
        </div>
      </div>
      </div>
      
      </form>
  </mat-expansion-panel>
</mat-accordion>
<hr>
<h3>Quantidade de parcelas geradas: {{parcelas.data?.length>0?parcelas.data.length:0}}</h3>

<div class="row" *ngIf="parcelas.data?.length>0">

    <app-table fixed
    [dataSource]="parcelas"   
    [columns]="columns"
    [enablePagination]="false"
    [onExcluir]="onExcluir.bind(this)"
    [itemIdProperty] = "'parcelaId'"
    class="servico-table"> 
</app-table>
<div><b>Valor que precisa ser parcelado:</b> R$ {{ valorTotal}}   |   <b>Valor já parcelado:</b> R$ {{ getSomaValorTotalParcelasFinais() }} | <span [ngClass]="{'valor-faltante': (valorTotal - getSomaValorTotalParcelasFinais()) > 0}"><b> Faltante:</b> R$ {{valorTotal-getSomaValorTotalParcelasFinais()}}</span></div>

<hr>
<br>
</div>