
<mat-toolbar>
  <div class="md-toolbar-tools">
    <h1 mat-dialog-title>Itens com códigos de serviço diferentes</h1>
    <span flex></span>
    <div class="posicionar-direita">
      <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>

<div mat-dialog-content>
  <div mat-dialog-content>
    <mat-card class="info-card">
      <mat-card-content>
        <p>
          Existem itens com códigos de serviço diferentes. Eles serão agrupados e criadas
          <strong>{{ faturaItensCodigoServicoDto.length }}</strong> notas fiscais/contas a receber, uma para cada grupo de itens.
        </p>
      </mat-card-content>
    </mat-card>
    <br>
  <mat-table [dataSource]="faturaItensCodigoServicoDto" class="mat-elevation-z8 grouped-table">
    <!-- Coluna: Itens -->
    <ng-container matColumnDef="itens">
      <mat-header-cell *matHeaderCellDef>Serviço/Produto</mat-header-cell>
      <mat-cell *matCellDef="let item">
        <div *ngFor="let faturaItem of item.faturaItems" class="item-container">
          &nbsp;{{faturaItem.codProd}} - {{faturaItem.descricao}}
        </div>
      </mat-cell>
    </ng-container>

    <!-- Coluna: Código de Serviço -->
    <ng-container matColumnDef="codigoServico">
      <mat-header-cell *matHeaderCellDef> Código de Serviço da Nota</mat-header-cell>
      <mat-cell *matCellDef="let item"> {{ item.codigoServico }} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>

<div mat-dialog-actions>
  <div class="row">
    <div class="col-md-6">
      <button mat-raised-button color="warn" (click)="onNoClick()">Cancelar e ajustar os itens</button>
    </div>
    <div class="col-md-6 text-right">
      <button mat-raised-button color="primary" (click)="onYesClick()">Continuar</button>
  </div>
</div>
