import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable()
export class ServicoService {

    buscaServicos(filtros: any): Observable<HttpResponse<any>> {
        let params = new HttpParams();

        // Adiciona os filtros aos parâmetros da requisição
        Object.keys(filtros).forEach(key => {
          if (filtros[key] !== null && filtros[key] !== '') {
            params = params.append(key, filtros[key]);
          }
        });

        // Realiza a requisição GET com os parâmetros
        return this.http.get<any>(
          `${environment.webApiSaguiControlUrl}/api/servicos/buscaServicos`,
          { params, observe: 'response' }
        );
      }
    criaServico(item: any): Observable<HttpResponse<any>> {
        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/servicos/servico/', item, { observe: 'response' })
    };

    atualizaServico(item: any, itemId: any): Observable<HttpResponse<any>> {
        return this.http.put(
            environment.webApiSaguiControlUrl + '/api/servicos/atualiza/servico/' + itemId, item, { observe: 'response' })
    };

    recuperaServico(itemId: any): Observable<HttpResponse<any>> {
      return new Observable((observer) => {
          setTimeout(() => {
              this.http.get<any>(
                  `${environment.webApiSaguiControlUrl}/api/servicos/recuperaservico/${itemId}`,
                  { observe: 'response' }
              ).subscribe(
                  response => {
                      observer.next(response);
                      observer.complete();
                  },
                  error => observer.error(error)
              );
          }, 500);
      });
  }


    removeServico(itemId: any): Observable<HttpResponse<any>> {
        return this.http.delete(
            environment.webApiSaguiControlUrl + '/api/Servicos/removeServico/' + itemId, { observe: 'response' })
    }

   buscaGrupoServico(itemId: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/servicos/grupos/' + itemId, { observe: 'response' })
    }

    getEmpresas(): Observable<HttpResponse<any>> {
      return this.http.get(
        environment.webApiSaguiControlUrl + '/api/servicos/empresas', { observe: 'response' })
    }

    getgrupoContas(tpFatura: any): Observable<HttpResponse<any>> {
      return this.http.get(
        environment.webApiSaguiControlUrl + '/api/servicos/visualizatodosgruposconta/' + tpFatura, { observe: 'response' })
    }

  getServicoGrupos(filtros: any): Observable<HttpResponse<any>> {
    let params = new HttpParams();

    // Adiciona os filtros aos parâmetros da requisição
    Object.keys(filtros).forEach(key => {
      if (filtros[key] !== null && filtros[key] !== '') {
        params = params.append(key, filtros[key]);
      }
    });

    // Realiza a requisição GET com os parâmetros
    return this.http.get<any>(
      `${environment.webApiSaguiControlUrl}/api/servicos/grupos`,
      { params, observe: 'response' }
    );
  }


  getListaServicos(): Observable<HttpResponse<any>> {
    return this.http.get(
        environment.webApiSaguiControlUrl + '/api/servicolista/visualizatodas', { observe: 'response' })
  }

  getCaixas(): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/servicos/visualizatodoscaixas', { observe: 'response' })
  }

    buscaIntegracaoSoc(itemId: any): Observable<HttpResponse<any>>{
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/servico/verifica-integracoes/' +itemId, {observe: 'response'})
    }

    buscaGrupoServicos(): Observable<HttpResponse<any>>{
      return this.http.get(
        environment.webApiSaguiControlUrl + '/api/servicos/grupos-servico', {observe: 'response'})
    }

    recuperaGrupoServico(itemId: any): Observable<HttpResponse<any>> {
      return this.http.get(
          environment.webApiSaguiControlUrl + '/api/servicos/grupo-servico/' + itemId, { observe: 'response' })
    }

    criaGrupoServico(item: any): Observable<HttpResponse<any>> {
      return this.http.post(
          environment.webApiSaguiControlUrl + '/api/servicos/grupo-servico/', item, { observe: 'response' })
  };
    atualizaGrupoServico(item: any, itemId: any): Observable<HttpResponse<any>> {
      return this.http.put(
          environment.webApiSaguiControlUrl + '/api/servicos/grupo-servico/' + itemId, item, { observe: 'response' })
  };
  removeGrupo(itemId: any): Observable<HttpResponse<any>> {
    return this.http.delete(
        environment.webApiSaguiControlUrl + '/api/Servicos/grupo-servico/' + itemId, { observe: 'response' })
  }
    constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

    }
}
