<mat-toolbar>
  <div class="md-toolbar-tools">
    <h1 mat-dialog-title>{{tipoOperacao}} Parcela</h1>
    <span flex></span>
    <div class="posicionar-direita">
      <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
        <mat-icon>fullscreen_exit</mat-icon>
      </button>
      <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
        <mat-icon>fullscreen</mat-icon>
      </button>
      <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" matTooltip="Salvar" (click)="salvar()" *ngIf="permiteEdicao">
    <mat-icon>save</mat-icon>
  </button>
  <button mat-raised-button [matMenuTriggerFor]="opcoes" matTooltip="Opções">
    <mat-icon>more_horiz</mat-icon>
  </button>
  <mat-menu #opcoes>
    <button mat-menu-item *ngIf="contaParcelaForm.value.contaParcelaId > 0"
      (click)="abreModalConta(contaParcelaForm.value.contaId)">
      <mat-icon>edit</mat-icon> Conta
    </button>
    <button mat-menu-item *ngIf="contaParcelaForm.value.caixaMovimentoId > 0" (click)="caixaMovimentoModal()">
      <mat-icon>edit</mat-icon> Lançamento no Caixa
    </button>
    <button mat-menu-item *ngIf="contaParcelaForm.value.contaParcelaId > 0 &&
      contaParcelaForm.value.adiantamentoContaId == 0 && permiteEdicao" (click)="corrigirParcela()" >
      Corrigir Parcela
    </button>
    <button mat-menu-item *ngIf="contaParcelaForm.value.statusPagamento == 0" (click)="baixarParcela(contaParcelaForm.value.contaParcelaId)">
      <mat-icon>attach_money</mat-icon> Baixar
    </button>
    <button mat-menu-item *ngIf="contaParcelaForm.value.statusPagamento < 2" (click)="cancelarParcela(contaParcelaForm.value)"
      color="warn">
      <mat-icon>delete</mat-icon> Cancelar
    </button>
    <button mat-menu-item *ngIf="contaParcelaForm.value.contaParcelaId > 0" (click)="abreModalAnexo()"><span
        class="fa fa-paperclip"></span> Anexos</button>
    <button mat-menu-item *ngIf="contaParcelaForm.value.contaParcelaId > 0" (click)="abrirLogs()">Logs</button>
    <button mat-menu-item *ngIf="contaParcelaForm.value.contaCorrenteVendaId > 0"
      (click)="contaCorrenteVendaModal(contaParcelaForm.value.contaCorrenteVendaId)">
      <span class="fa fa-card"></span> Lançamento Cartão</button>
  </mat-menu>
</div>
<mat-dialog-content>
  <div class="md-dialog-content">
    <br />
    <div class="row">
      <form [formGroup]="contaParcelaForm">
        <div class="col-md-12">
          <mat-card>
            <mat-card-header>
              <mat-card-title>Informação da Parcela</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div class="row">
                <div class="col-md-6">
                  Status Pagamento: {{retornaStatusPagamento(contaParcelaForm.value.statusPagamento)}}
                </div>
                <!-- <mat-form-field class="col-md-3 col-xs-12 form-group">
                                  <mat-label>Status Pagamento</mat-label>
                                  <mat-select formControlName="statusPagamento" (blur)="definirComoPago()"
                                  (disabled)="contaParcelaForm.value.statusPagamento > 0">
                                    <mat-option [value]="0">Ativo</mat-option>
                                    <mat-option [value]="1">Quitado</mat-option>
                                    <mat-option [value]="2">Cancelado</mat-option>
                                  </mat-select>
                                </mat-form-field> -->
                <mat-form-field class="col-md-6 col-xs-12 form-group">
                  <mat-label>Descrição Parcela</mat-label>
                  <input type="text" matInput formControlName="descricao">
                </mat-form-field>
              </div>
              <!-- <div class="row">
                                <mat-form-field class="col-md-3 col-xs-12 form-group">
                                  <mat-label>Status Pagamento</mat-label>
                                  <mat-select formControlName="statusPagamento" (blur)="definirComoPago()"
                                  (disabled)="contaParcelaForm.value.statusPagamento > 0">
                                    <mat-option [value]="0">Ativo</mat-option>
                                    <mat-option [value]="1">Quitado</mat-option>
                                    <mat-option [value]="2">Cancelado</mat-option>
                                  </mat-select>
                                </mat-form-field>
                                <mat-form-field class="col-md-9 col-xs-12 form-group">
                                  <mat-label>Descrição Parcela</mat-label>
                                  <input type="text" matInput formControlName="descricao">
                                </mat-form-field>
                              </div> -->
              <div class="row">
                <mat-form-field class="col-md-4 col-xs-12 form-group">
                  <mat-label>Valor Parcela</mat-label>
                  <input currencyMask matInput formControlName="valorParcela">
                </mat-form-field>
                <mat-form-field class="col-md-4 col-xs-12 form-group">
                  <mat-label>Data de Vencimento</mat-label>
                  <input matInput [matDatepicker]="picker1" placeholder="Data de Vencimento"
                    formControlName="dataVencimento">
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="col-md-4 col-xs-12 form-group">
                  <mat-label>Data de Emissão</mat-label>
                  <input matInput [matDatepicker]="picker2" placeholder="Data de Emissão" formControlName="dataCriacao">
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="row">
                <mat-form-field class="col-md-12 col-xs-12 form-group">
                  <mat-label>Tipo de Documento Fiscal</mat-label>
                  <mat-select formControlName="documentoFiscal">
                    <mat-option [value]="1">Nfs-e</mat-option>
                    <mat-option [value]="2">Recibo</mat-option>
                    <mat-option [value]="3">Nf-e</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </mat-card-content>
          </mat-card>
          <mat-card *ngIf="contaParcelaForm.value.statusPagamento == 1">
            <mat-card-header>
              <mat-card-title>Informações do Pagamento</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div class="row">
                <mat-form-field class="col-md-6 col-xs-12 form-group">
                  <mat-label>Data do Pagamento</mat-label>
                  <input matInput [matDatepicker]="picker3" formControlName="dataPagamento">
                  <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                  <mat-datepicker #picker3></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="col-md-6 col-xs-12 form-group">
                  <mat-label>Valor do Pagamento</mat-label>
                  <input currencyMask matInput formControlName="valorPagamento">
                </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field class="col-md-4 col-xs-12 form-group">
                    <mat-label>Valor do Juros</mat-label>
                    <input currencyMask matInput placeholder="Valor Juros Pago" formControlName="valorJurosPago">
                  </mat-form-field>
                  <mat-form-field class="col-md-4 col-xs-12 form-group">
                    <mat-label>Valor da Multa</mat-label>
                    <input currencyMask matInput placeholder="Valor Juros Pago" formControlName="valorMultaPago">
                  </mat-form-field>
                  <mat-form-field class="col-md-4 col-xs-12 form-group">
                    <mat-label>Valor do Desconto</mat-label>
                    <input currencyMask matInput placeholder="Valor Desconto Pago" formControlName="valorDescontoPago">
                  </mat-form-field>
                </div>
                <div class="row">
                <mat-form-field class="col-md-4 col-xs-12 form-group">
                  <mat-label>Observações</mat-label>
                  <input type="text" matInput formControlName="observacaoPagamento">
                </mat-form-field>
                <mat-form-field class="col-md-4 col-xs-12 form-group">
                  <mat-label>Tipo De Pagamento</mat-label>
                  <mat-select formControlName="tipoPagamento">
                    <mat-option [value]="0">Dinheiro</mat-option>
                    <mat-option [value]="1">Débito</mat-option>
                    <mat-option [value]="2">Crédito</mat-option>
                    <mat-option [value]="3">Boleto</mat-option>
                    <mat-option [value]="11">PIX</mat-option>
                    <mat-option [value]="4">Transferência</mat-option>
                    <mat-option [value]="5">Cheque</mat-option>
                    <mat-option [value]="6">Vale Refeição</mat-option>
                    <mat-option [value]="7">Banco</mat-option>
                    <mat-option [value]="8">Prestação de Contas</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="col-md-6 col-xs-12 form-group" *ngIf="contaParcelaForm.value.tipoPagamento == 8">
                  <mat-label>Selecione a Operação (*)</mat-label>
                  <mat-select formControlName="operacaoContabilId">
                    <mat-option *ngFor="let operacaoContabil of operacoesContabeis"
                      [value]="operacaoContabil.operacaoContabilId">
                      {{operacaoContabil.descricao}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="col-md-2 col-xs-12 form-group" *ngIf="contaParcelaForm.value.tipoPagamento == 2">
                  <mat-label>Número de Parcelas</mat-label>
                  <input type="number" matInput placeholder="Número de Parcelas" formControlName="numeroParcelas">
                </mat-form-field>
                <mat-form-field class="col-md-3 col-xs-12 form-group" *ngIf="contaParcelaForm.value.tipoPagamento == 2">
                  <mat-label>Código de Autenticação</mat-label>
                  <input type="string" matInput placeholder="Código de Autenticacao"
                    formControlName="codigoAutenticacao">
                </mat-form-field>
              </div>
            </mat-card-content>
          </mat-card>
          <mat-card *ngIf="contaParcelaForm.value.boletoId > 0">
            <mat-card-header>
              <mat-card-title>Informações do Boleto</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div class="row">
                <label>Linha Digitável: {{boleto.linhaDigitavel}}</label>
              </div>
              <div class="row">
                <label>Nosso Número: {{boleto.nossoNumero}}</label>
              </div>
              <div class="row">
                <label>Nosso Documento: {{boleto.numeroDocumento}}</label>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </form>
    </div>
  </div>
</mat-dialog-content>
