<div class="row">
  <br>
  <p>Esse é o momento de relacionar esse documento com outros documentos relevantes do sistema, como orçamentos anteriores e pedidos de venda, para garantir que todas as informações estejam devidamente integradas e atualizadas.
  </p>
</div>

<hr>

<div class="row">
  <h3>Vincular Elemento:</h3>
  <form [formGroup]="documentoRelacionadoForm">
      <mat-form-field class="col-md-4 col-xs-12 form-group">
          <mat-label>Tipo Relação</mat-label>
          <mat-select formControlName="tipoDocumentoDestino">
              <mat-option *ngFor="let option of optionsDocumentosRelacionados" [value]="option.value">
                  {{ option.viewValue }}
              </mat-option>
          </mat-select>
      </mat-form-field>

      <mat-form-field class="col-md-7 col-xs-12 form-group" *ngIf="documentoRelacionadoForm.get('tipoDocumentoDestino').value">
          <mat-label>ID do item que será relacionado</mat-label>
          <input type="text" matInput [formControl]="documentoFormControl" [matAutocomplete]="doc">
          <mat-autocomplete #doc="matAutocomplete" [displayWith]="displayDocumentoFn" (optionSelected)="onDocumentoSelected($event.option.value)">
              <mat-option *ngFor="let objeto of filteredObjetos" [value]="objeto">
                  {{objeto.descricao}}
              </mat-option>
              <mat-option *ngIf="!(filteredObjetos?.length > 0)" class="nao-encontrado">
                  ❌ Nenhum item com o mesmo cliente ou empresa cadastrado foi encontrado.
              </mat-option>
          </mat-autocomplete>
      </mat-form-field>
      
      
  </form>
</div>

<div class="row" *ngIf="documentosRelacionadosDataTable.data.length > 0">
  <app-table fixed
      [dataSource]="documentosRelacionadosDataTable"
      [columns]="columnsDocumentosRelacionados"
      [enablePagination]="false"
      [onExcluir]="onExcluirDocumento.bind(this)"
      [itemIdProperty]="'idDocumentoDestino'"
      class="servico-table">
  </app-table>
</div>

<hr>
