import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';


export interface DialogData {
  contratoId: number;
}

@Component({
  selector: 'app-adiciona-relacao-contrato-dialog',
  templateUrl: './contrato-tipofaturamento-dialog.component.html'
})
export class ContratoTipoFaturamentoDialogComponent implements OnInit {
  maximizado: boolean;
  contratoTipoFaturamento: FormGroup;


  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ContratoTipoFaturamentoDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public IRelacao: DialogData) {

  }

  ngOnInit(): void {
    this.maximizado = false;
    this.contratoTipoFaturamento = this.fb.group({
      proximoPasso: new FormControl('', Validators.required)
    });

  }

  fecharDialog() {
    this.dialogRef.close('cancelar');
}

maximizarDialog() {
    this.dialogRef.updateSize('100%', '100%');
    this.maximizado = true;
}

restaurarDialog() {
    let innerWidth = window.innerWidth;

    if (innerWidth < 1024) {
        this.dialogRef.updateSize('90%', '100vh');
    }
    else {
        this.dialogRef.updateSize('60%', '100vh');
    }
    this.maximizado = false;
}
salvar(){
  this.dialogRef.close(this.contratoTipoFaturamento.get('proximoPasso').value);
}

}
