import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable()
export class UtilsService {

  removeCaracteresEspeciais(texto: string) {

    if (texto != undefined && texto != null && texto != '') {
      return texto.replace(/[^\w\s]/gi, '');
    }
    
  }

  removerEspacosComecoFim(texto: string) {

    if (texto != undefined && texto != null && texto != '') {
      return texto.trim();
    }
    
  }

  cpfValido(strCPF: string) {
    if (strCPF != undefined) {
      // Verificar se o CPF está no formato correto com pontos e traços
      const cpfPattern = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
      const cpfNumericPattern = /^\d{11}$/;
  
      if (!cpfPattern.test(strCPF) && !cpfNumericPattern.test(strCPF)) {
        return false;
      }
  
      // Remover caracteres especiais
      strCPF = strCPF.replace(/[^\d]/g, '');
  
      let Soma = 0;
      let Resto = 0;
  
      if (strCPF == "00000000000") return false;
  
      for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;
  
      if ((Resto == 10) || (Resto == 11)) Resto = 0;
      if (Resto != parseInt(strCPF.substring(9, 10))) return false;
  
      Soma = 0;
      for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
      Resto = (Soma * 10) % 11;
  
      if ((Resto == 10) || (Resto == 11)) Resto = 0;
      if (Resto != parseInt(strCPF.substring(10, 11))) return false;
      return true;
    }
  }

  formatarCNPJ(cnpj: string | number): string {
    // Converter para string se for número
    let cnpjStr = cnpj.toString();
  
    // Remover caracteres não numéricos
    cnpjStr = cnpjStr.replace(/[^\d]/g, '');
  
    // Verificar se o CNPJ tem 14 dígitos
    if (cnpjStr.length !== 14) {
      return cnpjStr;
    }
  
    // Formatar o CNPJ
    return cnpjStr.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
  }


 // Função para validar Email
 validarEmail(email: string): boolean {
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return re.test(String(email).toLowerCase()) && !email.includes(',');
}

  minArrayLengthValidator(minLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control && control.value && Array.isArray(control.value)) {
        return control.value.length >= minLength ? null : { minArrayLength: true };
      }
      return { minArrayLength: true };
    };
  }


    // Função de validação de Email
    emailValidator(): ValidatorFn {
      return (control: AbstractControl): ValidationErrors | null => {
        const valid = this.validarEmail(control.value);
        return valid ? null : { emailInvalid: true };
      };
    }
  

     // Função de validação de CPF
  cpfValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const valid = this.cpfValido(control.value);
      return valid ? null : { cpfInvalid: true };
    };
  }



  constructor() {

  }
}
