import { Component, Input, OnInit, AfterViewInit, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { forEach } from 'jszip';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ItemParcela, TipoPagamento } from '../shared/item-parcela.model';


@Component({
    selector: 'app-calcula-parcelas',
    templateUrl: 'calcula-parcelas-component.html',
    styleUrls: ['calcula-parcelas-component.css'],
    providers: [DatePipe]
  })
  export class CalculaParcelasComponent implements OnInit, AfterViewInit, OnChanges {
  
    //entradas
    @Input() valorTotal: number = 0; //valor total do contrato
    @Input() valorLiquido: number; //valor sugestivo para primeira parcela
    @Input() tipoPagamento: number = 3; // valor surgestivo tipo de pagamento
    @Input() numeroParcelas: number = 1;
    @Input() vencimento: Date;
    @Input() parcelasIniciais: ItemParcela[] = [];
    @Input() parcelaIdNome:string ='';
    @Input() panelOpenState: boolean = true;
    @Input() intervaloParcelas: number = 30;

    //saidas
    @Output() parcelasFinaisChange = new EventEmitter<any[]>();
    
    formParcelas!: FormGroup;
    valorCadaParcela: number = 0;
    currentDate = new Date();
    parcelas = new MatTableDataSource<any>([]);
    metodoPagamentoTexto: any = '';
    private _parcelasFinais: any[] = [];



    get parcelasFinais(): any[] {
      return this._parcelasFinais;
    }

 
    set parcelasFinais(value: any[]) {
      this._parcelasFinais = value;
      this.parcelasFinaisChange.emit(this._parcelasFinais);
    }

    columns = [
      { def: 'dataVencimentoParcela', header: 'Data de Vencimento' },
      { def: 'valorLiquido', header: 'Valor Parcela' },
      { def: 'tipoPagamento', header: 'Tipo Pagamento'},
      { def: 'acaoTable', header: 'Ações' }
  
    ];
  

    constructor(private formBuilder: FormBuilder,private datePipe: DatePipe, private currencyPipe: CurrencyPipe, private _snackBar: MatSnackBar,) {
    }

    ngOnInit(): void {
       
    

        this.createForm();
        const brTime = this.currentDate.toLocaleString("en-US", {timeZone: "America/Sao_Paulo"});
        this.currentDate = new Date(brTime);

        this.onChanges();


          }

          ngAfterViewInit(): void {

         
           
          }

          ngOnChanges(changes: SimpleChanges): void {
            if (changes['valorLiquido']) {
                this.formParcelas.get('valorLiquido')?.setValue(Number(this.valorLiquido.toFixed(2)), { emitEvent: false });
            }
            if (changes['vencimento']) {
                this.formParcelas.get('dataVencimentoParcela')?.setValue(this.vencimento, { emitEvent: false });
            }
            if (changes['numeroParcelas']) {
                this.formParcelas.get('numeroParcelas')?.setValue(this.numeroParcelas, { emitEvent: false });
            }
            if (changes['valorTotal']) {
                const numeroParcelas = this.formParcelas?.get('numeroParcelas').value;
                const valorLiquido = numeroParcelas === 0 ? 0 : this.valorTotal / numeroParcelas;
                this.formParcelas?.get('valorLiquido').setValue(Number(valorLiquido.toFixed(2)), { emitEvent: false });
            }
            if (changes['intervaloParcelas']) {
                this.formParcelas?.get('intervaloParcelas')?.setValue(this.intervaloParcelas, { emitEvent: false });
            }

            if (changes['tipoPagamento']) {
                this.formParcelas?.get('tipoPagamento')?.setValue(this.tipoPagamento, { emitEvent: false });
            }


            if (changes.parcelasIniciais) {

              if(this.parcelasIniciais?.length>0){

                this.panelOpenState = false;
  
  
              }else{
                this.panelOpenState = true;
              }
          
     
              this.parcelas.data = this.parcelasIniciais?.map((parcela: any) => {
                return {
                  parcelaId: parcela[this.parcelaIdNome],
                  dataVencimentoParcela: this.datePipe.transform(parcela.dataVencimento, 'dd/MM/yyyy'),
                  valorLiquido: this.currencyPipe.transform(parcela.valorLiquido, 'BRL', 'symbol'),
                  tipoPagamento: TipoPagamento[parcela.tipoPagamento]


              }});

              this.parcelasFinais = this.parcelasIniciais?.map((parcela: any) => {
                return {
                  parcelaId: parcela[this.parcelaIdNome],
                  dataVencimentoParcela: parcela.dataVencimento,
                  valorLiquido: Number(parcela.valorLiquido.toFixed(2)),
                  tipoPagamento: parcela.tipoPagamento


              }});

              this.preencherFormularioSugestoes();

            }

        }

          createForm() {
            let data = new Date();
            if(!this.vencimento){
               data.setDate(this.currentDate.getDate() + 5);
              
              }else{
                data = this.vencimento;
              }
            

            this.formParcelas = this.formBuilder.group({
              tipoPagamento: [3, Validators.required],
              numeroParcelas: [1, new Date()],
              dataVencimentoParcela: [data, Validators.required],
              valorLiquido: [this.valorLiquido, Validators.required],
              intervaloParcelas: [30, Validators.required]
            }); 

            this.metodoPagamentoTexto = "BOLETO";
          }

          ajustaDizimaPeriodica(somaTodasParcelasAposInsercao:any, quantidadeParcelasAposInsercao:any){
           
            const diferenca = this.valorTotal - somaTodasParcelasAposInsercao;
         
            if(diferenca<1 && diferenca>-1){

              //pode ser uma dizima periodica ou diferença de arredondamento
              return diferenca;

            }else{

              if(diferenca>=1){
              //o usuário apenas não finalizou a inserção de todas as parcelas
              return 0

              }else{
                //o usuário inseriu mais do que deveria
                return -99;	

              }

            }
        
           
          } 

          getSomaValorTotalParcelasFinais(){
            return Number((this.parcelasFinais?.reduce((sum, item) => Number(sum.toFixed(2)) + Number(item.valorLiquido.toFixed(2)), 0)).toFixed(2)) || 0;
          }

          
          verificaParcelas(){

          //somar o valor de todas as parcelasFinais
          const valorLiquidoParcelas = Number((this.parcelasFinais?.reduce((sum, item) => Number(sum.toFixed(2)) + Number(item.valorLiquido.toFixed(2)), 0)).toFixed(2));
          //quantidade de parcelas que tem em parcelasFinais
          const quantidadeParcelasAtuais = this.parcelasFinais.length;

          //multipla o valor this.formParcelas.get('numeroParcelas') pelo valor this.formParcelas.get('valorLiquido')
          const somaDasParcelasQueSeraoInseridas = Number((Number(this.formParcelas.get('numeroParcelas').value.toFixed(2)) * Number(this.formParcelas.get('valorLiquido').value.toFixed(2))).toFixed(2));
          //quantidade de parcelas que serão inseridas
          const quantidadeParcelasQueSeraInserida = this.formParcelas.get('numeroParcelas').value;

          const somaTodasParcelasAposInsercao = valorLiquidoParcelas + somaDasParcelasQueSeraoInseridas;
          //quantidade de parcelas apos a inserção
          const quantidadeParcelasAposInsercao = quantidadeParcelasAtuais + quantidadeParcelasQueSeraInserida;

          const diferenca = this.ajustaDizimaPeriodica(somaTodasParcelasAposInsercao, quantidadeParcelasAposInsercao);
          

          //se o valorLiquidoParcelas + valorTotalQueSeraInserido for menor do que o this.valorTotal, ele chama o gerarParcelas, senão ele retorna um erro de snackbar falando que a soma não pode ultrapassar o valor total do contrato
          if(diferenca!==-99){
            
            
          this.gerarParcelas(diferenca);
          
          }
            else{
              this._snackBar.open('😵‍💫 Erro! As parcelas não foram adicionadas! \n\n A soma dos valores das parcelas não pode ultrapassar o Valor Total Líquido que precisa ser faturado.\n\n Altere ou exclua as parcelas atuais para continuar.', '✖', {
                duration: 10000, panelClass: ['mat-toolbar', 'warn']
              });
            }
          }


          gerarParcelas(diferenca: any){ 
            const parcelasTemp = this.parcelas.data.slice(); // Usar slice para criar uma cópia
            const numeroParcelasNovas = this.formParcelas.get('numeroParcelas')?.value || 1;
            const dataVencimento = this.formParcelas.get('dataVencimentoParcela')?.value;
            const intervaloParcela = this.formParcelas.get('intervaloParcelas')?.value || 30; // Obter o intervalo em dias
            const tipoPagamento = this.formParcelas.get('tipoPagamento')?.value >= 0 ? this.formParcelas.get('tipoPagamento')?.value : 3;

         
            const valorParcela = Number((this.formParcelas.get('valorLiquido').value).toFixed(2));
  
         
            for (let i = 0; i < numeroParcelasNovas; i++) {
              const dataVencimentoParcela = new Date(dataVencimento);
              dataVencimentoParcela.setDate(dataVencimentoParcela.getDate() + (i * intervaloParcela)); // Adicionar o intervalo em dias

              const valorParcelaAjustada = i === numeroParcelasNovas - 1 ? valorParcela + diferenca : valorParcela;
         
              parcelasTemp.push({
                parcelaId: parcelasTemp.length - 1,
                dataVencimentoParcela: this.datePipe.transform(dataVencimentoParcela, 'dd/MM/yyyy'),
                valorLiquido: this.currencyPipe.transform(valorParcelaAjustada, 'BRL', 'symbol'),
                tipoPagamento: TipoPagamento[tipoPagamento]
              });
         
              this.parcelasFinais.push({
                parcelaId: this.parcelasFinais.length - 1,
                dataVencimentoParcela: dataVencimentoParcela,
                valorLiquido: Number(valorParcelaAjustada.toFixed(2)),
                tipoPagamento: this.formParcelas.get('tipoPagamento')?.value
              });
            }
         
            this.parcelas.data = parcelasTemp;
            this.parcelasFinaisChange.emit(this.parcelasFinais);
            this.panelOpenState = false;
         
            this.formParcelas.reset();
            this.preencherFormularioSugestoes();
         
          }
          
          onExcluir(idToRemove: any): void {
            // Filtra as parcelas finais para remover aquelas com parcelaId igual a idToRemove
            this.parcelasFinais = this.parcelasFinais.filter(parcela => parcela.parcelaId !== idToRemove);
          
            // Filtra as parcelas temporárias para remover aquelas com parcelaId igual a idToRemove
            const parcelasTemp = this.parcelas.data.filter(parcela => parcela.parcelaId !== idToRemove);
          
            // Atualiza o data source com as parcelas filtradas
            this.parcelas.data = parcelasTemp;

            this.preencherFormularioSugestoes();
          }

          onChanges(): void {
            this.formParcelas.get('numeroParcelas').valueChanges.subscribe(val => {
               const numeroParcelas = this.formParcelas.get('numeroParcelas').value;
               const valorRestante = this.getValorRestante() >0 ? this.getValorRestante() : 0;

               const valorLiquido= numeroParcelas===0? 0 : Number((valorRestante/numeroParcelas).toFixed(2));
              this.formParcelas.get('valorLiquido').setValue(valorLiquido, { emitEvent: false });
             
            });
          }

          preencherFormularioSugestoes(){
            this.formParcelas?.get('numeroParcelas').setValue(1);
            const intervalo = this.intervaloParcelas ? this.intervaloParcelas : 30;
            this.formParcelas.get('intervaloParcelas').setValue(intervalo);

            const tipoPagamento = this.tipoPagamento>=0 ? this.tipoPagamento : 3;
            this.formParcelas.get('tipoPagamento').setValue(tipoPagamento);

            const dataAtual = new Date();
            const dataComUmMesAMenos = new Date(dataAtual.setMonth(dataAtual.getMonth() - 1));

            let ultimaDataInserida = this.parcelasFinais[this.parcelasFinais.length - 1]?.dataVencimentoParcela || dataComUmMesAMenos;
            
            //SOMAR O INTERVALO NA ÚLTIMA DATA INSERIDA
            ultimaDataInserida = new Date(ultimaDataInserida);
            ultimaDataInserida.setDate(ultimaDataInserida.getDate() + intervalo);
            

            this.formParcelas.get('dataVencimentoParcela').setValue(ultimaDataInserida);

            //subtrair o this.valorLiquido pela soma da propriedade valorLiquido de todas as parcelasFinais
            let valorLiquido = this.getValorRestante() >0 ? this.getValorRestante() : 0;

            //arredondar valorLiquido para 2 casas decimais, usando Math.round
            valorLiquido = Number(valorLiquido.toFixed(2));


            this.formParcelas.get('valorLiquido').setValue(valorLiquido);
           
          
          }

          //obter valor restante para completar o valor total do contrato
          getValorRestante(): number {
            return Number(this.valorTotal.toFixed(2)) - Number((this.parcelasFinais.reduce((sum, item) => Number(sum.toFixed(2)) + Number(item.valorLiquido.toFixed(2)), 0)).toFixed(2));  
          }


          onSelectChange(event: MatSelectChange) {
            const selectedOption = event.source.selected as any;
            this.metodoPagamentoTexto = selectedOption ? selectedOption.viewValue : null;
          }


          
        
    }
