import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OrdemServico } from '../models/ordem-servico';

@Injectable({
  providedIn: 'root'
})
export class OrdemServicoService {
  private apiUrl: string = '/api/ordemservico';
  private baseUrl: string = environment.webApiSaguiControlUrl + this.apiUrl;

  constructor(private http: HttpClient) { }

  visualizaTodasNaoCompletasCanceladas(): Observable<HttpResponse<any>> {
     return this.http.get<any>(`${this.baseUrl}`, { observe: 'response' })
  }

  postByVendaId(vendaId: number): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${this.baseUrl}/${vendaId}`, { observe: 'response' })
  }

  patchOrdemServico(ordemServico: OrdemServico): Observable<HttpResponse<any>> {
    return this.http.patch<any>(`${this.baseUrl}`, ordemServico, { observe: 'response' })
  }

  checkIfOrdemServicoExistsByVendaId(vendaId: number): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${this.baseUrl}/venda/${vendaId}`, { observe: 'response' })
  }

  getOrdemServicoById(ordemServicoId: number): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${this.baseUrl}/${ordemServicoId}`, { observe: 'response' })
  }

  buscarOrdemServicoByFiltro(filtro: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${this.baseUrl}/filtro`, filtro, { observe: 'response' })
  }

}
