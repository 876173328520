import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable()
export class grupoContaService {

  getgrupoContas(itemId: any): Observable<HttpResponse<any>> {

    const params = {
      tpConta: itemId,
    };

    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/grupoConta/visualizatodas/', { observe: 'response', params })
  }

  getPlanoConta(tpConta: any, empresas: any[]): Observable<HttpResponse<any>> {

    const params = {
      tipoConta: tpConta,
      empresaIds: empresas
    };


    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/grupoConta/planoconta/', { observe: 'response', params })
  }

  postContasContabeisPorFiltro(item: any): Observable<HttpResponse<any>> {

    let filtro: any = {};
        filtro.id = item.descricao;
        filtro.empresaIds = item.empresaIds;
        filtro.tpConta = item.tpConta;
    
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/grupoConta/visualizacontacontabilpornomeeempresas/', filtro, { observe: 'response' })
  }

  getgrupoContasPagarReceber(): Observable<HttpResponse<any>> {

    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/grupoConta/visualizatodassemtipo/', { observe: 'response' })
  }

  getgrupoConta(itemId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/grupoConta/visualiza/' + itemId, { observe: 'response' })
  }

  getgrupoContaPai(itemId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/grupoConta/visualizapai/' + itemId, { observe: 'response' })
  }

  getgrupoContasPorFiltro(params): Observable<HttpResponse<any>> {

    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/grupoConta/filtro', { observe: 'response', params })
  }

  postVisualizaGrupoConta(item: any, skipLoader = 'false'): Observable<HttpResponse<any>> {

    const headers = new HttpHeaders().set('X-Skip-Loader', skipLoader); 
    
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/grupoConta/visualizaporfiltro/', item, {headers, observe: 'response' } )
  }

  GrupoContas(tipoConta: any, grupoContaPaiId: number): Observable<HttpResponse<any>> {

    const params = {
      tipoConta: tipoConta,
      grupoContaPaiId: grupoContaPaiId
    }
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/grupoConta/getgrupocontas', { observe: 'response', params })
  }

  postgrupoConta(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/grupoConta/inclui/', item, { observe: 'response' })
  };

  putgrupoConta(item: any, itemId: any): Observable<HttpResponse<any>> {
    return this.http.put(
      environment.webApiSaguiControlUrl + '/api/grupoConta/atualiza/' + itemId, item, { observe: 'response' })
  };

  deletegrupoConta(itemId: any): Observable<HttpResponse<any>> {
    return this.http.delete(
      environment.webApiSaguiControlUrl + '/api/grupoConta/exclui/' + itemId, { observe: 'response' })
  }

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

  }
}
