import { ClienteImpostosEmpresas } from './../shared/cliente.model';
import { ClienteContadorService } from '../shared/cliente-contador.service';
'use strict';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { debounceTime, map, switchMap } from "rxjs/operators";

import { ClienteService } from '../shared/cliente.service';
import { UfService } from '../../uf/shared/uf.service';
import { PaisService } from '../../pais/shared/pais.service';
import { MunicipioService } from '../../municipio/shared/municipio.service';
import { CepService } from '../../cep/shared/cep.service';

import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';
import { ConfirmDialogComponent } from '../../core/dialogs/confirm-dialog/confirm-dialog.component';

import { ClienteHistoricoComponent } from '../cliente-historico/cliente-historico.component';

import { Cliente, ClienteImpostoAgrupado, TipoImposto, TipoImpostoBackendToFrontend, TipoImpostoFrontendToBackend, TipoNota } from '../shared/cliente.model';
import { ContaContabilService } from '../../contabil/shared/conta-contabil.service';
import { ClienteServicoComponent } from '../cliente-servico/cliente-servico.component';
import { ClienteFaturamentoComponent } from '../cliente-faturamento/cliente-faturamento.component';
import { ParceiroService } from 'src/app/parceiro/shared/parceiro.service';
import { ClienteServicoService } from '../shared/cliente-servico.service';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { ClienteFaturamentoService } from '../shared/cliente-faturamento.service';
import { ContatoCadastroComponent } from 'src/app/contato/contato-cadastro/contato-cadastro.component';
import { ContatoService } from 'src/app/contato/shared/contato.service';
import { DadoBancarioService } from 'src/app/dado-bancario/shared/dado-bancario.service';
import { DadoBancarioCadastroComponent } from 'src/app/dado-bancario/dado-bancario-cadastro/dado-bancario-cadastro.component';
import { ClienteContador } from '../shared/cliente-contador';
import { IntegracaoService } from 'src/app/configuracao/shared/integracao.service';
import { Observable, of } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';

export interface DialogData {
  clienteId: number;
  clienteTipo: number;
  clienteNome: string;
  clienteEmpresas: [];
}

@Component({
  selector: 'cliente-cadastro',
  templateUrl: 'cliente-cadastro.component.html',
})

export class ClienteCadastroComponent {

  cliente: any = {};
  clientes: any = [];
  clienteCadastroForm: FormGroup;
  maximizado: boolean = false;
  pessoaFisica: boolean = false;
  isento: boolean = false;
  localEntregaAtivo: boolean = false;
  ufs: any = [];
  paises: any = [];
  municipios: any = [];
  localEntregaMunicipios: any = [];
  contasContabeis: any = [];
  usuarios: any = [];
  cepPesquisado: any = {};
  cepEntregaPesquisado: any = {};
  clienteHistoricos: any = [];
  clienteServicos: any = [];
  clienteFaturamentos: any = [];
  contatos: any = [];
  dadosBancarios: any = [];
  textoclienteTipo: string = '';
  clienteContadorForm = this._formBuilder.group({
    clienteContadorId: [0],
    razaoSocial: [''],
    funcionarioId: [0],
    nomeFantasia: [''],
    cnpj: [''],
    telefone: [''],
    municipioId: [0],
    ufId: [0],
    paisId: [0],
    clienteId: [0],
  });
  empresas: any = [];
  filteredEmpresas: any[] = [];
  integracao: boolean = false;
  isChecked: boolean = false;
  displayedColumns: string[] = ['retido', 'tipoImposto', 'empresas'];
  dataSourceNfse = new MatTableDataSource<ClienteImpostoAgrupado>();
  dataSourceNfe = new MatTableDataSource<ClienteImpostoAgrupado>();
  fiscalCheckbox = {
    nfse: false,
    nfe: false
  };




  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ClienteCadastroComponent>,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public ICliente: DialogData,
    private clienteService: ClienteService,
    private clienteServicoService: ClienteServicoService,
    private municipioService: MunicipioService,
    private ufService: UfService,
    private paisService: PaisService,
    private cepService: CepService,
    private contaContabilService: ContaContabilService,
    private clienteFaturamentoService: ClienteFaturamentoService,
    private parceiroService: ParceiroService,
    private utilsService: UtilsService,
    private contatoService: ContatoService,
    private dadoBancarioService: DadoBancarioService,
    private clienteContadorService: ClienteContadorService,
    private integracaoService: IntegracaoService
  ) {
  }



  ngOnInit() {


    this.clienteCadastroForm = this._formBuilder.group(new Cliente());
    if (this.ICliente.clienteId > 0) {

      this.buscarCliente(this.ICliente.clienteId);
    } else {
      if (this.ICliente.clienteTipo == 1) {
        this.textoclienteTipo = 'Lead';
        this.clienteCadastroForm.get('clienteTipo').setValue(1);
      } else {
        this.textoclienteTipo = 'Cliente';
        this.clienteCadastroForm.get('clienteTipo').setValue(0);
      }
    }

    this.clienteCadastroForm.addControl('contaContabilAutoComplete', new FormControl(''));
    this.clienteContadorForm.addControl('contadorCheckbox', new FormControl(''));

    if (this.ICliente.clienteNome != null && this.ICliente.clienteNome != undefined) {
      this.clienteCadastroForm.get('razaoSocial').setValue(this.ICliente.clienteNome);
    }

    this.updateCheckboxState();
    this.clienteContadorForm.get('clienteContadorId').valueChanges.subscribe(() => {
      this.updateCheckboxState();
    });

    this.clienteCadastroForm.get('clienteEmpresas').setValue(this.ICliente.clienteEmpresas);
    this.clienteCadastroForm.get('email').setValidators(this.utilsService.emailValidator());

    this.buscarPaises();

    this.buscarUfs();

    this.onChanges();

  }
  private updateCheckboxState(): void {
    const clienteContadorId = this.clienteContadorForm.get('clienteContadorId').value;
    this.clienteContadorForm.get('contadorCheckbox').setValue(clienteContadorId > 0, { emitEvent: false });
  }
  onChanges(): void {

    this.clienteCadastroForm
      .get('contaContabilAutoComplete')
      .valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => value?.length >= 3 ?
          this.contaContabilService.postVisualizaContaContabilPorFiltro(value) : []
        )
      )
      .subscribe(data =>
        this.contasContabeis = data.body
      );

    this.clienteCadastroForm
      .get('ufId')
      .valueChanges
      .subscribe(data => this.buscarMunicipioPorUf(data, false));

    this.clienteCadastroForm
      .get('ufEntregaId')
      .valueChanges
      .subscribe(data => this.buscarMunicipioPorUf(data, true));

    this.clienteCadastroForm
      .get('isento')
      .valueChanges
      .subscribe(data => {
        if (data === true) {
          this.clienteCadastroForm
            .get('inscEstadual').setValue('ISENTO');
        }
      });

    this.clienteCadastroForm
      .get('pessoaFisica')
      .valueChanges
      .subscribe(data => {
        if (data === true) {
          this.pessoaFisica = true;
        } else {
          this.pessoaFisica = false;
        }
      });


    this.buscaEmpresas();
    this.anyIntegracaoSocAtiva();
  }

  fecharDialog() {
    this.dialogRef.close(this.clienteCadastroForm.value);
  }
  private transformBackendData(data: any[]): ClienteImpostoAgrupado[] {
    return data.map(item => ({
      ...item,
      TipoImposto: TipoImpostoFrontendToBackend[item.TipoImposto]
    }));
  }
  salvarClienteContador() {
    const clienteContador: ClienteContador = this.clienteContadorForm.value;
    if (clienteContador.clienteContadorId > 0) {
      this.clienteContadorService.patchClienteContador(clienteContador).subscribe(data => {

        this.clienteContadorForm.patchValue(data.body);
        this.clienteCadastroForm.get('clienteContadorId').setValue(data.body.clienteContadorId);
        this._snackBar.open("Registro Salvo com Sucesso");

      });

    } else {
      this.clienteContadorService.postClienteContador(clienteContador).subscribe(data => {

        this.clienteContadorForm.patchValue(data.body);
        this.clienteCadastroForm.get('clienteContadorId').setValue(data.body.clienteContadorId);
        this._snackBar.open("Registro Salvo com Sucesso");

      });
    }


  }



  buscaEmpresas() {
    this.clienteService.getEmpresasAllDados().subscribe(data => {
      this.empresas = data.body;
      this.listaEmpresa();
    });
  }
  enumImpostoEmString(enumNumber: TipoImposto) {
    return TipoImpostoBackendToFrontend[enumNumber];
  }

  listaEmpresa() {
    if (this.clienteCadastroForm.value && this.clienteCadastroForm.value.clienteEmpresas) {
      const selectedEmpresas = this.empresas.filter(empresa =>
        this.clienteCadastroForm.value.clienteEmpresas.some(pe => pe.empresaId === empresa.empresaId)
      );
      this.clienteCadastroForm.get('clienteEmpresas').setValue(selectedEmpresas);
    }
  }


  getClienteImpostosEmpresas(tipoNota: TipoNota): Observable<ClienteImpostoAgrupado[]> {
    const data: ClienteImpostosEmpresas[] = this.clienteCadastroForm.value.clienteImpostosEmpresas


    return of(data).pipe(
      map(backendData => this.mergeWithAllImpostos(backendData, tipoNota))
    );
  }

  private mergeWithAllImpostos(backendData: ClienteImpostosEmpresas[], tipoNota: TipoNota): ClienteImpostoAgrupado[] {
    const allImpostosNfse = [
      TipoImposto.PIS,
      TipoImposto.COFINS,
      TipoImposto.CSLL,
      TipoImposto.ISSQN,
      TipoImposto.IRRF,
      TipoImposto.INSS
    ];

    const allImpostosNfe = [
      TipoImposto.ICMS,
      TipoImposto.ICMS_ST,
      TipoImposto.PIS,
      TipoImposto.PIS_ST,
      TipoImposto.COFINS,
      TipoImposto.COFINS_ST,
      TipoImposto.IPI
    ];

    const allImpostos = tipoNota === TipoNota.NFSE ? allImpostosNfse : allImpostosNfe;

    const allImpostosMapped = allImpostos.map(imposto => ({
      TipoImposto: imposto,
      Retido: false,
      Empresas: []
    }));

    const groupedData = this.groupByImposto(backendData.filter(item => item.TipoNota === tipoNota));

    return allImpostosMapped.map(imposto => {
      const match = groupedData.find(item => item.TipoImposto === imposto.TipoImposto);
      return match ? match : imposto;
    });
  }

  private groupByImposto(data: ClienteImpostosEmpresas[]): ClienteImpostoAgrupado[] {
    const grouped = data.reduce((acc, item) => {
      const key = `${item.TipoImposto}-${item.Retido}`;
      if (!acc[key]) {
        acc[key] = {
          TipoImposto: item.TipoImposto,
          Retido: item.Retido,
          Empresas: []
        };
      }
      acc[key].Empresas.push(item.EmpresaId);
      return acc;
    }, {});

    return Object.values(grouped);
  }

  maximizarDialog() {
    this.dialogRef.updateSize('100%', '100%');
    this.maximizado = true;
  }

  restaurarDialog() {
    let innerWidth = window.innerWidth;

    if (innerWidth < 1024) {
      this.dialogRef.updateSize('90%', '100vh');
    }
    else {
      this.dialogRef.updateSize('60%', '100vh');
    }
    this.maximizado = false;
  }

  buscarUfs() {

    this.ufService.getUFs().subscribe(data => {

      this.ufs = data.body;

    });
  }

  buscarPaises() {

    this.paisService.getPaises().subscribe(data => {

      this.paises = data.body;
    });
  }

  buscarMunicipioPorUf(ufId: number, localEntrega: boolean) {

    if (ufId > 0) {

      this.municipioService.getMunicipioPorUf(ufId).subscribe(data => {

        let municipiosTemp = data.body;

        if (this.cepPesquisado.ibge > 0) {

          let municipioSelecionado = municipiosTemp.find(municipioTemp => municipioTemp.codIBGE == this.cepPesquisado.ibge);
          this.clienteCadastroForm.get('municipioId').setValue(municipioSelecionado.municipioId);

        }

        if (this.cepEntregaPesquisado.ibge > 0) {

          let municipioSelecionado = municipiosTemp.find(municipioTemp => municipioTemp.codIBGE == this.cepEntregaPesquisado.ibge);
          this.clienteCadastroForm.get('municipioEntregaId').setValue(municipioSelecionado.municipioId);
        }

        if (!localEntrega) {
          this.municipios = data.body;
          this.clienteCadastroForm.get('localEntregaAtivo').setValue(false);
        }
        else {
          this.localEntregaMunicipios = data.body;
          this.clienteCadastroForm.get('localEntregaAtivo').setValue(true);
        }

      });

    }
  }

  buscarCliente(clienteId: number) {

    this.clienteService.getCliente(clienteId).subscribe(data => {
      this.InsereDadosCliente(data.body);
    });
    this.listaEmpresa();
  }

  InsereDadosCliente(data: any) {

    this.clienteCadastroForm.patchValue(data);
    if (this.clienteCadastroForm.get('clienteTipo').value == 1) {
      this.textoclienteTipo = 'Lead';
    } else {
      this.textoclienteTipo = 'Cliente';
    }

    if (data.inscEstadual == "ISENTO") {
      this.clienteCadastroForm.get('isento').setValue(true);
    }

    if (data.cnpj == "" || data.cnpj == undefined || data.cnpj == null) {
      this.clienteCadastroForm.get('pessoaFisica').setValue(true);
    } else {
      this.clienteCadastroForm.get('pessoaFisica').setValue(false);
    }

    this.clienteContadorService.getClienteContadorByClienteId(data.clienteId).subscribe(data => {
      this.clienteContadorForm.patchValue(data.body);
    });

    if (this.clienteContadorForm.get('clienteContadorId').value == 0) {
      this.clienteContadorForm.get('contadorCheckbox').setValue(false);
    }

    var clienteForm = this.clienteCadastroForm.value

    this.getClienteImpostosEmpresas(TipoNota.NFSE).subscribe(data => {
      const transformedData = this.transformBackendData(data);
      this.dataSourceNfse.data = transformedData;

      let groupedByTipoImpostoNfse = this.groupByTipoImposto(clienteForm.clienteImpostosEmpresas, 0);

      this.dataSourceNfse.data = this.dataSourceNfse.data.map(item => {
        const match = groupedByTipoImpostoNfse.find(groupedItem => groupedItem.TipoImposto === item.TipoImposto);
        return match ? match : item;
      });

    });

    this.getClienteImpostosEmpresas(TipoNota.NFE).subscribe(data => {

      const transformedData = this.transformBackendData(data);
      this.dataSourceNfe.data = transformedData;

      let groupedByTipoImpostoNfe = this.groupByTipoImposto(clienteForm.clienteImpostosEmpresas, 1);

      this.dataSourceNfe.data = this.dataSourceNfe.data.map(item => {
        const match = groupedByTipoImpostoNfe.find(groupedItem => groupedItem.TipoImposto === item.TipoImposto);
        return match ? match : item;
      });
    });

    this.buscaEmpresas();

  }

  groupByTipoImposto(data, tipoNota): ClienteImpostoAgrupado[] {

    const filteredArray = data.filter(item => item.tipoNota == tipoNota);

    if (tipoNota == 0 && filteredArray.length > 0) {
      this.fiscalCheckbox.nfse = true;
    } else if (tipoNota == 1 && filteredArray.length > 0) {
      this.fiscalCheckbox.nfe = true;
    }

    const grouped = filteredArray.reduce((acc, item) => {
      const key = `${item.tipoImposto}-${item.retido}`;
      if (!acc[key]) {
        acc[key] = {
          TipoImposto: item.tipoImposto,
          Retido: item.retido,
          Empresas: []
        };
      }
      acc[key].Empresas.push(item.empresaId);
      return acc;
    }, {});

    return Object.values(grouped);

  }


  salvar(): void {
    let clienteForm = this.clienteCadastroForm;
    let empresasIds = clienteForm.value?.clienteEmpresas?.map(empresa => empresa.empresaId);

    let nfsesComEmpresasSelecioandas = this.dataSourceNfse.data.filter(nfse => nfse.Empresas.length > 0);
    let nfeComEmpresasSelecioandas = this.dataSourceNfe.data.filter(nfe => nfe.Empresas.length > 0);
    let clienteEmpresImpostos: ClienteImpostosEmpresas[] = [];

    nfsesComEmpresasSelecioandas.forEach(nfse => {
      nfse.Empresas.forEach((empresaId: number) => {
        clienteEmpresImpostos.push({
          ClienteImpostosEmpresasId: 0,
          EmpresaId: empresaId,
          Retido: nfse.Retido,
          TipoImposto: nfse.TipoImposto,
          TipoNota: TipoNota.NFSE,
          ClienteId: clienteForm.value.clienteId,
          SaguiClienteId: clienteForm.value.saguiClienteId
        });
      });
    });

    nfeComEmpresasSelecioandas.forEach(nfe => {
      nfe.Empresas.forEach((empresaId: number) => {
        clienteEmpresImpostos.push({
          ClienteImpostosEmpresasId: 0,
          EmpresaId: empresaId,
          Retido: nfe.Retido,
          TipoImposto: nfe.TipoImposto,
          TipoNota: TipoNota.NFE,
          ClienteId: clienteForm.value.clienteId,
          SaguiClienteId: clienteForm.value.saguiClienteId
        });
      });
    });

    let clienteEmpresas = empresasIds?.map(empresaId => {
      return {
        clienteId: clienteForm.value?.clienteId,
        empresaId: empresaId
      }
    });

    clienteForm.get('clienteEmpresas').setValue(clienteEmpresas);


    if (!clienteForm.value.clienteEmpresas || clienteForm.value.clienteEmpresas.length === 0) {
      this._snackBar.open("Selecione ao menos uma empresa", "Fechar", {
        duration: 5000,
        panelClass: ['snackbar-error']
      });
      return;
    }
    clienteForm.value.clienteImpostosEmpresas = clienteEmpresImpostos;

    if (clienteForm.value.clienteId == 0) {
      this.clienteService.postCliente(clienteForm.value).subscribe(data => {
        this._snackBar.open("Registro Salvo com Sucesso", "Fechar", {
          duration: 5000
        });

        this.buscarCliente(data.body.clienteId);
        this.dialogRef.close(data.body);
      });
    } else {
      this.clienteService.putCliente(clienteForm.value, clienteForm.value.clienteId).subscribe(data => {
        this._snackBar.open("Registro Salvo com Sucesso", "Fechar", {
          duration: 5000
        });

        this.buscarCliente(clienteForm.value.clienteId);
      });
    }
    if(this.clienteContadorForm.value.contadorCheckbox){
      this.salvarClienteContador();
    }
  }


  buscarContasContabeis() {

    if (this.clienteCadastroForm.value.contaContabilId > 0) {

      this.contaContabilService.getContaContabil(this.clienteCadastroForm.value.contaContabilId).subscribe(data => {

        this.clienteCadastroForm.get('contaContabilAutoComplete').setValue(data.body.descricao);

      });
    }
  }

  buscarClienteHistoricos() {

    if (this.clienteCadastroForm.value.clienteId > 0) {

      this.clienteService.getClienteHistoricos(this.clienteCadastroForm.value.clienteId).subscribe(data => {

        this.clienteHistoricos = data.body;

      });
    }
  }

  buscarClienteServicos() {

    if (this.clienteCadastroForm.value.clienteId > 0) {

      this.clienteService.getClienteServicos(this.clienteCadastroForm.value.clienteId).subscribe(data => {

        this.clienteServicos = data.body;

      });
    }
  }

  buscarClienteFaturamentos() {

    if (this.clienteCadastroForm.value.clienteId > 0) {

      this.clienteService.getClienteFaturamentos(this.clienteCadastroForm.value.clienteId).subscribe(data => {

        this.clienteFaturamentos = data.body;

      });
    }
  }

  filtrarContasContabeis(searchText) {

    let filtro: any = {};

    filtro.descricao = searchText;

    this.contaContabilService.postVisualizaContaContabilPorFiltro(filtro).subscribe(data => {

      this.contasContabeis = data.body;

    });
  }

  determinarContaContabil(conta) {

    if (conta != undefined) {
      this.clienteCadastroForm.get('contaContabilId').setValue(conta.contaContabilId);
    }

  };

  consultarCep(valor, localEntrega: boolean) {

    if (valor != undefined) {

      this.cepService.getDadosEndereco(valor).subscribe(data => {

        this.clienteCadastroForm.get('paisId').setValue(30);

        if (!localEntrega) {

          this.clienteCadastroForm.get('logradouro').setValue(data.body.logradouro);
          this.clienteCadastroForm.get('bairro').setValue(data.body.bairro);
          let uf = this.ufs.find(ufTemp => ufTemp.siglaUF == data.body.uf);
          this.clienteCadastroForm.get('ufId').setValue(uf.ufId);
          this.cepPesquisado = data.body;

        } else {

          this.clienteCadastroForm.get('logradouroEntrega').setValue(data.body.logradouro);
          this.clienteCadastroForm.get('bairroEntrega').setValue(data.body.bairro);
          let uf = this.ufs.find(ufTemp => ufTemp.siglaUF == data.body.uf);
          this.clienteCadastroForm.get('ufEntregaId').setValue(uf.ufId);
          this.cepEntregaPesquisado = data.body;
        }

      }, (error: HttpErrorResponse) => {

        const dialogRef = this.dialog.open(InfoDialogComponent, {
          data: {
            titulo: "Erro",
            erro: "Erro ao consultar CEP, preencha o endereço manualmente"
          }
        });
      });
    }
  };

  ModalClienteHistorico(clienteHistoricoId) {

    const dialogRef = this.dialog.open(ClienteHistoricoComponent, {
      data: {
        clienteHistoricoId: clienteHistoricoId,
        clienteId: this.clienteCadastroForm.value.clienteId
      },
      maxHeight: '100vh'
    });

    dialogRef.afterClosed().subscribe(result => {

      this.buscarClienteHistoricos();

    });

  }

  ModalClienteServico(clienteServicoId) {

    const dialogRef = this.dialog.open(ClienteServicoComponent, {
      data: {
        clienteServicoId: clienteServicoId,
        clienteId: this.clienteCadastroForm.value.clienteId
      },
      maxHeight: '100vh'
    });

    dialogRef.afterClosed().subscribe(result => {

      this.buscarClienteServicos();

    });

  }

  ModalClienteFaturamento(clienteFaturamentoId) {

    const dialogRef = this.dialog.open(ClienteFaturamentoComponent, {
      data: {
        clienteFaturamentoId: clienteFaturamentoId,
        clienteId: this.clienteCadastroForm.value.clienteId
      },
      maxHeight: '100vh'
    });

    dialogRef.afterClosed().subscribe(result => {

      this.buscarClienteFaturamentos();

    });

  }

  confirmarExclusaoClienteFaturamento(clienteFaturamentoId: number) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titulo: "Exclusão",
        mensagem: "Tem certeza que deseja excluir esse agendamento?"
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {

        this.clienteFaturamentoService.delete(clienteFaturamentoId).subscribe(data => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Sucesso",
              mensagem: "Registro excluído com Sucesso"
            }
          });

          this.buscarClienteFaturamentos();

        }, (error: HttpErrorResponse) => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Erro",
              erro: error
            }
          });
        });
      }
    });
  };

  confirmarExclusaoClienteServico(clienteServicoId: number) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titulo: "Exclusão",
        mensagem: "Tem certeza que deseja cancelar este serviço?"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.clienteServicoService.deleteClienteServico(clienteServicoId).subscribe(data => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Sucesso",
              mensagem: "Registro excluído com Sucesso"
            }
          });

          this.buscarClienteServicos();

        }, (error: HttpErrorResponse) => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Erro",
              erro: error
            }
          });
        });
      }
    });
  };

  ExcluirUsuario(clienteUsuarioId) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titulo: "Exclusão",
        mensagem: "Tem certeza que deseja excluir este usuário?"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.clienteService.deleteClienteUsuario(clienteUsuarioId).subscribe(data => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Sucesso",
              mensagem: "Registro excluído com Sucesso"
            }
          });

        }, (error: HttpErrorResponse) => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Erro",
              erro: "Erro ao excluir este usuário. Tente novamente mais tarde ou entre em contato com suporte@saguitech.com.br"
            }
          });
        });
      }
    });
  };

  buscarParceiro() {

    this.tratarCampos();

    let parceiroPesquisa: any = {
      cnpj: this.clienteCadastroForm.get('cnpj').value,
      cpf: this.clienteCadastroForm.get('cpf').value,
      uf: this.clienteCadastroForm.get('ufSigla').value,
    }

    if ((parceiroPesquisa.cpf == undefined || parceiroPesquisa.cpf == null || parceiroPesquisa.cpf == '') &&
      (parceiroPesquisa.cnpj == undefined || parceiroPesquisa.cnpj == null || parceiroPesquisa.cnpj == '')) {
      return;
    };

    this.clienteService.consultaParceiro(parceiroPesquisa).subscribe(data => {
      if (data.body != null && data.body.cnpj != null) {
        this.clienteCadastroForm.get('ufId').setValue(data.body.ufId);
        this.clienteCadastroForm.get('municipioId').setValue(data.body.municipioId);
        this.clienteCadastroForm.get('razaoSocial').setValue(data.body.razaoSocial);
        this.clienteCadastroForm.get('nomeFantasia').setValue(data.body.nomeFantasia);
        this.clienteCadastroForm.get('logradouro').setValue(data.body.logradouro);
        this.clienteCadastroForm.get('numero').setValue(data.body.numero);
        this.clienteCadastroForm.get('cep').setValue(data.body.cep);
        this.clienteCadastroForm.get('telefone').setValue(data.body.telefone);
        this.clienteCadastroForm.get('bairro').setValue(data.body.bairro);
        this.clienteCadastroForm.get('complemento').setValue(data.body.complemento);
        this.clienteCadastroForm.get('paisId').setValue(30);
        this.clienteCadastroForm.get('inscEstadual').setValue(data.body.inscricaoEstadual);
        this.clienteCadastroForm.get('codRegimeTributario').setValue(data.body.codRegimeTributario);
      }

    });


  }

  buscarParceiroContador() {
    this.tratarCampos();
    let parceiroPesquisa: any = {
      cnpj: this.clienteContadorForm.get('cnpj').value,
      cpf: null,
      uf: this.clienteContadorForm.get('ufId').value,
    }

    if ((parceiroPesquisa.cpf == undefined || parceiroPesquisa.cpf == null || parceiroPesquisa.cpf == '') &&
      (parceiroPesquisa.cnpj == undefined || parceiroPesquisa.cnpj == null || parceiroPesquisa.cnpj == '')) {
      return;
    };

    this.clienteService.consultaParceiro(parceiroPesquisa).subscribe(data => {
      if (data.body != null) {
        this.clienteContadorForm.patchValue(data.body);
        this.clienteContadorForm.value.municipioId = data.body.municipioId;

      }
    });

  }





  definieUf(uf: any) {

    this.clienteCadastroForm.get('ufId').setValue(uf.ufId);

  }

  tratarCampos() {

    let cnpj = this.utilsService.removeCaracteresEspeciais(this.clienteCadastroForm.get('cnpj').value);
    let cnpjContador = this.utilsService.removeCaracteresEspeciais(this.clienteContadorForm.get('cnpj').value);

    cnpj = this.utilsService.removerEspacosComecoFim(cnpj);
    cnpjContador = this.utilsService.removerEspacosComecoFim(cnpjContador);
    this.clienteCadastroForm.get('cnpj').setValue(cnpj);
    this.clienteContadorForm.get('cnpj').setValue(cnpjContador);

    let cpf = this.utilsService.removeCaracteresEspeciais(this.clienteCadastroForm.get('cpf').value);
    cpf = this.utilsService.removerEspacosComecoFim(cpf);
    this.clienteCadastroForm.get('cpf').setValue(cpf);

  }

  modalContato(id: number) {

    const dialogRef = this.dialog.open(ContatoCadastroComponent, {
      data: {
        contatoId: id,
        idOrigem: this.clienteCadastroForm.value.clienteId,
        contatoTipo: 1,
        saguiClienteId: this.clienteCadastroForm.value.saguiClienteId
      },
      maxHeight: '100vh',
      maxWidth: '100vw'
    });

    dialogRef.afterClosed().subscribe(result => {

      this.buscarContatos();

    });
  }

  buscarContatos() {

    this.clienteService.getContatos(this.clienteCadastroForm.value.clienteId).subscribe(data => {

      this.contatos = data.body;

    });
  }

  confirmarExclusaoContato(contatoId) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titulo: "Confirmação",
        mensagem: "Deseja realmente excluir esse contato?"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.contatoService.deleteContato(contatoId).subscribe(data => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Sucesso",
              mensagem: "Registro excluído com Sucesso"
            }
          });

          this.buscarContatos();

        }, (error: HttpErrorResponse) => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Erro",
              erro: error
            }
          });
        });
      }
    });
  }

  modalDadoBancario(id: number) {

    const dialogRef = this.dialog.open(DadoBancarioCadastroComponent, {
      data: {
        dadoBancarioId: id,
        idOrigem: this.clienteCadastroForm.value.clienteId,
        dadoBancarioTipoOrigem: 1,
        saguiClienteId: this.clienteCadastroForm.value.saguiClienteId
      },
      maxHeight: '100vh',
      maxWidth: '100vw'
    });

    dialogRef.afterClosed().subscribe(result => {

      this.buscarDadosBancarios();

    });
  }

  buscarDadosBancarios() {

    this.clienteService.getDadosBancarios(this.clienteCadastroForm.value.clienteId).subscribe(data => {

      this.dadosBancarios = data.body;

    });

  }

  confirmarExclusaoDadoBancario(dadoBancarioId) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titulo: "Confirmação",
        mensagem: "Deseja realmente excluir esse registro?"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.dadoBancarioService.deleteDadoBancario(dadoBancarioId).subscribe(data => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Sucesso",
              mensagem: "Registro excluído com Sucesso"
            }
          });

          this.buscarDadosBancarios();

        }, (error: HttpErrorResponse) => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Erro",
              erro: error
            }
          });
        });
      }
    });
  }

  matTabMudouAba($event: any) {

    switch ($event.index) {

      case 1:
        //Faturamento
        this.buscarContasContabeis();
        this.buscarClienteFaturamentos();
        break;
      case 2:
        //Contato
        this.buscarContatos();
        break;
      case 3:
        //Dados Bancários
        this.buscarDadosBancarios();
        break;
      case 4:
        //Histórico
        this.buscarClienteHistoricos();
        break;
      case 5:
        //Assinaturas
        this.buscarClienteServicos();
    }
  }


  anyIntegracaoSocAtiva() {

    this.clienteService.verficaIntegracaoSeHaIntegrcaoSoc().subscribe(data => {
      this.integracao = data.body ? true : false;

    });
  }

}
