import { FaturaItem } from "./fatura-item.model";
import { FaturaParcela } from "./fatura-parcela.model";

export class Fatura {

    faturaId: number = 0;

    faturaTipo: number = 0;

    faturaStatus: number = 0;

    faturaTipoDocumento: number = 0;

    descricao: string = '';

    dataCriacao: Date = new Date();

    valorTotal: number = 0;

    valorDesconto: number = 0;

    valorBruto: number = 0;

    valorLiquido: number = 0;

    dataPrimeiroPagamento: Date = new Date();

    dataUltimoPagamento: Date = new Date();

    diaVencimento: number = 1;

    numeroParcelas: number = 1;

    valorMulta: number = 0;

    tipoMulta: number = 0;

    valorJuros: number = 0;

    tipoJuros: number = 0;

    parceiroId: number = 0; //Se tipoConta for 0 então é cliente, se tpConta for 1 então é fornecedor

    tipoPagamento: number = 0;

    contaId: number = 0;

    contratoId: number = 0;

    grupoContaId: number = 0;

    caixaId: number = 0;

    empresaId: number = null;

    nfeId: number = 0;

    valorSemRetencoes: number = 0;

    valorCofins: number = 0;

    valorCsll: number = 0;

    valorInss: number = 0;

    valorIr: number = 0;

    valorIss: number = 0;

    valorPis: number = 0;

    saguiClienteId: number = 0;

    centroCustoId: number = 0;

    nfseId: number = 0;
    isSocCancela: boolean = false;

    faturaItens: FaturaItem[] = [];

    faturaParcelas: FaturaParcela[] = [];

    valorTotalBruto: number = this.valorBruto;

    descontosAplicadosAosItens: number = 0;

    somaDeTodosOsDescontos: number = this.valorDesconto;

    descontosAplicadosGlobalmente: number = 0;
    documentoFiscal: number = 1;

}
