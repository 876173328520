import { Injectable } from '@angular/core';

@Injectable()
export class HtmlPrinterService {

  printDiv(divName: string, titulo: string, orientacaoPaisagem = false): void {
    let printContents = document.getElementById(divName).innerHTML;
    this.printHtmlString(printContents, titulo, orientacaoPaisagem);
  }

  imprimirConteudoTinyMCE(templateContent: string) {
      // Abre uma nova janela para impressão
      const janelaImpressao = window.open('', '', 'height=600,width=800');
    
      janelaImpressao!.document.write(templateContent); // Adiciona o conteúdo do TinyMCE
 
    
      // Aguarda o carregamento do conteúdo antes de imprimir
      janelaImpressao!.document.close();
      
      // Função para verificar quando todas as imagens foram carregadas
      const imagens = janelaImpressao!.document.images;
      let imagensCarregadas = 0;
    
      if (imagens.length === 0) {
        // Se não houver imagens, podemos chamar o print diretamente
        chamarPrint();
      } else {
        // Para cada imagem, adicionar um listener de carregamento
        for (let i = 0; i < imagens.length; i++) {
          imagens[i].onload = () => {
            imagensCarregadas++;
            // Quando todas as imagens tiverem sido carregadas, chamar o print
            if (imagensCarregadas === imagens.length) {
              chamarPrint();
            }
          };
    
          // Se houver erro no carregamento de alguma imagem, ainda chamamos o print
          imagens[i].onerror = () => {
            imagensCarregadas++;
            if (imagensCarregadas === imagens.length) {
              chamarPrint();
            }
          };
        }
      }
    
      // Função que chama a impressão
      function chamarPrint() {
        janelaImpressao!.focus();
        janelaImpressao!.print();
        janelaImpressao!.onafterprint = () => janelaImpressao!.close();
      }
    }
    



  printHtmlString(printContents: string, titulo: string, orientacaoPaisagem = false) {
    var popupWin = window.open('', '_blank', 'width=' + screen.width + ',height=' + screen.height);

    let texto = '<html><head><meta charset="utf-8"><meta http-equiv="X-UA-Compatible" content="IE=edge"><meta name="viewport" content="width=device-width, initial-scale=1"><title>' + titulo + '</title>';
    texto += '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">';
    texto += '<link rel="stylesheet" href="assets/css/bootstrap.min.css" />';
    texto += '<link rel="stylesheet" href="assets/css/angular-material.css" />';
    texto += '<link rel="stylesheet" href="assets/css/custom.min.css"  />';
    texto += '<link rel="stylesheet" href="assets/css/danfe.css" />';
    //texto += '<link rel="stylesheet" href="assets/css/pedido.css" />';
    //texto += '<link rel="stylesheet" href="assets/css/barcode.css" />';
    texto += '<link rel="stylesheet" href="assets/css/boleto.css" />';
    texto += '<link rel="stylesheet" href="assets/css/bootstrap.min.css" />';
    texto += '<link rel="stylesheet" href="assets/css/pedido.css" media="all" />';
    texto += '<link rel="stylesheet" href="assets/css/barcode.css" />';
    texto += '<script src="assets/scripts/angular.min.js"></script>';
    texto += '<script src="assets/scripts/angular-resource.min.js"></script>';
    texto += '<script src="assets/scripts/angular-local-storage.min.js"></script>';
    texto += '<script src="assets/scripts/angular-animate.min.js"></script>';
    texto += '<script src="assets/scripts/jquery-2.2.1.min.js"></script>';
    texto += '<script src="assets/scripts/bootstrap.min.js"></script>';
    texto += '<script src="assets/scripts/barcodeGenerator.js"></script>';

    if (titulo == 'Diário') {
      texto += '<link rel="stylesheet" href="assets/css/diario.css" />';
    }

    if (orientacaoPaisagem) {

      texto += '<style>@page { size: A4 landscape;margin: 0; }</style>';

      texto += '<style>body { margin: 0; }</style>';

    } else {

      texto += '<style>@page { size: A4 portrait;margin: 0; }</style>';

      texto += '<style>body { margin: 0; }</style>';

    }




    texto += '</head><body onload="window.print()" style="padding: 12px;">' + printContents + '</body></html>';

    popupWin.document.open();
    popupWin.document.write(texto);
    popupWin.document.close();
  }

  constructor() {

  }
}
