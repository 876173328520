import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable()
export class FaturaService {

  postVisualizaFaturasPorFiltro(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/fatura/visualizatodas/', item, { observe: 'response' })
  }

  postGerarNfe(faturaId: number): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(
      environment.webApiSaguiControlUrl + '/api/fatura/gerarnfe/' + faturaId, null, { observe: 'response' })
  }

  getIntegracoesSOC(): Observable<HttpResponse<any>>{
    return this.http.get(
        environment.webApiSaguiControlUrl + '/api/fatura/verifica-integracoes-soc/', {observe: 'response'})
}


  getEmpresas(tpFatura: any): Observable<HttpResponse<any>> {


    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/fatura/visualizatodasempresas/' + tpFatura, { observe: 'response' })
  }

  getgrupoContas(tpFatura: any, empresaId:any = 0): Observable<HttpResponse<any>> {

    const params = {
      empresaId: empresaId,
      tpFatura: tpFatura
  }
  return this.http.get(
      environment.webApiSaguiControlUrl + '/api/fatura/visualizatodosgrupoContas/', { observe: 'response', params })
  }

  getCaixas(tpFatura: any, empresaId: any = 0): Observable<HttpResponse<any>> {

    const params = {
      empresaId: empresaId,
      tpFatura: tpFatura
  }

    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/fatura/visualizatodoscaixas/', { observe: 'response', params })
  }

  postFaturas(faturas: any[]): Observable<HttpResponse<any>> {

    return this.http.post(
        environment.webApiSaguiControlUrl + '/api/fatura/incluifaturas/', faturas, { observe: 'response' })}


  getCentroCustos(tpFatura: any, empresaId:any=0): Observable<HttpResponse<any>> {

    const params = {
      empresaId: empresaId,
      tpFatura: tpFatura
  }

    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/fatura/centrocusto/', { observe: 'response', params })
  }

  getFatura(itemId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/fatura/visualiza/' + itemId, { observe: 'response' })
  }

  getClientePorId(itemId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/fatura/visualizacliente/' + itemId, { observe: 'response' })
  }

  getParceiroPorNome(nome: any, faturaTipo: number): Observable<HttpResponse<any>> {
    const params = new HttpParams().set('id', nome);
    if (faturaTipo == 0) {
      return this.http.get(
        environment.webApiSaguiControlUrl + '/api/fatura/visualizaclientepornome',
      { observe: 'response', params }
    );
    } else {
      return this.http.get(
        environment.webApiSaguiControlUrl + '/api/fatura/visualizafornecedorpornome',
      { observe: 'response', params }
    );
    }
  }

  getFornecedorPorId(itemId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/fatura/visualizafornecedor/' + itemId, { observe: 'response' })
  }

  getFornecedorPorNome(nome: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/fatura/visualizafornecedorpornome/' + nome, { observe: 'response' })
  }

  postFatura(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/fatura/inclui/', item, { observe: 'response' })
  };

  putFatura(item: any, itemId: any): Observable<HttpResponse<any>> {
    return this.http.put(
      environment.webApiSaguiControlUrl + '/api/fatura/atualiza/' + itemId, item, { observe: 'response' })
  };

  deleteFatura(itemId: any): Observable<HttpResponse<any>> {
    return this.http.delete(
      environment.webApiSaguiControlUrl + '/api/fatura/exclui/' + itemId, { observe: 'response' })
  }

  getFaturaItem(itemId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/faturaitem/visualiza/' + itemId, { observe: 'response' })
  }

  postIncluiFaturaItem(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/faturaitem/inclui/', item, { observe: 'response' })
  };

  putAtualizaFaturaItem(item: any, itemId: any): Observable<HttpResponse<any>> {
    return this.http.put(
      environment.webApiSaguiControlUrl + '/api/faturaitem/atualiza/' + itemId, item, { observe: 'response' })
  };

  deleteFaturaItem(itemId: any): Observable<HttpResponse<any>> {
    return this.http.delete(
      environment.webApiSaguiControlUrl + '/api/faturaitem/exclui/' + itemId, { observe: 'response' })
  }

  deleteFaturaParcela(itemId: any): Observable<HttpResponse<any>> {
    return this.http.delete(
      environment.webApiSaguiControlUrl + '/api/faturaparcela/exclui/' + itemId, { observe: 'response' })
  }

  getVisualizaDocumento(parametros: any): Observable<HttpResponse<any>> {

    const params = {
      idOrigem: parametros.idOrigem,
      tipoOrigem: parametros.tipoOrigem,
      tipoDestino: parametros.tipoDestino
    }

    return this.http.get(environment.webApiSaguiControlUrl + '/api/fatura/visualizadocumentopororigemdestino', { observe: 'response', params })
  }

  postGerarParcelas(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/faturaparcela/gerarparcelas', item, { observe: 'response' })
  };

  postGerarConta(item: any, atualizaValoresRetencao:boolean): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/fatura/gerarconta/' + atualizaValoresRetencao, item, { observe: 'response' })
  };

  postGerarContaBoleto(item: any, atualizaValoresRetencao: boolean): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/fatura/faturageraboletos/'+ atualizaValoresRetencao, item, { observe: 'response' })
  };

  postGerarNfse(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/fatura/gerarnfse', item, { observe: 'response' })
  };

  getNfsesAndContasByFaturaId(faturaId: number): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/fatura/get-nfses-and-contas-by/' + faturaId, { observe: 'response' })
  }

  postVerificaCServicoDiferentes(fatura): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/fatura/verifica-cservico-diferentes', fatura, { observe: 'response' })
  }

  postBuscaValorRetencaoNfses(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/fatura/busca-valor-retencao-nfses', item, { observe: 'response' })
  };

  getProdutoPorNome(parametros: any): Observable<HttpResponse<any>> {

    const params = {
      id: parametros.id,
      tipoProduto: parametros.tipoProduto,
    }

    return this.http.get(environment.webApiSaguiControlUrl + '/api/fatura/autocompleteproduto', { observe: 'response', params })
  }

  postCalculaImposto(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/faturaitem/calculaimposto', item, { observe: 'response' })
  };

  getListaServicos(): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/servicolista/visualizatodas', { observe: 'response' })
  }

  postFaturaPorEmail(fatura: any, emailFile?: File): Observable<HttpResponse<any>> {
    const formData = new FormData();
    if (emailFile) {
      formData.append('emailFile', emailFile);
    }

    return this.http.post<any>(
      `${environment.webApiSaguiControlUrl}/api/fatura/email/${fatura.faturaId}`,
      formData,
      { observe: 'response' }
    );
  }

  postImprimir(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/fatura/imprimir', item, { observe: 'response' })
  };

  verificaFaturaStatus(faturaStatus: number) {

    switch (faturaStatus) {

      case 0:
        return 'Aberta';
      case 1:
        return 'Faturada';
      case 99:
        return "Cancelada";
      default:
        return 'Aberta';
    }
  }

  corStatus(faturaStatus: number) {

    switch (faturaStatus) {

      case 0:
        return 'Processando';
      case 1:
        return 'Sucesso';
      case 99:
        return "Erro";
      default:
        return 'Processando';
    }
  }

  //Atualização de endpoints para o novo serviço de fatura
  getProdutoPorNomeEEmpresas(parametros: any): Observable<HttpResponse<any>> {
    const params = {
        id: parametros.id,
        empresasIds: parametros.empresasIds
    }

    return this.http.post(
        environment.webApiSaguiControlUrl + '/api/Fatura/buscaprodutopornomeeempresas', params, { observe: 'response' })
  }

  //cria nova fatura
  NovaFatura(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
        environment.webApiSaguiControlUrl + '/api/Fatura/v2/fatura', item, { observe: 'response' })
  }

  getLogEmailFatura(params: any): Observable<HttpResponse<any>> {

    const url = `${environment.webApiSaguiControlUrl}/api/log/v2/Log/${params.faturaId}`;
    return this.http.get(url, { observe: 'response', params });
  }

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

  }
}
