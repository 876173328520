import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable()
export class OperacaoContabilService {

  getOperacoes(): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/operacao/visualizatodas/', { observe: 'response' })
  }

  getOperacoesContabeis(): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/operacaocontabil/visualizatodas/', { observe: 'response' })
  }

  getOperacaoContabil(itemId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/operacaocontabil/visualiza/' + itemId, { observe: 'response' })
  }

  postContasContabeisPorFiltro(item: any): Observable<HttpResponse<any>> {

    let filtro: any = {};
        filtro.id = item.descricao;
        filtro.empresaIds = item.empresaIds;
    
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/operacaocontabil/visualizacontacontabilpornomeeempresas/', filtro, { observe: 'response' })
  }


  getEmpresa(): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/operacaocontabil/empresas/', { observe: 'response' })
  }

  postOperacaoContabil(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/operacaocontabil/inclui/', item, { observe: 'response' })
  };

  putOperacaoContabil(item: any, itemId: any): Observable<HttpResponse<any>> {
    return this.http.put(
      environment.webApiSaguiControlUrl + '/api/operacaocontabil/atualiza/' + itemId, item, { observe: 'response' })
  };

  deleteOperacaoContabil(itemId: any): Observable<HttpResponse<any>> {
    return this.http.delete(
      environment.webApiSaguiControlUrl + '/api/operacaocontabil/exclui/' + itemId, { observe: 'response' })
  }

  getOperacoesContabeisPaginadas(page: number, pageSize: number): Observable<{ totalItems: number, operacoes: any[] }> {
    const params = {
      page: page.toString(),
      pageSize: pageSize.toString()
    };
    return this.http.get<{ totalItems: number, operacoes: any[] }>(
      environment.webApiSaguiControlUrl + '/api/operacaocontabil/visualiza/paginacao', { params });
  }

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

  }
}
