import { ContaParcela } from "./conta-parcela.model";

export class Conta {

  contaId: number = 0;

  tpConta: number = 0;

  tipoAdiantamento: number = 0;

  //Se tpConta for 0 então é cliente
  //se tpConta for 1 então é fornecedor
  //se tpConta for 2 pode ser Cliente, Fornecedor ou Funcionário dependentedo do tipoAdiantamento
  parceiroId: number = 0;

  descricaoConta: string = '';

  nomeParceiro: string = '';

  dataCriacao: Date = new Date();

  grupoContaId: number = 0;

  empresaId: number = 0;

  saldo: number = 0;

  valor: number = 0;

  nfeId: number = 0;

  gerarNFSeAutomaticamente: boolean = false;

  saguiClienteId: number = 0;

  caixaId: number = 0;

  contaContabilDebitoId: number = 0;

  contaContabilCreditoId: number = 0;

  centroCustoId: number = 0;

  isSocCancela: boolean = false;

  contaParcelas: ContaParcela[] = [];

  comportamentoNfse: number = 0;

  documentoFiscal: number = 0;
}
