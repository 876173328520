import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { observable, Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable()
export class IntegracaoService {

  getPorFiltro(): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/integracao/visualizatodas', { observe: 'response' })
  }

  get(itemId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/integracao/visualiza/' + itemId, { observe: 'response' })
  }

  getTemIntegracaoAtivaBool(): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/saguitechIntegracao/integracao-ativa', { observe: 'response' })
  }

  post(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/integracao/inclui', item, { observe: 'response' })
  };

  put(item: any, itemId: any): Observable<HttpResponse<any>> {
    return this.http.put(
      environment.webApiSaguiControlUrl + '/api/integracao/atualiza/' + itemId, item, { observe: 'response' })
  };

  delete(itemId: any): Observable<HttpResponse<any>> {
    return this.http.delete(
      environment.webApiSaguiControlUrl + '/api/integracao/exclui/' + itemId, { observe: 'response' })
  }

  getEmpresas(): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/integracao/empresas', { observe: 'response' })
  }

  getSaguitechIntegracoes(): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/integracao/saguitechintegracoes', { observe: 'response' })
  }

  postSoc(socExportaDadosParametros){
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/integracao/socexportadados', socExportaDadosParametros, { observe: 'response' })

  }

  postSocExecutarAgora(executarIntegracaoDto?){
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/integracao/socexecutaragora', executarIntegracaoDto, { observe: 'response' })

  }

  verificaIntegracaoPorNumeroEnum(idEnumIntegracaoTipo: number): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/integracao/verifica-integracoes/' + idEnumIntegracaoTipo, { observe: 'response' })
  }

  verficaIntegracaoSeHaIntegrcaoSoc(): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/integracao/verifica-integracoes-soc', { observe: 'response' })
  }

  dadosAcesso(itemId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/Integracao/dados-autenticacao?' + itemId, { observe: 'response' })
  }

  geraChave(nome: string): Observable<string> {
    return this.http.get<string>(
      `${environment.webApiSaguiControlUrl}/api/integracao/gerar-chave`, { params: { nome }, responseType: 'text' as 'json' }
    );
  }
  geraCsrKey(item: any):  Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/Certificados/gera-certificado-csr', item, {observe: 'response'}
    );
  }

  geraCertificadoDinamicoItau(itemId: any): Observable<HttpResponse<any>>{
    return this.http.get(
    environment.webApiSaguiControlUrl + '/api/IntegracaoItauApi/retornaCertificadoDinamico/' +itemId, {observe: 'response'})
  }

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

  }
}
