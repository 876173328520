import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable()
export class OrcamentoService {

  postVisualizaOrcamentosPorFiltro(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/orcamento/visualizatodas/', item, { observe: 'response' })
  }

  getEmpresa(): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/orcamento/visualizatodasempresas', { observe: 'response' })
  }

  getVendedores(): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/venda/visualizatodosvendedores', { observe: 'response' })
  }

  getTransportadoras(): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/orcamento/transportadoras', { observe: 'response' })
  }

  getOrcamento(itemId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/orcamento/visualiza/' + itemId, { observe: 'response' })
  }

  getClientePorId(itemId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/orcamento/visualizacliente/' + itemId, { observe: 'response' })
  }


  getClientePorNome(nome: any): Observable<HttpResponse<any>> {
    const params = new HttpParams().set('id', nome);
    return this.http.get(
       environment.webApiSaguiControlUrl +'/api/orcamento/visualizaclientepornome',
      { observe: 'response', params }
    );
  }

  getFornecedorPorId(itemId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/orcamento/visualizafornecedor/' + itemId, { observe: 'response' })
  }

  getFornecedorPorNome(nome: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/orcamento/visualizafornecedorpornome/' + nome, { observe: 'response' })
  }

  postOrcamento(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/orcamento/inclui/', item, { observe: 'response' })
  };

  putOrcamento(item: any, itemId: any): Observable<HttpResponse<any>> {
    return this.http.put(
      environment.webApiSaguiControlUrl + '/api/orcamento/atualiza/' + itemId, item, { observe: 'response' })
  };

  putAtualizaStatus(item: any, itemId: any): Observable<HttpResponse<any>> {
    return this.http.put(
      environment.webApiSaguiControlUrl + '/api/orcamento/atualizastatus/' + itemId, item, { observe: 'response' })
  };

  deleteOrcamento(itemId: any): Observable<HttpResponse<any>> {
    return this.http.delete(
      environment.webApiSaguiControlUrl + '/api/orcamento/exclui/' + itemId, { observe: 'response' })
  }

  getOrcamentoItem(itemId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/orcamentoitem/visualiza/' + itemId, { observe: 'response' })
  }

  postIncluiOrcamentoItem(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/orcamentoitem/inclui/', item, { observe: 'response' })
  };

  putAtualizaOrcamentoItem(item: any, itemId: any): Observable<HttpResponse<any>> {
    return this.http.put(
      environment.webApiSaguiControlUrl + '/api/orcamentoitem/atualiza/' + itemId, item, { observe: 'response' })
  };

  deleteOrcamentoItem(itemId: any): Observable<HttpResponse<any>> {
    return this.http.delete(
      environment.webApiSaguiControlUrl + '/api/orcamentoitem/exclui/' + itemId, { observe: 'response' })
  }

  getVisualizaDocumento(parametros: any): Observable<HttpResponse<any>> {

    const params = {
      idOrigem: parametros.idOrigem,
      tipoOrigem: parametros.tipoOrigem,
      tipoDestino: parametros.tipoDestino
    }

    return this.http.get(environment.webApiSaguiControlUrl + '/api/orcamento/visualizadocumentopororigemdestino', { observe: 'response', params })
  }

  postGerarVenda(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/orcamento/gerarvenda', item, { observe: 'response' })
  };

  getProdutoPorNome(parametros: any): Observable<HttpResponse<any>> {

    const params = {
      id: parametros.id,
      tipoProduto: parametros.tipoProduto,
    }

    return this.http.get(environment.webApiSaguiControlUrl + '/api/orcamento/autocompleteproduto', { observe: 'response', params })
  }

  getEstoqueByProduto(itemId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/orcamentoitem/visualizaestoqueporproduto/' + itemId, { observe: 'response' })
  }

  postCalculaImposto(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/orcamentoitem/calculaimposto', item, { observe: 'response' })
  };

  getListaServicos(): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/servicolista/visualizatodas', { observe: 'response' })
  }

  getListaServico(itemId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/servicolista/visualizaporid/' + itemId, { observe: 'response' })
  }

  postDefinirComoenviadoAoCliente(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/orcamento/definircomoenviadoaocliente', item, { observe: 'response' })
  };

  postVisualizaOrcamentoParcelaPorFiltro(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/orcamentoparcela/visualizatodas/', item, { observe: 'response' })
  }

  getOrcamentoParcela(itemId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/orcamentoparcela/visualiza/' + itemId, { observe: 'response' })
  }

  postOrcamentoParcela(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/orcamentoparcela/inclui/', item, { observe: 'response' })
  };

  postGerarorcamentoParcelasProduto(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/orcamentoparcela/gerarparcelasproduto/', item, { observe: 'response' })
  };

  postGerarorcamentoParcelasServico(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/orcamentoparcela/gerarparcelasservico/', item, { observe: 'response' })
  };

  putOrcamentoParcela(item: any, itemId: any): Observable<HttpResponse<any>> {
    return this.http.put(
      environment.webApiSaguiControlUrl + '/api/orcamentoparcela/atualiza/' + itemId, item, { observe: 'response' })
  };

  deleteOrcamentoParcela(itemId: any): Observable<HttpResponse<any>> {
    return this.http.delete(
      environment.webApiSaguiControlUrl + '/api/orcamentoparcela/exclui/' + itemId, { observe: 'response' })
  }

  postImprimir(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/orcamento/imprimir/', item, { observe: 'response' })
  };
  putDescontosAdicionais(DescontoDto: any): Observable<HttpResponse<any>> {
    return this.http.put(
      environment.webApiSaguiControlUrl + '/api/orcamento/descontos-adicionais/', DescontoDto, { observe: 'response' })
  }

  retornarStatus(status: number): string {
    let textoStatus = "";

    switch (status) {
        case 0:
            textoStatus = "Em Criação";
            break;
        case 1:
            textoStatus = "Enviado ao Cliente";
            break;
        case 2:
            textoStatus = "Venda Efetivada";
            break;
        case 3:
            textoStatus = "Substituído";
            break;
        case 4:
            textoStatus = "Recusado";
            break;
        case 99:
            textoStatus = "Cancelado";
            break;
        default:
            textoStatus = "";
            break;
    }

    return textoStatus;
}

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

  }
}
