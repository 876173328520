import { Directive, ElementRef, OnInit, Renderer2, Optional } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';

@Directive({
  selector: '[appRequiredField]'
})
export class RequiredFieldDirective implements OnInit {
  private isTouched = false;

  constructor(
    private el: ElementRef,
    @Optional() private control: NgControl,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    if (!this.control) {
      console.error('NgControl not found for', this.el.nativeElement);
      return;
    }

    const control = this.control.control;
    if (control && control.validator) {
      this.control.statusChanges?.subscribe(() => this.updateErrorMessage());
      this.renderer.listen(this.el.nativeElement, 'focus', () => this.onFocus());
      this.renderer.listen(this.el.nativeElement, 'blur', () => this.onBlur());

      const formElement = this.el.nativeElement.closest('form');
      if (formElement) {
        this.renderer.listen(formElement, 'reset', () => this.onFormReset());
      }

      // Listen to value changes
      control.valueChanges?.subscribe(() => {
        this.isTouched = true; // Mark as touched when value changes
        control.markAsTouched(); // Mark control as touched
        control.markAsDirty(); 
        this.updateErrorMessage();
      });

      this.updateErrorMessage();
    }
  }

  private onFocus() {
    this.isTouched = true;
  }

  private onBlur() {
    this.updateErrorMessage();
  }

  private onFormReset() {
    this.isTouched = false;
    this.updateErrorMessage();
  }

  private updateErrorMessage() {
    const control = this.control.control;

    if (!control) return;

    const matFormField = this.el.nativeElement.closest('mat-form-field');
    if (matFormField) {
      const formFieldControl = matFormField.querySelector('.mat-form-field-subscript-wrapper');

      if (formFieldControl) {
        // Remove all existing error elements
        const existingErrors = formFieldControl.querySelectorAll('mat-error');
        existingErrors.forEach(errorElement => {
          this.renderer.removeChild(formFieldControl, errorElement);
        });

        // Add the error message if the control is invalid and has been touched
        if (this.isTouched && control.invalid) {
          const errorElement = this.renderer.createElement('mat-error');
          let errorMessage = 'Campo inválido';

          if (control.errors?.['required']) {
            errorMessage = 'Campo Obrigatório';
          } else if (control.errors?.['email']) {
            errorMessage = 'Email inválido';
          } else if (control.errors?.['minlength']) {
            errorMessage = `Mínimo de ${control.errors['minlength'].requiredLength} caracteres`;
          } else if (control.errors?.['maxlength']) {
            errorMessage = `Máximo de ${control.errors['maxlength'].requiredLength} caracteres`;
          } else {
            errorMessage = 'Campo inválido';
          }

          const text = this.renderer.createText(errorMessage);
          this.renderer.appendChild(errorElement, text);

          // Add Angular Material class manually if necessary
          this.renderer.addClass(errorElement, 'mat-error');
          this.renderer.setAttribute(errorElement, 'aria-atomic', 'true');
          this.renderer.setAttribute(errorElement, 'aria-live', 'polite');
          this.renderer.appendChild(formFieldControl, errorElement);
        }
      }
    }
  }
}