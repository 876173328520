<mat-toolbar>
    <div class="md-toolbar-tools">
        <h1 mat-dialog-title>{{ data.title }}</h1>
        <span flex></span>
        <div class="posicionar-direita">
            <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
                <mat-icon>fullscreen_exit</mat-icon>
            </button>
            <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
                <mat-icon>fullscreen</mat-icon>
            </button>
            <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
</mat-toolbar>
<div>
    <mat-dialog-actions class="text-left mt-15px">
        <button mat-raised-button color="primary" (click)="salvarServico()" style="margin-left: auto;" [disabled]="!formulariosSaoValidos()">
            <mat-icon>save</mat-icon> Salvar
        </button>
    </mat-dialog-actions>
  </div>
<mat-dialog-content>
    <mat-tab-group class="text-left">
        <mat-tab label="Geral">
            <form style="margin-top:25px;" [formGroup]="editGeral">
                <div class="row">
                    <mat-form-field class="col-md-4 col-xs-12 form-group">
                        <mat-label>Empresa</mat-label>
                        <mat-select formControlName="produtoEmpresa" multiple required>
                          <mat-option *ngFor="let empresa of empresas" [value]="empresa">
                            {{empresa.nomeFantasia}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    <mat-form-field class="form-group col-md-4 col-xs-12">
                        <mat-label>Grupo de Serviços</mat-label>
                        <mat-select formControlName="produtoGrupoId" required>
                            <mat-option *ngFor="let produtoGrupo of produtoGrupos" [value]="produtoGrupo.produtoGrupoId">
                                {{produtoGrupo.descricao}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="col-md-4 col-xs-12 form-group" *ngIf="!pacoteServico || editGeral.get('pacoteServico').value == false">
                        <mat-label>Tipo de Recorrência</mat-label>
                        <mat-select formControlName="recorrencia" required>
                            <mat-option [value]="0">Não Recorrente</mat-option>
                            <mat-option [value]="3">Mensal</mat-option>
                            <mat-option [value]="4">Trimestral</mat-option>
                            <mat-option [value]="5">Semestral</mat-option>
                            <mat-option [value]="6">Anual</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="col-md-4 col-xs-12 form-group" *ngIf="pacoteServico || editGeral.get('pacoteServico').value == true">
                        <mat-label>Comportamento</mat-label>
                        <mat-select formControlName="comportamentoPacote" required>
                            <mat-option [value]="0">Dinâmico</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field class="form-group col-md-4 col-xs-12">
                        <mat-label>Código do Serviço</mat-label>
                        <input matInput formControlName="codProd" required />
                    </mat-form-field>
                    <mat-form-field class="form-group col-md-8 col-xs-12">
                        <mat-label>Nome</mat-label>
                        <input matInput formControlName="descricao" required />
                    </mat-form-field>
                </div>
            </form>
        </mat-tab>

        <mat-tab label="Financeiro">
            <form style="margin-top:25px;" [formGroup]="editFinanceiro">
                <div class="row text-left" *ngIf="pacoteServico || editGeral.get('pacoteServico').value == true">
                    <label>Contas a Receber:</label>
                    <br>
                    <mat-radio-group formControlName="contasareceber">
                        <mat-radio-button [value]="0" [checked]="editFinanceiro.get('contasareceber').value === 0">
                            Selecionar Manualmente
                        </mat-radio-button>
                        <mat-radio-button [value]="1" [checked]="editFinanceiro.get('contasareceber').value === 1">
                            Importar dos serviços do pacote
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="row" *ngIf="editFinanceiro.get('contasareceber')?.value === 0">
                    <mat-form-field class="col-md-6 col-xs-12 form-group">
                        <mat-label>Categoria a Receber</mat-label>
                        <mat-select formControlName="grupoContaId">
                            <mat-option *ngFor="let grupoConta of grupoContas" [value]="grupoConta.grupoContaId">
                                {{ grupoConta.descricao }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <hr>
                <div class="row text-left" *ngIf="!pacoteServico || editGeral.get('pacoteServico').value === 0">
                    <h3>Tabela de preço padrão</h3>
                    <div class="col-md-6 col-xs-12" style="border-right: 1px solid #f3f3f3;">
                        <p>Compra</p>
                        <div class="row mt-15px">
                            <mat-form-field class="col-md-6 col-xs-12 form-group">
                                <mat-label>Unidade de Compra</mat-label>
                                <input type="text" matInput formControlName="unidadeCompra">
                                <mat-autocomplete autoActiveFirstOption>
                                    <mat-option *ngFor="let unidadeMedida of compraFilteredOptions | async"
                                        [value]="unidadeMedida.unidade">
                                        {{unidadeMedida.unidade}} - {{unidadeMedida.descricao}}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-hint> Ex: unidade (un), usuário (us), hora (h), licença, pacote (pcte)</mat-hint>
                            </mat-form-field>
                            <mat-form-field class="form-group col-md-6 col-xs-12">
                                <mat-label>Valor para Compra</mat-label>
                                <input currencyMask matInput formControlName="valorCompra">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-6 col-xs-12">
                        <p>Venda</p>
                        <div class="row mt-15px">
                            <mat-form-field class="col-md-6 col-xs-12">
                                <mat-label>Unidade de venda</mat-label>
                                <input type="text" matInput formControlName="unComercial">
                                <mat-autocomplete autoActiveFirstOption>
                                    <mat-option *ngFor="let unidadeMedida of comercialFilteredOptions | async"
                                        [value]="unidadeMedida.unidade">
                                        {{unidadeMedida.unidade}} - {{unidadeMedida.descricao}}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-hint> Ex: unidade (un), usuário (us), hora (h), licença, pacote (pcte)</mat-hint>
                            </mat-form-field>
                            <mat-form-field class="form-group col-md-6 col-xs-12">
                                <mat-label>Valor Comercial</mat-label>
                                <input currencyMask matInput formControlName="vlrComercial">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </form>
        </mat-tab>

        <mat-tab label="NFS-e">
            <form style="margin-top:25px;" [formGroup]="editNfs">
                <div class="row text-left" *ngIf="pacoteServico || editGeral.get('pacoteServico').value">
                    <label>Configuração Nota Fiscal</label>
                    <br>
                    <mat-radio-group formControlName="contasareceber">
                        <mat-radio-button value="0">Selecionar Manualmente</mat-radio-button>
                        <mat-radio-button value="1">Importar dos serviços do pacote</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="row">
                    <mat-form-field class="col-md-12 col-xs-12 form-group" *ngIf="editNfs.get('contasareceber').value == 0 || editGeral.get('pacoteServico').value == 0">
                        <mat-label>Código do Serviço/Atividade</mat-label>
                        <mat-select formControlName="servicoListaId">
                            <mat-option *ngFor="let listaServico of listaServicos" [value]="listaServico.servicoListaId">
                                {{listaServico.codigo + ' - ' + listaServico.descricao}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field class="form-group col-md-12 col-xs-12" *ngIf="editNfs.get('contasareceber').value == 0 || editGeral.get('pacoteServico').value == 0">
                        <mat-label>Discriminação Padrão</mat-label>
                        <textarea matInput rows="5" formControlName="servicoDiscriminacaoPadrao"></textarea>
                    </mat-form-field>
                </div>
            </form>
        </mat-tab>

        <mat-tab label="Pagamento Recorrente">
            <form style="margin-top: 25px;" [formGroup]="editGetnet">
                <div class="row">
                    <div class="form-group col-md-12 text-left">
                        <mat-slide-toggle formControlName="utilizaGetNetPlano">
                            Ativar Pagamento Recorrente (GetNet)                        
                        </mat-slide-toggle>
                        <br>
                        <mat-hint>Um plano da GetNet permite criar assinaturas com faturamento automático. Para utilizar essa funcionalidade a Saguitech precisa configurar o seu ambiente previamente. Se possuir interesse entre em contato com o suporte. Para conhecer sobre o produto da GetNet <a target="_blank" href="https://site.getnet.com.br/recorrencia/">clique aqui</a></mat-hint>
                    </div>
                </div>
                <div class="row" *ngIf="editGetnet.get('utilizaGetNetPlano').value">
                    <mat-form-field class="col-md-6 col-xs-12 form-group">
                        <mat-label>Fluxo de Caixa</mat-label>
                        <mat-select formControlName="caixaId">
                            <mat-option *ngFor="let caixa of caixas" [value]="caixa">
                                {{caixa.nome}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="row">
                    <div class="col-md-12 col-xs-12 form-group text-left">
                        <label *ngIf="editGetnet.get('utilizaGetNetPlano').value != '00000000-0000-0000-0000-000000000000'">
                            Idenfiticação no Plano na GetNet: {{editGetnet.get('getNetPlanId').value}}
                        </label>
                        <label *ngIf="editGetnet.get('utilizaGetNetPlano').value == '00000000-0000-0000-0000-000000000000'">
                            Não sincronizado
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 col-xs-12 form-group text-left">
                        <mat-hint>A GetNet é atualizada a cada vez que uma tentativa de salvar o Plano ocorre. Se desejar forçar a sincronização simplesmente clique no botão salvar.</mat-hint>
                    </div>
                </div>
            </form>
        </mat-tab>

        <mat-tab label="SOC" *ngIf="integracao">
            <form [formGroup]="editSoc" style="margin-top: 25px;">
                <mat-form-field class="col-md-6 col-xs-12 form-group">
                    <mat-label>Código SOC</mat-label>
                    <input type="text" matInput formControlName="codSoc">
                </mat-form-field>
                <mat-form-field class="col-md-6 col-xs-12 form-group">
                    <mat-label>Tipo de Serviço SOC</mat-label>
                    <mat-select formControlName="tipoSoc">
                        <mat-option [value]="1">Exame</mat-option>
                        <mat-option [value]="2">Serviço</mat-option>
                    </mat-select>
                </mat-form-field>
            </form>
        </mat-tab>
    </mat-tab-group>
    <hr>
</mat-dialog-content>

<!-- <mat-dialog-footer>
    <mat-dialog-actions class="text-left mt-15px">
        <button mat-raised-button color="primary" (click)="salvarServico()" style="margin-left: auto;" [disabled]="!formulariosSaoValidos()">
            <mat-icon>save</mat-icon> Salvar
        </button>
    </mat-dialog-actions>
</mat-dialog-footer> -->
